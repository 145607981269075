import { FC, ReactElement, SyntheticEvent, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import DeleteIcon from '@mui/icons-material/Delete'
import {
  Autocomplete,
  CircularProgress,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'

import DataGrid from '~/components/UI/DataGrid/DataGrid'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import { Account } from '~/types/Account.model'
import { Fields } from '~/types/Fields.model'
import { Options, SearchParams } from '~/types/User.model'

type TableProps = {
  fields: Fields[]
  name: string
  source: (val: any) => any
}

type Props = {
  user: Account
  title: string
  tableProps: TableProps
  useAll: (searchParams: SearchParams, options: Options) => any
  useAttach: (customerId: string, setError: (val: string) => void) => any
  useDetach: (customerId: string, setError: (val: string) => void) => any
  getOptionLabel: (option: any) => any
}

const OneToManyMapper: FC<Props> = ({
  user,
  title,
  tableProps,
  useAll,
  useAttach,
  useDetach,
  getOptionLabel,
}): ReactElement => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState('')
  const [debounceInputValue] = useDebounce(inputValue, 400)

  const { data, isFetching } = useAll(
    {
      page: 1,
      pageSize: 100,
      q: debounceInputValue,
      status: 'ACTIVE',
    },
    {
      enabled: open,
      placeholderData: {
        items: [],
        totalCount: 0,
      },
    }
  )
  const { mutate: addAccount } = useAttach(user.id, setError)
  const { mutate: removeAccount } = useDetach(user.id, setError)

  const actions = [
    {
      icon: <DeleteIcon sx={{ color: 'red' }} />,
      click: (row: Account) => {
        setError('')
        removeAccount(row.id)
      },
    },
  ]

  const { setTableProps, tableProps: currentTableProps } = useDataGridStore()

  useEffect(() => {
    setTableProps({
      ...tableProps,
      actions,
    })
  }, [])

  const handleChange = (
    ev: SyntheticEvent<Element, Event>,
    account: Account,
    reason: string
  ) => {
    setError('')
    setValue(account)

    if (reason === 'selectOption') {
      addAccount(account.id, {
        onSuccess: () => {
          setValue(null)
        },
      })
    }
  }

  return (
    <>
      <Grid container mt={6} pb={3}>
        <Grid item xs={8} md={4}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Grid container mb={2} alignItems="center">
            <Grid item xs={12}>
              <Autocomplete
                fullWidth={true}
                open={open}
                value={value}
                onOpen={() => {
                  setOpen(true)
                }}
                onClose={() => {
                  setOpen(false)
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue)
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={getOptionLabel}
                onChange={handleChange}
                options={data?.items}
                loading={isFetching}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          Add New
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <>
                          {isFetching ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              {!!error && <FormHelperText error={true}>{error}</FormHelperText>}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {Object.keys(currentTableProps).length > 0 && <DataGrid />}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
// eslint-disable-next-line react/display-name
export default (props: Props) => (
  <DataGridProvider>
    <OneToManyMapper {...props} />
  </DataGridProvider>
)
