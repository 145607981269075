import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material'

import DataGrid from '~/components/UI/DataGrid/DataGrid'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import { useImpersonate } from '~/services/auth'
import { fetchUsers } from '~/services/users'
import { PageParams } from '~/types/PageParams.model'
import { User } from '~/types/User.model'
import PencilIcon from '~/UI/icons/PencilIcon'
import { TEXTS } from '~/utils/allConstants/Constants'

import userFields from './UserFields'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorMediumGrey } = variables
const { YES, NO } = TEXTS

const Users = () => {
  const navigate = useNavigate()
  const impersonate = useImpersonate()
  const [selectedUser, setSelectedUser] = useState(null)
  const actions = [
    {
      icon: <PencilIcon color={acrColorMediumGrey} />,
      tooltip: 'Edit',
      click: (row: User) => {
        navigate(`/manage/users/${row.id}`)
      },
    },
    {
      icon: <SupervisorAccountIcon />,
      tooltip: 'Impersonate',
      click: (row: User) => {
        setSelectedUser(row)
      },
      disabled: (row: User) => {
        return row.userStatus !== 'ACTIVE'
      },
    },
  ]

  const { setTableProps, tableProps } = useDataGridStore()

  useEffect(() => {
    setTableProps({
      name: 'manage_users',
      source: (params: PageParams<any>) => {
        return fetchUsers({
          ...params,
          fields: userFields,
        })
      },
      fields: userFields,
      actions,
    })
  }, [])

  const handleClose = () => {
    setSelectedUser(null)
  }

  const doImpersonate = () => {
    impersonate.mutate(selectedUser, {
      onSuccess: handleClose,
    })
  }

  return (
    <>
      <Typography variant="h3" pb={2}>
        Manage Users
      </Typography>

      <Divider />

      {Object.keys(tableProps).length > 0 && <DataGrid />}

      {selectedUser !== null && (
        <Dialog open={selectedUser !== null} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title" mt={1} px={4}>
            Do you want impersonate for {selectedUser.firstName},&nbsp;
            {selectedUser.lastName}?
          </DialogTitle>
          <DialogActions sx={{ py: 3, justifyContent: 'center' }}>
            <Button className="acr-button" onClick={handleClose}>
              {NO}
            </Button>
            <LoadingButton
              className="acr-button bg-teal"
              onClick={doImpersonate}
              loading={impersonate.isLoading}
            >
              {YES}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
// eslint-disable-next-line react/display-name
export default () => (
  <DataGridProvider>
    <Users />
  </DataGridProvider>
)
