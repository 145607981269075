import { useState } from 'react'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  useMediaQuery,
} from '@mui/material'

import PasswordTooltip from '~/components/common/passwordTooltip/PasswordTooltip'
import ControllerStyledFormControl from '~/components/Form/StyledFormControl'
import ControllerStyledSelect from '~/components/Form/StyledSelect'
import { NoGridStyledTextField } from '~/components/Form/StyledTextField'
import { GlobalLoading } from '~/components/UI/Loading'
import { useAreaOfResponsibility, UserTypes } from '~/services/users'
import { theme } from '~/theme/AppTheme'
import { UserRegisterForm } from '~/types/UserForm.model'
import { TEXTS } from '~/utils/allConstants/Constants'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue } = variables

const {
  FIRST_NAME,
  LAST_NAME,
  EMAIL_ADDRESS,
  MOBILE_NUMBER,
  COMPANY_NAME,
  COMPANY_PHONE_NUMBER,
  AREA_OF_RESPONSIBILITY,
  PASSWORD,
  CONFIRM_PASSWORD,
} = TEXTS

const UserRegistrationForm = ({
  control,
  watch,
  registerMutation,
  responseError,
  handleSubmit,
  submitButton,
  onSubmit,
  showPasswordFields = true,
  type,
}: UserRegisterForm) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const watchUserType = watch('userType')
  const watchAreaOfResponsibility = watch('responsibilityArea')
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const { data: areaOfResponsibility, isLoading } = useAreaOfResponsibility()

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  if (isLoading) {
    return <GlobalLoading />
  }

  return (
    <Grid container columnSpacing={4} pb={3}>
      <Grid item xs={12} md={6}>
        <ControllerStyledFormControl
          name="firstName"
          control={control}
          id="first-name-textfield"
          label={FIRST_NAME}
          placeholder={FIRST_NAME}
          labelSx={{ color: acrColorBlue }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControllerStyledFormControl
          name="lastName"
          control={control}
          id="last-name-textfield"
          label={LAST_NAME}
          placeholder={LAST_NAME}
          labelSx={{ color: acrColorBlue }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControllerStyledFormControl
          name="company"
          control={control}
          id="company-name-textfield"
          label={COMPANY_NAME}
          placeholder={COMPANY_NAME}
          labelSx={{ color: acrColorBlue }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControllerStyledFormControl
          name="companyContactPhone"
          control={control}
          id="company-phone-number-textfield"
          label={COMPANY_PHONE_NUMBER}
          placeholder={COMPANY_PHONE_NUMBER}
          labelSx={{ color: acrColorBlue }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControllerStyledFormControl
          name="contactEmail"
          control={control}
          id="contactEmail-textield"
          label={EMAIL_ADDRESS}
          placeholder={EMAIL_ADDRESS}
          labelSx={{ color: acrColorBlue }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControllerStyledFormControl
          name="contactPhone"
          control={control}
          id="phone-number-textfield"
          label={MOBILE_NUMBER}
          placeholder={MOBILE_NUMBER}
          labelSx={{ color: acrColorBlue }}
        />
      </Grid>
      {type != 'newCarrier' && (
        <>
          <Grid item xs={12} md={6}>
            <Grid container>
              <ControllerStyledSelect
                name="userType"
                control={control}
                id="user-type-textfield"
                label="User Type"
                labelSx={{ color: acrColorBlue }}
              >
                {Object.values(UserTypes).map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
                <MenuItem value="Other">Other</MenuItem>
              </ControllerStyledSelect>
            </Grid>

            {watchUserType === 'Other' && (
              <Grid item xs={12} sx={{ marginTop: isTablet ? '0px' : '20px' }}>
                <NoGridStyledTextField
                  name="userTypeOther"
                  control={control}
                  id="userType-textfield"
                  placeholder="Please specify User Type"
                />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container>
              {areaOfResponsibility && (
                <ControllerStyledSelect
                  name="responsibilityArea"
                  control={control}
                  id="responsibilityArea-select"
                  label={AREA_OF_RESPONSIBILITY}
                  labelSx={{ color: acrColorBlue }}
                >
                  {areaOfResponsibility.map((item: string, index: number) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                  <MenuItem value="Other">Other</MenuItem>
                </ControllerStyledSelect>
              )}
            </Grid>
            {watchAreaOfResponsibility === 'Other' && (
              <Grid item xs={12} sx={{ marginTop: isTablet ? '0px' : '20px' }}>
                <NoGridStyledTextField
                  name="responsibilityAreaOther"
                  control={control}
                  id="responsibilityArea-textfield"
                  placeholder="Please specify Area of Responsibility"
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      {showPasswordFields && (
        <>
          <Grid item xs={12} md={6}>
            <ControllerStyledFormControl
              name="password"
              control={control}
              id="password-textield"
              label={PASSWORD}
              placeholder={PASSWORD}
              type={showPassword ? 'text' : 'password'}
              labelSx={{ color: acrColorBlue }}
              tooltip={<PasswordTooltip />}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControllerStyledFormControl
              name="confirm_password"
              control={control}
              id="confirm-password-textield"
              label={CONFIRM_PASSWORD}
              placeholder={CONFIRM_PASSWORD}
              type={showConfirmPassword ? 'text' : 'password'}
              labelSx={{ color: acrColorBlue }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showConfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} mt={3}>
        {responseError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {responseError}
          </Alert>
        )}
        <Box sx={{ textAlign: 'right' }}>
          <LoadingButton
            className="acr-button bg-teal size-big"
            fullWidth={isTablet}
            onClick={handleSubmit(onSubmit)}
            loading={registerMutation.isLoading}
          >
            {submitButton}
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  )
}

export default UserRegistrationForm
