import { useEffect, useState } from 'react'
import packageJsonData from 'package.json'
import CacheBuster from 'react-cache-buster'
import ReactGA from 'react-ga4'
import { Outlet, useLocation } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { Container, Grid } from '@mui/material'

import { AccountsProvider } from '../components/Accounts'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import SubHeader from '../components/Header/SubHeader'
import {
  Notification,
  NotificationProvider,
} from '../components/Notification/index'
import Loading from '../components/UI/Loading'
import { useAuth } from '../services/auth'
import { theme } from '../theme/AppTheme'

import './App.scss'

const App = () => {
  const isAuth = useAuth()
  const location = useLocation()
  const { version } = packageJsonData
  // Note: enabling it for QA by default
  const isProduction = true || process.env.NODE_ENV === 'production'

  const [lastLocation, setLastLocation] = useState('')

  useEffect(() => {
    if (location.pathname !== lastLocation) {
      window.scrollTo(0, 0)
      setLastLocation(location.pathname)
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname,
      })
    }
  }, [location, lastLocation])

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} // If false, the library is disabled.
      isVerboseMode={false} // If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} // If not pass, nothing appears at the time of new version check.
      metaFileDirectory="." // If public assets are hosted somewhere other than root on your server.
    >
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <NotificationProvider>
          <AccountsProvider>
            <Container
              disableGutters={true}
              sx={{
                height: '100%',
                width: '100%',
                boxSizing: 'border-box',
                margin: '0',
              }}
              maxWidth={false}
            >
              <Header />
              {isAuth.data && <SubHeader />}

              <Grid
                container
                direction="column"
                sx={{
                  paddingTop: isAuth.data ? '176px' : '112px',
                  minHeight: '100%',
                  left: '0',
                  width: '100%',
                  [theme.breakpoints.down('md')]: {
                    paddingTop: isAuth.data ? '112px' : '98px',
                  },
                }}
              >
                <Grid item xs={true} sx={{ width: '100%' }}>
                  <Notification />
                  <Outlet />
                </Grid>
                {isAuth.data && (
                  <Grid item style={{ maxWidth: '100%' }}>
                    <Footer />
                  </Grid>
                )}
              </Grid>
            </Container>
          </AccountsProvider>
        </NotificationProvider>
      </QueryParamProvider>
    </CacheBuster>
  )
}

export default App
