import { Link, Typography } from '@mui/material'

import PreLoginLayout from '~/components/common/preLoginLayout/PreLoginLayout'

const RegisterConfirm = () => {
  const leftPart = (
    <Typography variant="h3" my={2}>
      Thank you for confirming your email!
    </Typography>
  )
  const rightPart = (
    <>
      <Typography variant="h3" mb={2}>
        Your account has been sent to an ACR representative to complete the
        approval process.
      </Typography>
      <Typography variant="h5" py={3}>
        Access to the ACR portal is for existing customer only.
        <br />
        Non-ACR existing customers, please click&nbsp;
        <Link href="https://weareacr.com/contact" target="_blank">
          here
        </Link>
        &nbsp;for more information on how to open a customer account.
      </Typography>
    </>
  )

  return <PreLoginLayout leftChildren={leftPart} rightChildren={rightPart} />
}

export default RegisterConfirm
