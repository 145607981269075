import { FC, SyntheticEvent, useState } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { Tab, TabProps, Tabs, useMediaQuery, useTheme } from '@mui/material'

import { useAuth, useLogoutMutation } from '~/services/auth'
import { RoleTypes } from '~/services/users'
import { GrantedClaims, TEXTS } from '~/utils/allConstants/Constants'

import './UserTab.scss'

const {
  LOGOUT,
  BANNER_NOTIFICATION,
  MY_ACCOUNT,
  MANAGE_USERS,
  SITE_SETTINGS,
  SYSTEM_INFORMATION,
  CREATE_NEW_USER,
} = TEXTS

const StyledTab = (props: TabProps & NavLinkProps & any) => {
  return (
    <Tab
      component={NavLink}
      className="user-tab"
      {...props}
      sx={[...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
    />
  )
}

type Props = {
  toggleDrawer?: () => void
}

const UserTab: FC<Props> = ({ toggleDrawer }) => {
  const [value, setValue] = useState('user_details')
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const authQuery = useAuth()
  const logoutMutation = useLogoutMutation()
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const handleLogout = (ev: SyntheticEvent) => {
    ev.preventDefault()
    logoutMutation.mutate(null, {
      onSuccess: () => {
        window.location.href = '/login'
      },
    })
  }
  return (
    <Tabs
      orientation="vertical"
      value={value}
      onChange={handleChange}
      sx={[
        {
          flex: '0 0 230px',
          [theme.breakpoints.up('md')]: {
            borderRight: 1,
            borderColor: 'divider',
            padding: '0px 16px',
            flex: '0 0 280px',
          },
        },
        {
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        },
      ]}
    >
      <StyledTab
        value="user_details"
        label={MY_ACCOUNT}
        to="/manage/profile"
        onClick={toggleDrawer}
      />
      {authQuery.data.role.includes(RoleTypes.ADMIN) && (
        <StyledTab
          value="manage_users"
          label={MANAGE_USERS}
          to="/manage/users"
          onClick={toggleDrawer}
        />
      )}
      {authQuery.data.claims.includes(
        GrantedClaims.BANNER_NOTIFICATION_EDITOR
      ) && (
        <StyledTab
          value="banner_notification"
          label={BANNER_NOTIFICATION}
          to="/manage/banner-notification"
          onClick={toggleDrawer}
        />
      )}

      <StyledTab
        value="site_settings"
        label={SITE_SETTINGS}
        to="/manage/site"
        onClick={toggleDrawer}
      />

      <StyledTab
        value="system_information"
        label={SYSTEM_INFORMATION}
        to="/manage/system"
        onClick={toggleDrawer}
      />

      {authQuery.data.claims.includes(GrantedClaims.NEW_USER_CREATION) && (
        <StyledTab
          value="create_new_user"
          label={CREATE_NEW_USER}
          to="/manage/newuser"
          onClick={toggleDrawer}
        />
      )}

      {!isTablet && (
        <StyledTab
          value="log_out"
          label={LOGOUT}
          sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
          to="/"
          end={true}
          onClick={handleLogout}
        />
      )}
    </Tabs>
  )
}

export default UserTab
