import { FC } from 'react'

import { Button, Grid, Link, Typography } from '@mui/material'

import returnPDF from '~/PDFs/Return.Policy.2023.pdf'

type Props = {
  closeModal: () => void
  onClick: () => void
}

const ReturnConfirmModal: FC<Props> = ({ closeModal, onClick }) => {
  return (
    <>
      <Grid container my={2} display="flex">
        <Grid item xs={12}>
          <Typography variant="h3" mb={4}>
            Confirm Return
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Please review ACR&apos;s&nbsp;
            <Link href={returnPDF} target="_blank">
              Return Policy
            </Link>
            &nbsp; before proceeding with your Return.
          </Typography>
        </Grid>
      </Grid>
      <Grid container mt={4} mb={2}>
        <Grid item xs={12} gap={2} display="flex" justifyContent="center">
          <Button
            className="acr-button bg-teal"
            sx={{
              width: '140px',
            }}
            onClick={onClick}
          >
            Proceed
          </Button>
          <Button
            className="acr-button"
            sx={{
              width: '140px',
            }}
            onClick={closeModal}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ReturnConfirmModal
