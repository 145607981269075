import * as React from 'react'

import { Grid, Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'

import Logo from '~/images/acr-logo-white.png'
import FacebookIcon from '~/images/social/facebook.svg'
import LinkedinIcon from '~/images/social/linkedin.svg'
import systemInstructions from '~/PDFs/WIN.Customer.Communication.Instructions.pdf'

import FooterCard from './FooterCard'

import './Footer.scss'

const Footer = () => {
  return (
    <Box component="footer">
      <Grid
        container
        spacing={0}
        columnSpacing={{ xs: 12, sm: 1, md: 1 }}
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          boxSizing: 'border-box',
        }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <FooterCard heading="Help & Support">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Link
                href="https://weareacr.com/privacy-policy"
                target="_blank"
                className="footerLink"
              >
                Policies
              </Link>
              <Link
                href="mailTo:customerservice@amercareroyal.com"
                className="footerLink"
              >
                Customer Support
              </Link>
              <Link
                target="_blank"
                href={systemInstructions}
                className="footerLink"
              >
                System Instructions
              </Link>
            </Box>
          </FooterCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FooterCard heading="Contact">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography className="footerText">
                Call us at&nbsp;
                <Link href="tel:+800-666-6655" className="footerLink">
                  +1 800-666-6655
                </Link>
              </Typography>
              <Typography className="footerText">
                or&nbsp;
                <Link
                  target="_blank"
                  href="https://weareacr.com/contact"
                  className="footerLink contactLink"
                >
                  Contact Us
                </Link>
                &nbsp;Online
              </Typography>
              <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                <Link
                  href="https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2Famercareroyal"
                  target="_blank"
                >
                  <img src={FacebookIcon} alt="Facebook page" />
                </Link>
                <Link
                  href="https://www.linkedin.com/company/amercareroyal/"
                  target="_blank"
                >
                  <img src={LinkedinIcon} alt="LinkedIn" />
                </Link>
              </Stack>
            </Box>
          </FooterCard>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FooterCard heading="Distribution Points">
            <Grid container spacing={0} rowSpacing={3}>
              <Grid item xs={12} sm={6}>
                <FooterCard heading="Pennsylvania" isSmall>
                  <Typography className="footerText">
                    420 Clover Mill Rd
                    <br />
                    Exton, PA 19341
                  </Typography>
                </FooterCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FooterCard heading="South Carolina" isSmall>
                  <Typography className="footerText">
                    100 Trade Zone Blvd Ste D
                    <br />
                    Summerville, SC 29483
                  </Typography>
                </FooterCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FooterCard heading="Texas" isSmall>
                  <Typography className="footerText">
                    2401 Petty Pl
                    <br />
                    Fort Worth, TX 76177
                  </Typography>
                </FooterCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FooterCard heading="California" isSmall>
                  <Typography className="footerText">
                    1801 S Carlos Ave
                    <br />
                    Ontario, CA 91761
                  </Typography>
                </FooterCard>
              </Grid>
            </Grid>
          </FooterCard>
        </Grid>
      </Grid>
      <Box className="logoWrapper">
        <Link href="https://weareacr.com" target="_blank">
          <img
            src={Logo}
            alt={Logo}
            style={{
              height: 64,
              verticalAlign: 'top',
            }}
          />
        </Link>
      </Box>
    </Box>
  )
}

export default Footer
