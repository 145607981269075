import { FC, ReactElement, useState } from 'react'

import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { Box, Button, Divider, Grid, Link, Typography } from '@mui/material'

import { InternalLink } from '~/components/UI/DataGrid/DataGrid'
import { CustomerAlert } from '~/types/CustomerAlert.model'
import { TEXTS } from '~/utils/allConstants/Constants'

import './BannerPreview.scss'

const { VIEW_LESS, VIEW_MORE, MARK_AS_READ } = TEXTS

type Props = {
  bannerFormData: CustomerAlert
  header?: boolean
  fileUrl?: boolean
  handleMarkAsRead?: (id: string) => void
  isLoading?: boolean
}

export const StyledLink: FC<{ children: string; onClick: () => void }> = ({
  children,
  onClick,
}) => {
  return (
    <Link
      className="hoverLink"
      sx={{ wordBreak: 'break-all' }}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}

export const BannerPreview: FC<Props> = ({
  bannerFormData,
  header = true,
  fileUrl = true,
  handleMarkAsRead,
  isLoading,
}): ReactElement => {
  const [viewMore, setViewMore] = useState(false)

  return (
    <Box>
      {header && (
        <>
          <Typography variant="h3" pb={2}>
            Banner Preview
          </Typography>
          <Divider />
        </>
      )}
      <Box mt={!header ? 2 : 4} mb={3} className="banner">
        <Grid p={2}>
          <Typography className="flexVerticalCenter message">
            <span>{bannerFormData.message}</span>
            <Button
              className="acr-button bg-blue size-small viewButton"
              onClick={() => setViewMore((prev) => !prev)}
            >
              {viewMore ? VIEW_LESS : VIEW_MORE}
            </Button>
          </Typography>
          {viewMore && (
            <Typography>
              {bannerFormData.details}
              {bannerFormData.includeAttachment && (
                <Typography component="span">
                  &nbsp; Click&nbsp;
                  <InternalLink to={fileUrl ? bannerFormData.fileUrl : ''}>
                    here
                  </InternalLink>
                  &nbsp;to learn more.
                </Typography>
              )}
            </Typography>
          )}
        </Grid>
        <Typography className="flexVerticalCenter markAsRead">
          <TaskAltIcon />
          {isLoading ? (
            'Loading...'
          ) : (
            <StyledLink
              onClick={() => !header && handleMarkAsRead(bannerFormData.id)}
            >
              {MARK_AS_READ}
            </StyledLink>
          )}
        </Typography>
      </Box>
    </Box>
  )
}
