import { createContext, FC, ReactElement, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { Account, SubAccount } from '~/types/Account.model'

const AccountsContext = createContext<{
  selectedAccount: Account | null
  selectedSubAccount: SubAccount | null
  isUserHasAccessToAccount: boolean
  setUserAccess: (havingAccess: boolean) => void
  setSelectedAccount: (account: Account) => void
  setSelectedSubAccount: (subAccount: SubAccount) => void
  accounts?: Map<string, Account[]> | undefined
  subAccounts?: SubAccount[]
} | null>(null)
AccountsContext.displayName = 'AccountsContext'

interface Props {
  children: ReactElement
}

const AccountsProvider: FC<Props> = ({ children }) => {
  const queryClient = useQueryClient()
  const [account, setAccount] = useState<Account | null>(undefined)
  const [subAccount, setSubAccount] = useState<SubAccount | null>(undefined)
  const [isUserHasAccessToAccount, setIsUserHasAccessToAccount] =
    useState<boolean>(false)

  // TODO: it is possible to declare all dependent useQuery with one prefix
  const invalidateQueries = () => {
    queryClient.invalidateQueries(['open_orders'])
    queryClient.invalidateQueries(['history_orders'])
    queryClient.invalidateQueries(['all_invoices'])
    queryClient.invalidateQueries(['open_invoices'])
    queryClient.invalidateQueries(['closed_invoices'])
    queryClient.invalidateQueries(['current_price'])
    queryClient.invalidateQueries(['future_price'])
    queryClient.invalidateQueries(['open_return'])
    queryClient.invalidateQueries(['history_return'])
    queryClient.invalidateQueries(['price_change_history'])
    queryClient.invalidateQueries(['price_change_history'])
    queryClient.invalidateQueries(['division_names'])
    queryClient.invalidateQueries(['class_names'])
  }
  const setSelectedAccount = (account: Account) => {
    setAccount(account)
    setSubAccount(null)
    queryClient.invalidateQueries(['subAccounts'])
    invalidateQueries()
  }
  const setUserAccess = (havingAccess: boolean) =>
    setIsUserHasAccessToAccount(havingAccess)
  const setSelectedSubAccount = (subAccount: SubAccount) => {
    setSubAccount(subAccount)
    invalidateQueries()
  }

  return (
    <AccountsContext.Provider
      value={{
        selectedAccount: account,
        isUserHasAccessToAccount: isUserHasAccessToAccount,
        setUserAccess: setUserAccess,
        selectedSubAccount: subAccount,
        setSelectedAccount: setSelectedAccount,
        setSelectedSubAccount: setSelectedSubAccount,
      }}
    >
      {children}
    </AccountsContext.Provider>
  )
}

export { AccountsProvider }
export default AccountsContext
