import { Dispatch, SetStateAction, useState } from 'react'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { Link, Typography } from '@mui/material'

import PreLoginLayout from '~/components/common/preLoginLayout/PreLoginLayout'
import { useRegisterMutation } from '~/services/auth'
import { RegisterUserForm } from '~/types/UserForm.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import {
  companyNameValidation,
  companyPhoneValidation,
  confirmPasswordValidation,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  mobilePhoneRequiredValidation,
  passwordYupValidation,
  responsibilityAreaValidation,
  textFieldValidation,
  userTypeValidation,
} from '~/utils/helpers/helperFunctions'

import UserRegistrationForm from './components/UserRegistrationForm'

const { REGISTER, REGISTER_NEW_USER } = TEXTS

const schema = yup
  .object({
    contactEmail: emailValidation,
    password: passwordYupValidation,
    confirm_password: confirmPasswordValidation,
    responsibilityArea: responsibilityAreaValidation,
    responsibilityAreaOther: textFieldValidation,
    userType: userTypeValidation,
    userTypeOther: textFieldValidation,
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    contactPhone: mobilePhoneRequiredValidation,
    company: companyNameValidation,
    companyContactPhone: companyPhoneValidation,
  })
  .required()

const RegisterForm = ({
  complete,
}: {
  complete: Dispatch<SetStateAction<boolean>>
}) => {
  const registerMutation = useRegisterMutation()

  // FIXME: need to be improvement
  const [responseError, setResponseError] = useState('')

  const { handleSubmit, control, watch } = useForm<RegisterUserForm>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
    mode: 'onChange',
  })

  const onSubmit = (data: RegisterUserForm) => {
    setResponseError('')

    if (
      data.responsibilityArea === 'Other' &&
      data.responsibilityAreaOther !== ''
    ) {
      data.responsibilityArea = data.responsibilityAreaOther
    }
    if (data.userType === 'Other' && data.userTypeOther !== '') {
      data.userType = data.userTypeOther
    }
    data.contactEmail = data.contactEmail.trim()
    registerMutation.mutate(data, {
      onSuccess: () => {
        ReactGA.event({
          category: 'User',
          action: 'Registration Success',
        })

        complete(true)
      },
      onError: (error: any) => {
        // FIXME: need to be improvement
        let message = error.data.message
        if (error.data.status === 'CONFLICT') {
          message = (
            <>
              Email &#39;{data.contactEmail}&#39; is already in use. Please log
              in or request a&nbsp;
              <Link component={RouterLink} to="/forgot-password">
                password reset
              </Link>
              .
            </>
          )
        }

        setResponseError(message)
      },
    })
  }

  const leftPart = (
    <>
      <Typography variant="h1" my={2}>
        {REGISTER_NEW_USER}
      </Typography>
      <Typography className="formPageText">
        The ACR portal is for existing ACR customers only. If you are an
        existing ACR customer, please complete this form to register for a user
        ID.
        <br />
        <br />
        Only requests from existing customers will be approved for access.
        Non-ACR existing customers, please click&nbsp;
        <Link href="https://weareacr.com/contact" target="_blank">
          here
        </Link>
        &nbsp;for more information on how to open a customer account.
      </Typography>
    </>
  )

  const rightPart = (
    <UserRegistrationForm
      control={control}
      watch={watch}
      registerMutation={registerMutation}
      responseError={responseError}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      submitButton={REGISTER}
      type="newUser"
    />
  )

  return <PreLoginLayout leftChildren={leftPart} rightChildren={rightPart} />
}

export default RegisterForm
