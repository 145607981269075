import {
  ChangeEvent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'

import { useAccountMetadata } from '~/services/accounts'
import { exportProductFile } from '~/services/priceList'
import { Account, SubAccount } from '~/types/Account.model'
import { TabsType } from '~/types/TabsType.model'
import NoDataFoundModal from '~/UI/modals/NoDataFoundModal'
import { TEXTS } from '~/utils/allConstants/Constants'

import { AccountsContext } from '../Accounts'
import useSynchAccountsNavigation from '../Accounts/hook/useSynchAccountsNavigation'
import ContentWrapper from '../UI/ContentWrapper/ContentWrapper'
import useDataGridStore, {
  DataGridProvider,
} from '../UI/DataGrid/DataGridContext'
import Loading from '../UI/Loading'
import TabsTablePage from '../UI/TabsTablePage'

import { getCurrentFields } from './components/CurrentPriceListTabContent'
import { getFutureFields } from './components/FuturePriceListTabContent'
import useDivisionClassNames from './hooks/useDivisionClassNames'
import usePriceListTabs from './hooks/usePriceListTabs'

const { ACCOUNT_SELECT_MESSAGE, EXPORT_AS_PDF } = TEXTS

const PriceList = () => {
  const { active_tab } = useParams()
  const [selectedDivision, setSelectedDivision] = useState('')
  const { selectedAccount, selectedSubAccount } =
    useContext(AccountsContext) ||
    ({} as {
      selectedAccount: Account
      selectedSubAccount: SubAccount
    })

  const accounts = useMemo(() => {
    const f = {} as { accountId: string }
    if (selectedAccount) {
      f.accountId = selectedAccount.id

      if (selectedSubAccount) {
        f.accountId = selectedSubAccount.id
      }
    }
    return f
  }, [selectedAccount, selectedSubAccount])

  const { divisionNames, classNames } = useDivisionClassNames({
    selectedSubAccountId: accounts.accountId,
    currentTab: active_tab,
    selectedDivision,
  })

  const {
    setTableProps,
    tableProps,
    sort,
    filters: storeFilters,
  } = useDataGridStore()

  const { data: accountMetadata } = useAccountMetadata()

  const handleDivisionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedDivision(event.target.value)
  }

  const herculesFilters = { divisionNames, classNames, handleDivisionChange }
  const currentFields = getCurrentFields(herculesFilters)
  const futureFields = getFutureFields(herculesFilters)

  const { currentTab, priceListTabs, isLoadingFuturePriceList } =
    usePriceListTabs({
      active_tab,
      accounts,
      currentFields,
      futureFields,
      selectedAccount,
      selectedSubAccount,
    })

  useSynchAccountsNavigation(`price-list/${currentTab}`)

  useEffect(() => {
    setTableProps((prevState: TabsType) => {
      return {
        ...prevState,
        additionalFilters: {
          ...accounts,
        },
      }
    })
  }, [accounts])

  useEffect(() => {
    if (selectedAccount && selectedSubAccount) {
      setTableProps((prevState: TabsType) => {
        return {
          ...prevState,
          fields: active_tab === 'current' ? currentFields : futureFields,
        }
      })
    }
  }, [divisionNames, classNames, selectedAccount, selectedSubAccount])

  const handleExcelExport = useCallback(() => {
    ReactGA.event({
      category: 'Products',
      action: 'Export as Excel',
    })
    return exportProductFile({
      extension: 'xlsx',
      tab: currentTab,
      filters: storeFilters,
      ...tableProps.additionalFilters,
      sort: sort !== '' ? sort : undefined,
      fields: tableProps.fields,
    })
  }, [tableProps, storeFilters, sort, currentTab])

  const handlePdfExport = useCallback(() => {
    ReactGA.event({
      category: 'Products',
      action: EXPORT_AS_PDF,
    })
    return exportProductFile({
      extension: 'pdf',
      tab: currentTab,
      filters: storeFilters,
      ...tableProps.additionalFilters,
      sort: sort !== '' ? sort : undefined,
      fields: tableProps.fields,
    })
  }, [tableProps, storeFilters, sort, currentTab])

  if (
    !selectedAccount ||
    !selectedSubAccount ||
    (selectedSubAccount && accountMetadata?.priceSetupAt !== 'ACCOUNT')
  ) {
    if (!selectedAccount || !selectedSubAccount) {
      return <NoDataFoundModal message={ACCOUNT_SELECT_MESSAGE} />
    }
  }

  if (isLoadingFuturePriceList) {
    return <Loading />
  }

  return (
    <ContentWrapper>
      <TabsTablePage
        key={currentTab}
        currentTab={currentTab}
        tabs={priceListTabs}
        handleExcelExport={handleExcelExport}
        handlePdfExport={handlePdfExport}
      />
    </ContentWrapper>
  )
}

// eslint-disable-next-line react/display-name
export default memo(() => (
  <DataGridProvider initialPageSize={100}>
    <PriceList />
  </DataGridProvider>
))
