import { cloneElement, FC, memo, ReactElement, useCallback } from 'react'
import cn from 'classnames'

import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Modal, SxProps } from '@mui/material'

import CreatePortal from '~/utils/helpers/CreatePortal'

import './CustomModal.scss'

interface Props {
  className?: string
  open: boolean
  setOpen: (state: boolean) => void
  children: ReactElement
  sx?: SxProps
}

const CustomModal: FC<Props> = ({
  className,
  open,
  setOpen,
  children,
  sx,
}): ReactElement => {
  const closeModal = useCallback(() => setOpen(false), [setOpen])
  return (
    <CreatePortal domNode="root-modals">
      <Modal open={open}>
        <Box
          className={cn('custom-modal', className)}
          sx={{
            ...(Array.isArray(sx) ? sx : [sx]),
          }}
        >
          <IconButton
            aria-label="close"
            onClick={closeModal}
            className="close-icon"
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <div className="modal-body">
            {cloneElement(children, {
              closeModal,
            })}
          </div>
        </Box>
      </Modal>
    </CreatePortal>
  )
}

export default memo(CustomModal)
