import Link from '@mui/material/Link'

import { Fields } from '~/types/Fields.model'
import { Address, Bill } from '~/types/OrderDetailsData.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import { formatCurrency } from '~/utils/helpers/helperFunctions'

const { ITEM_NO, DESCRIPTION, PRICE, AMOUNT, SHIPPED, ORDERED, LINE } = TEXTS

export const fields: Fields[] = [
  { id: 'lineNumber', label: LINE, minWidth: 30 },
  {
    id: 'orderedCount',
    label: ORDERED,
    minWidth: 50,
    format: (value: number) => (value !== null ? value : '--'),
  },
  {
    id: 'shippedCount',
    label: SHIPPED,
    minWidth: 50,
    format: (value: number) => (value !== null ? value : '--'),
  },
  {
    id: 'loc',
    label: 'LOC',
    minWidth: 30,
  },
  {
    id: 'itemNo',
    label: ITEM_NO,
    minWidth: 80,
    format: (value, row) =>
      row.productInfoUrl ? (
        <Link
          href={row.productInfoUrl}
          target="_blank"
          className="cursor-pointer"
        >
          {value}
        </Link>
      ) : (
        value
      ),
  },
  {
    id: 'description',
    label: DESCRIPTION,
    minWidth: 200,
  },
  {
    id: 'unitOfMeasure',
    label: 'U/M',
    minWidth: 50,
  },
  {
    id: 'packed',
    label: 'PKD',
    minWidth: 100,
  },
  {
    id: 'discountPercent',
    label: 'Disc%',
    minWidth: 50,
  },
  {
    id: 'price',
    label: PRICE,
    minWidth: 50,
    format: (value: number) => formatCurrency(value),
  },
  {
    id: 'amount',
    label: AMOUNT,
    minWidth: 100,
    format: (value: number) => formatCurrency(value),
  },
]

export const orderSpecificContent = [
  {
    id: 'orderAdditionalInfo',
    items: [
      {
        title: 'Sold to',
        dataId: 'billingAddress',
        id: 'billingAddress',
        fields: [
          {
            id: 'billToLocation',
          },
          { id: 'billToName' },
          { id: 'addressLine1' },
          { id: 'addressLine2' },
          { id: 'addressLine3' },
          {
            id: 'city',
            format: ({ city, state, postcode }: Address) =>
              `${city}, ${state} ${postcode}`,
          },
          { id: 'country' },
        ],
      },
      {
        title: 'Ship to',
        dataId: 'shippingAddress',
        id: 'shippingAddress',
        fields: [
          {
            id: 'billToLocation',
            format: ({ billToLocation, shipToLocation }: Bill) =>
              `${billToLocation}${shipToLocation ? `-${shipToLocation}` : ''}`,
          },
          { id: 'shipToName' },
          { id: 'addressLine1' },
          { id: 'addressLine2' },
          { id: 'addressLine3' },
          {
            id: 'city',
            format: ({ city, state, postcode }: Address) =>
              `${city}, ${state} ${postcode}`,
          },
          { id: 'country' },
        ],
      },
      {
        title: 'Order Details',
        id: 'orderDetails',
        fields: [
          { name: 'Order Number', id: 'orderNumber' },
          { name: 'Customer PO', id: 'purchaseOrder' },
          { name: 'Payment Terms', id: 'paymentTerms' },
          { name: 'Order Status', id: 'orderStatus' },
          { name: 'Order Taker', id: 'orderTaker' },
        ],
      },
      {
        title: 'Shipping Details',
        id: 'shippingAddress',
        fields: [
          { name: 'Order Date', id: 'orderDate' },
          { name: 'Requested Delivery Date', id: 'reqDeliveryDate' },
          { name: 'Carrier', id: 'shipper' },
          { name: 'Tracking number', id: 'trackingNumber' },
        ],
      },
    ],
  },
]
