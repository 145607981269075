import { FC, ReactElement } from 'react'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import { Badge, CircularProgress, IconButton } from '@mui/material'

import CustomTooltip from '~/components/common/customTooltip/CustomTooltip'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorMediumGrey } = variables

type Props = {
  toggleModal: () => void
  isOrderPodLoading: boolean
  hasAttachClick: boolean
}

const AttachFileAction: FC<Props> = ({
  toggleModal,
  isOrderPodLoading,
  hasAttachClick,
}): ReactElement => {
  return (
    <CustomTooltip title="POD">
      <IconButton onClick={toggleModal}>
        <Badge
          badgeContent={
            hasAttachClick &&
            isOrderPodLoading && <CircularProgress size="10px" />
          }
        >
          <AttachFileIcon sx={{ color: acrColorMediumGrey }} />
        </Badge>
      </IconButton>
    </CustomTooltip>
  )
}

export default AttachFileAction
