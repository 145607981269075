import { FC, ReactElement } from 'react'
import { Controller } from 'react-hook-form'

import { FormControlLabel, RadioGroup } from '@mui/material'
import Radio from '@mui/material/Radio'

import { StyledFormFieldProps } from '~/types/StyledFormFieldProps.model'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorTeal } = variables

const RadioButton: FC<StyledFormFieldProps> = ({
  control,
  name,
  options,
  sx,
}): ReactElement => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <RadioGroup value={field.value} onChange={field.onChange} sx={sx}>
          {options.map((option) => (
            <FormControlLabel
              key={option.label}
              value={option.value}
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      color: acrColorTeal,
                    },
                  }}
                />
              }
              label={option.label}
            />
          ))}
        </RadioGroup>
      )}
    />
  )
}

export default RadioButton
