import { useContext, useState } from 'react'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { Typography } from '@mui/material'

import PreLoginLayout from '~/components/common/preLoginLayout/PreLoginLayout'
import { useNewRegisterMutation } from '~/services/auth'
import { CreateNewUserForm } from '~/types/UserForm.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import {
  companyNameValidation,
  companyPhoneValidation,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  mobilePhoneValidation,
  responsibilityAreaValidation,
  textFieldValidation,
  userTypeValidation,
} from '~/utils/helpers/helperFunctions'

import { NotificationContext } from '../Notification'
import UserRegistrationForm from '../Register/components/UserRegistrationForm'

const { CREATE_NEW_USER } = TEXTS

const schema = yup
  .object({
    contactEmail: emailValidation,
    responsibilityArea: responsibilityAreaValidation,
    responsibilityAreaOther: textFieldValidation,
    userType: userTypeValidation,
    userTypeOther: textFieldValidation,
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    contactPhone: mobilePhoneValidation,
    company: companyNameValidation,
    companyContactPhone: companyPhoneValidation,
  })
  .required()

const CreateUsers = () => {
  const newRegisterMutation = useNewRegisterMutation()

  const alert = useContext(NotificationContext)
  // FIXME: need to be improvement
  const [responseError, setResponseError] = useState('')

  const { handleSubmit, control, watch, reset } = useForm<CreateNewUserForm>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
    mode: 'onChange',
  })

  const onSubmit = (data: CreateNewUserForm) => {
    setResponseError('')
    if (
      data.responsibilityArea === 'Other' &&
      data.responsibilityAreaOther !== ''
    ) {
      data.responsibilityArea = data.responsibilityAreaOther
    }
    if (data.userType === 'Other' && data.userTypeOther !== '') {
      data.userType = data.userTypeOther
    }
    data.contactEmail = data.contactEmail.trim()
    newRegisterMutation.mutate(data, {
      onSuccess: () => {
        ReactGA.event({
          category: 'User',
          action: 'Registration Success',
        })
        alert.success('New User Created!')

        reset()
      },
      onError: (error: any) => {
        setResponseError(error.data.message)
      },
    })
  }

  const leftPart = (
    <>
      <Typography variant="h1" my={2}>
        {CREATE_NEW_USER}
      </Typography>
      <Typography className="formPageText">
        Fill in the required to complete the registration on behalf of a new
        user. Please ensure all information is correct. Upon clicking
        &apos;Create&apos; an email will be sent to the user to confirm their
        email and create a password.
      </Typography>
    </>
  )
  const rightPart = (
    <UserRegistrationForm
      control={control}
      watch={watch}
      registerMutation={newRegisterMutation}
      responseError={responseError}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      submitButton="Create"
      showPasswordFields={false}
      type="newUser"
    />
  )

  return <PreLoginLayout leftChildren={leftPart} rightChildren={rightPart} />
}

export default CreateUsers
