import * as React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, Grid, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'

import { useAuth } from '~/services/auth'
import { useOrderPodReq } from '~/services/orderPod'
import { theme } from '~/theme/AppTheme'
import { Invoice } from '~/types/Invoice.model'
import { Order } from '~/types/Order.model'
import { DATE_STRING_OPTIONS, TEXTS } from '~/utils/allConstants/Constants'
import { formatDate } from '~/utils/helpers/helperFunctions'

import ModalContainer from '../UI/ModalContainer'
import ModalHeader from '../UI/ModalHeader'
import { ControllerStyledTextField } from '../UI/ModalInputs'

const { CLOSE, SEND_REQUEST, INVOICE_NUMBER, ORDER_NUMBER } = TEXTS

interface Props {
  source: Invoice | Order
  closeModal?: () => void
}

const schema = yup
  .object({
    userComment: yup.string().required('Comment is Required'),
  })
  .required()

const TableAttachFileModalBody: React.FC<Props> = ({
  source,
  closeModal,
}): React.ReactElement => {
  const { data: user } = useAuth()
  const isMatchTab = useMediaQuery(theme.breakpoints.down('md'))
  const useOrderPodReqMutation = useOrderPodReq(source.orderNumber)

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
  })

  const headerData = [
    {
      label: INVOICE_NUMBER,
      value: source.invoiceNumber,
    },
    {
      label: ORDER_NUMBER,
      value: source.orderNumber,
    },
  ]

  const handleSendComment = (data: { userComment: string }) => {
    useOrderPodReqMutation.mutate(data, {
      onSuccess: () => {
        reset({ userComment: '' })
        closeModal()
      },
    })
  }

  return (
    <>
      <ModalHeader
        data={headerData}
        heading="Proof of Delivery Request"
        headerPara="Your signed proof of delivery is not yet available in the 1ACR portal. Please click on 'Send Request' and a member from our logistics team will respond to you shortly."
      />
      <Box
        sx={{
          overflowY: 'auto',
          my: 1,
          height: isMatchTab ? '100%' : 270,
        }}
      >
        <ModalContainer
          author={`${user.firstName} ${user.lastName}`}
          createdAt={formatDate(Date.now(), DATE_STRING_OPTIONS)}
          InputComponent={
            <ControllerStyledTextField name="userComment" control={control} />
          }
        />
      </Box>
      <Grid container pb={2}>
        <Grid item xs={12} gap={2} display="flex" justifyContent="center">
          <LoadingButton
            className="acr-button bg-teal"
            onClick={handleSubmit(handleSendComment)}
            loading={useOrderPodReqMutation.isLoading}
          >
            {SEND_REQUEST}
          </LoadingButton>
          <Button
            className="acr-button"
            sx={{
              width: '140px',
            }}
            onClick={closeModal}
          >
            {CLOSE}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default TableAttachFileModalBody
