import { useContext } from 'react'

import { useQuery } from '@tanstack/react-query'

import { AccountsContext } from '../components/Accounts'
import { Account, SubAccount } from '../types/Account.model'

import axiosInstance from './axiosInstance'

export const fetchAccountById = ({ queryKey }: any) => {
  const [, _id] = queryKey
  return axiosInstance
    .get<Account[] | SubAccount[]>(`/v1/accounts/${_id}`)
    .then(({ data }) => {
      return data
    })
}

type Query = {
  queryKey: any[]
}

export const fetchAccounts = ({ queryKey }: Query) => {
  const [, searchParams] = queryKey
  const params = new URLSearchParams({
    ...searchParams,
    page: searchParams.page.toString(),
    pageSize: searchParams.pageSize.toString(),
  })
  return axiosInstance.get('/v1/accounts?' + params).then(({ data }) => data)
}

export const fetchAccountMetadata = ({ queryKey }: Query) => {
  const [, _id] = queryKey
  return axiosInstance
    .get(`/v1/accounts/${_id}/metadata`)
    .then(({ data }) => data)
}

export const useAccountMetadata = () => {
  const { selectedAccount, isUserHasAccessToAccount } =
    useContext(AccountsContext)
  return useQuery(
    ['accounts', selectedAccount?.id, 'metadata'],
    fetchAccountMetadata,
    {
      enabled: !!selectedAccount && isUserHasAccessToAccount,
    }
  )
}
