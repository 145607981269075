import { ChangeEvent, FC, Fragment, memo, ReactElement } from 'react'
import cn from 'classnames'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { KeyboardBackspace as KeyboardBackspaceIcon } from '@mui/icons-material'
import {
  Box,
  Container,
  IconButton,
  Link,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  useMediaQuery,
} from '@mui/material'

import CustomTooltip from '~/components/common/customTooltip/CustomTooltip'
import FilterGridData from '~/components/common/filterGridData/FilterGridData'
import { theme } from '~/theme/AppTheme'
import { Fields } from '~/types/Fields.model'
import { Actions } from '~/types/TabsType.model'
import CustomModal from '~/UI/modals/CustomModal'
import { TEXTS } from '~/utils/allConstants/Constants'
import { TableCellFormat } from '~/utils/helpers/helperFunctions'

import { GlobalLoading } from '../Loading'

import DataGridFilters from './DataGridFilters/DataGridFilters'
import useDataGridStore from './DataGridContext'

import './DataGrid.scss'
import variables from '~/theme/scss/_variables.module.scss'

const {
  acrColorBlueLight,
  acrColorBlack,
  acrColorBluishWhite,
  acrColorBlue,
  acrColorLightGrey2,
  acrColorRedishGrey,
  acrColorLime,
} = variables
const { NO_DATA_FOUND } = TEXTS

const ReversedKeyboardBackspaceIcon = () => (
  <KeyboardBackspaceIcon
    sx={{ transform: 'rotate(180deg)', color: acrColorBlack }}
  />
)

const StyledKeyboardBackspaceIcon = () => (
  <KeyboardBackspaceIcon sx={{ color: acrColorBlack }} />
)

export const InternalLink: FC<{ to: string; children: string }> = ({
  to,
  children,
}) => {
  to = to ?? ''
  let props: any = {
    component: RouterLink,
    to: to,
  }
  if (to.indexOf('http') !== -1) {
    props = {
      component: 'a',
      href: to,
      target: '_blank',
    }
  }
  return (
    <Link className="hoverLink" {...props}>
      {children}
    </Link>
  )
}

const iconButtonSx = (isCellActive: boolean) => {
  return {
    height: 32,
    width: 32,
    backgroundColor: isCellActive ? acrColorLime : acrColorBluishWhite,
    borderRadius: '4px',
    border: '1px solid',
    borderColor: isCellActive ? acrColorLime : acrColorBlue,
    mr: 1,
    '&:hover': {
      backgroundColor: isCellActive ? acrColorLime : acrColorBluishWhite,
    },
  }
}

const DataGrid = (): ReactElement => {
  const {
    tableProps,
    page,
    setPage,
    pageSize,
    openFilterModal,
    setOpenFilterModal,
    data,
    isFetching,
    isError,
    getIsCellActive,
    getIsGroupActive,
    applyGrouping,
    getSortingDirection,
    applyCellSort,
    changePageSize,
  } = useDataGridStore()
  const { pathname } = useLocation()
  const dataItems = data?.items
  const isApprovalRelatedPages = pathname.includes('/approvals/')
  const isManageUsersPage = pathname.includes('/manage/users')
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleChangePageSize = (event: SelectChangeEvent) => {
    changePageSize(event.target.value)
  }

  const handleChangePage = (event: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage)
  }

  const checkFilterFieldConfiguration = tableProps.fields?.some(
    (field: Fields) => !!field?.filterFieldConfiguration
  )

  const tableActions = tableProps.actions

  return (
    <Container maxWidth={false} disableGutters>
      <Box>
        <TableContainer
          sx={{
            mt: tableProps.name ? 5 : 0,
            border: '1px solid',
            borderColor: acrColorRedishGrey,
          }}
          component={Paper}
        >
          <Table
            sx={{
              height:
                (isFetching && dataItems.length === 0) || isError ? 439 : '',
            }}
            aria-label="custom pagination table"
            stickyHeader
          >
            <TableHead>
              <TableRow sx={{ textAlign: 'center' }}>
                {tableProps.fields?.map((column: any) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      borderBottom: '2px solid',
                      borderBottomColor: acrColorLightGrey2,
                    }}
                  >
                    <div className="flexVerticalCenter">
                      {column.hasOwnProperty('icon') && (
                        <IconButton
                          sx={{
                            ...iconButtonSx(getIsGroupActive(column.id)),
                          }}
                          onClick={() =>
                            applyGrouping(
                              column.id,
                              column.label,
                              column.groupType
                            )
                          }
                        >
                          {column.icon(
                            getIsGroupActive(column.id)
                              ? acrColorBluishWhite
                              : acrColorBlue
                          )}
                        </IconButton>
                      )}
                      {column.hasOwnProperty('sort') && column.sort ? (
                        <TableSortLabel
                          active={getIsCellActive(column.id)}
                          direction={getSortingDirection(column.id)}
                          onClick={() => applyCellSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      ) : (
                        column.label
                      )}
                    </div>
                  </TableCell>
                ))}

                {(checkFilterFieldConfiguration ||
                  tableActions?.length > 0) && (
                  <TableCell
                    className={
                      checkFilterFieldConfiguration && isApprovalRelatedPages
                        ? 'stickyTableCell'
                        : ''
                    }
                    sx={{
                      borderBottom: '2px solid',
                      borderBottomColor: acrColorLightGrey2,
                    }}
                  >
                    <div className="table-actions-wrapper">
                      {tableActions?.map((actionProps: any) => {
                        return actionProps.header ? actionProps.header() : null
                      })}
                      {checkFilterFieldConfiguration &&
                        (!isTabletOrMobile ||
                          isApprovalRelatedPages ||
                          isManageUsersPage) && <FilterGridData />}
                    </div>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {(isError || (!isFetching && dataItems.length === 0)) && (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={
                      tableProps?.fields?.length +
                      (checkFilterFieldConfiguration ||
                      tableProps?.actions?.length > 0
                        ? 1
                        : 0)
                    }
                  >
                    <strong>
                      {!!tableProps.notFoundMessage
                        ? tableProps.notFoundMessage
                        : NO_DATA_FOUND}
                    </strong>
                  </TableCell>
                </TableRow>
              )}

              {!isError &&
                dataItems.length > 0 &&
                dataItems.map((row: any, index: any) => {
                  if (row.hasOwnProperty('type') && row.type === 'group') {
                    return (
                      <Fragment key={row.id + index}>
                        <TableRow
                          sx={{
                            height: 56,
                          }}
                        >
                          <TableCell
                            colSpan={
                              tableProps?.fields?.length +
                              (checkFilterFieldConfiguration ||
                              tableActions?.length > 0
                                ? 1
                                : 0)
                            }
                            sx={[
                              {
                                backgroundColor: acrColorBlueLight,
                                color: acrColorBlue,
                                fontWeight: 500,
                                borderLeft: '5px solid',
                                borderLeftColor: acrColorLime,
                              },
                              row.hasOwnProperty('rowSx') ? row.rowSx : {},
                            ]}
                          >
                            <span>{row.groupLabel}</span>:&nbsp;
                            <strong>{row.groupItemName}</strong>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    )
                  } else {
                    return (
                      <Fragment key={row.id + index}>
                        <TableRow sx={{ height: 73 }}>
                          {tableProps.fields.map((column: any) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              <TableCellFormat column={column} row={row} />
                            </TableCell>
                          ))}
                          {(checkFilterFieldConfiguration ||
                            tableActions?.length > 0) && (
                            <TableCell
                              className={
                                checkFilterFieldConfiguration &&
                                isApprovalRelatedPages
                                  ? 'stickyTableCell'
                                  : ''
                              }
                            >
                              <div
                                className={
                                  checkFilterFieldConfiguration
                                    ? 'table-actions-wrapper'
                                    : ''
                                }
                              >
                                {tableActions?.map(
                                  ({
                                    render,
                                    icon,
                                    tooltip,
                                    click,
                                    disabled,
                                  }: Actions) => (
                                    <Fragment key={index}>
                                      {render ? (
                                        render(row, index)
                                      ) : (
                                        <CustomTooltip title={tooltip}>
                                          <IconButton
                                            onClick={click?.bind(this, row)}
                                            disabled={
                                              disabled &&
                                              disabled.call(this, row)
                                            }
                                          >
                                            {icon}
                                          </IconButton>
                                        </CustomTooltip>
                                      )}
                                    </Fragment>
                                  )
                                )}
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      </Fragment>
                    )
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {!isError && !!dataItems && !!data?.totalCount && (
          <Box
            className={cn(
              'pagination-wrapper',
              isTabletOrMobile && 'mobile-version'
            )}
          >
            <Pagination
              className="pagination-nav"
              count={
                pageSize === 'all'
                  ? 1
                  : data.totalCount
                  ? Math.ceil(data.totalCount / pageSize)
                  : 0
              }
              page={page}
              defaultPage={1}
              size={isTabletOrMobile ? 'medium' : 'large'}
              siblingCount={isTabletOrMobile ? 0 : 1}
              onChange={handleChangePage}
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: StyledKeyboardBackspaceIcon,
                    next: ReversedKeyboardBackspaceIcon,
                  }}
                  className="pagination-item"
                  {...item}
                />
              )}
            />
            <Select
              value={pageSize}
              label="rows per page"
              onChange={handleChangePageSize}
              className="page-size-select"
            >
              {(
                tableProps.pageSizeOptions || [
                  { value: 10, label: '10' },
                  { value: 20, label: '20' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' },
                ]
              ).map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Box>

      {isFetching && <GlobalLoading />}

      <CustomModal open={openFilterModal} setOpen={setOpenFilterModal}>
        <DataGridFilters />
      </CustomModal>
    </Container>
  )
}

export default memo(DataGrid)
