import { FC, ReactElement, useCallback, useEffect, useState } from 'react'

import { Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { fields as invoiceFields } from '~/components/Invoices/components/AllInvoicesTabContent'
import DataGrid from '~/components/UI/DataGrid/DataGrid'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import {
  createInvoiceComment,
  fetchAllInvoiceComments,
  fetchInvoiceComments,
  fetchInvoices,
} from '~/services/invoices'
import { useOrderPod } from '~/services/orderPod'
import { Invoice } from '~/types/Invoice.model'
import CustomModal from '~/UI/modals/CustomModal'
import OrderInvoiceActionButton from '~/UI/modals/modalBodies/tableCommentModalBody/actionButton/OrderInvoiceActionButton'
import TableAttachFileModalBody from '~/UI/modals/modalBodies/tableCommentModalBody/TableAttachFileModalBody'
import TableCommentModalBody from '~/UI/modals/modalBodies/tableCommentModalBody/TableCommentModalBody'
import { PAGE_TITLES, TEXTS } from '~/utils/allConstants/Constants'
import { downloadFileRes } from '~/utils/helpers/helperFunctions'

const { NO_DATA_MESSAGE } = TEXTS
const { INVOICES } = PAGE_TITLES

type Props = {
  filters: any
}

const SearchResultsInvoices: FC<Props> = ({ filters }): ReactElement => {
  const { setTableProps, tableProps, data } = useDataGridStore()

  const [openCommentModal, setOpenCommentModal] = useState(false)
  const [commentModalData, setCommentModalData] = useState<Invoice>()
  const [openAttachFileModal, setOpenAttachFileModal] = useState(false)
  const [attachFileModalData, setAttachFileModalData] = useState<Invoice>()
  const [attachFileRow, setAttachFileRow] = useState<Invoice>()

  const useOrderPodMutation = useOrderPod()

  const { data: comments, isFetching } = useQuery(
    ['invoices', 'comments', data?.items],
    async () => {
      const invoiceNumbers = data?.items.map(
        (itm: Invoice) => itm.invoiceNumber
      )
      return await fetchAllInvoiceComments(invoiceNumbers)
    },
    {
      enabled: data?.items.length > 0,
      placeholderData: [],
    }
  )

  const toggleCommentModal = useCallback((rowData: Invoice) => {
    setOpenCommentModal((prevState) => {
      if (!prevState) {
        setCommentModalData(rowData)
      }
      return !prevState
    })
  }, [])

  const toggleAttachFileModal = useCallback((rowData: Invoice) => {
    setAttachFileRow(rowData)
    useOrderPodMutation.mutate(rowData.orderNumber, {
      onSuccess: (response) => {
        const filename = `Order-POD-${rowData.orderNumber}.pdf`
        return downloadFileRes({ response, filename })
      },
      onError: () => {
        setOpenAttachFileModal((prevState) => {
          if (!prevState) {
            setAttachFileModalData(rowData)
          }
          return !prevState
        })
      },
    })
  }, [])

  const table = {
    name: 'search_invoices',
    additionalFilters: filters,
    source: fetchInvoices,
    fields: invoiceFields,
    actions: [
      {
        render: (row: Invoice) => {
          return (
            <OrderInvoiceActionButton
              hasComments={comments?.[row.invoiceNumber]?.length}
              toggleAttachFileModal={toggleAttachFileModal.bind(this, row)}
              toggleCommentModal={toggleCommentModal.bind(this, row)}
              isFetching={isFetching}
              isOrderPodLoading={useOrderPodMutation.isLoading}
              hasAttachClick={row === attachFileRow}
            />
          )
        },
      },
    ],
    notFoundMessage: NO_DATA_MESSAGE('Invoices'),
  }

  useEffect(() => {
    setTableProps(table)
  }, [isFetching, filters, useOrderPodMutation.isLoading])

  if (Object.keys(tableProps).length === 0) {
    return null
  }

  return (
    <>
      <Typography variant="h5" mt={3}>
        {INVOICES}
      </Typography>
      <DataGrid />
      <CustomModal open={openCommentModal} setOpen={setOpenCommentModal}>
        <TableCommentModalBody
          source={commentModalData}
          name="invoices"
          dataActions={{
            fetchSource: fetchInvoiceComments,
            createSource: createInvoiceComment,
          }}
        />
      </CustomModal>
      <CustomModal open={openAttachFileModal} setOpen={setOpenAttachFileModal}>
        <TableAttachFileModalBody source={attachFileModalData} />
      </CustomModal>
    </>
  )
}

// eslint-disable-next-line react/display-name
export default (props: Props) => {
  return (
    <DataGridProvider>
      <SearchResultsInvoices {...props} />
    </DataGridProvider>
  )
}
