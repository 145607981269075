import AccountLoader from '../components/Accounts/AccountLoader'
import InvoiceDetail from '../components/InvoiceDetail/InvoiceDetail'
import InvoiceOrderDetail from '../components/InvoiceDetail/InvoiceOrderDetail'
import Invoices from '../components/Invoices/Invoices'
import { RoutesCheck } from '../utils/allConstants/Constants'
import { RedirectWithAccountParams } from '../utils/routes-util'

import CheckRoutes from './CheckRoutes'

export default [
  {
    path: 'invoices',
    element: <RedirectWithAccountParams to="/invoices/all-invoices" />,
  },
  {
    path: ':accountId/invoices',
    element: (
      <RedirectWithAccountParams to="/:accountId/invoices/all-invoices" />
    ),
  },
  {
    path: ':accountId/:subAccountId/invoices',
    element: (
      <RedirectWithAccountParams to="/:accountId/:subAccountId/invoices/all-invoices" />
    ),
  },

  {
    path: 'invoices/:active_tab',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE, RoutesCheck.NOT_CARRIER]}>
        <Invoices />
      </CheckRoutes>
    ),
  },
  {
    path: ':accountId/invoices/:active_tab',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE, RoutesCheck.NOT_CARRIER]}>
        <AccountLoader>
          <Invoices />
        </AccountLoader>
      </CheckRoutes>
    ),
  },
  {
    path: ':accountId/:subAccountId/invoices/:active_tab',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE, RoutesCheck.NOT_CARRIER]}>
        <AccountLoader>
          <Invoices />
        </AccountLoader>
      </CheckRoutes>
    ),
  },
  {
    path: 'invoices/:id/details',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE]}>
        <InvoiceDetail />
      </CheckRoutes>
    ),
  },
  {
    path: 'invoices/:id/order/:orderId/details',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE, RoutesCheck.NOT_CARRIER]}>
        <InvoiceOrderDetail />
      </CheckRoutes>
    ),
  },
]
