import { FC } from 'react'
import { Controller } from 'react-hook-form'

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material'

import { StyledFormFieldProps } from '~/types/StyledFormFieldProps.model'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorTeal } = variables

const GroupCheckbox: FC<StyledFormFieldProps> = ({
  name,
  control,
  items,
  sx,
}) => {
  const handleSelect = (idChecked: any, values: any) => {
    const newNames = values?.includes(idChecked)
      ? values?.filter((id: string) => id !== idChecked)
      : [...values, idChecked]
    return newNames
  }
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <FormGroup sx={sx}>
            {items.map((item) => {
              const value = Object.values(item)
              return (
                <FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox
                      checked={field.value?.includes(value[0])}
                      disabled={value[2]}
                      onChange={() =>
                        field.onChange(handleSelect(value[0], field.value))
                      }
                      size="small"
                      sx={{
                        '& .MuiSvgIcon-root': {
                          color: acrColorTeal,
                        },
                      }}
                    />
                  }
                  label={value[1]}
                />
              )
            })}
          </FormGroup>
          {!!error && (
            <FormHelperText error={!!error}>
              {!!error ? error.message : ''}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}

export default GroupCheckbox
