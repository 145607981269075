import { FC, ReactElement } from 'react'

import Tooltip from '@mui/material/Tooltip'
import useMediaQuery from '@mui/material/useMediaQuery'

import { theme } from '~/theme/AppTheme'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorLime } = variables

type Props = {
  children: ReactElement
  title: string | ReactElement
}

const CustomTooltip: FC<Props> = ({ children, title, ...rest }) => {
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Tooltip
      arrow
      placement="top"
      title={title}
      enterDelay={isTabletOrMobile ? 0 : 200}
      leaveDelay={100}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: acrColorLime,
          },
        },
        arrow: {
          sx: {
            color: acrColorLime,
          },
        },
      }}
      {...rest}
    >
      {children}
    </Tooltip>
  )
}

export default CustomTooltip
