import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import HelpIcon from '@mui/icons-material/Help'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material'

import CustomTooltip from '~/components/common/customTooltip/CustomTooltip'
import PasswordTooltip from '~/components/common/passwordTooltip/PasswordTooltip'
import PreLoginLayout from '~/components/common/preLoginLayout/PreLoginLayout'
import { useResetPasswordByTokenMutation } from '~/services/users'
import { theme } from '~/theme/AppTheme'
import { PasswordsData } from '~/types/Auth.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import {
  confirmNewPasswordValidation,
  passwordValidation,
} from '~/utils/helpers/helperFunctions'

import { BootstrapInput } from '../Form/StyledFormControl'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorLime } = variables
const {
  EMAIL,
  CONFIRM_NEW_PASSWORD,
  NEW_PASSWORD,
  FORGOT_PASSWORD,
  CURRENT_PASSWORD,
  RESET_PASSWORD,
  NO_OLD_PASSWORD_MESSAGE,
  NO_NEW_PASSWORD_MESSAGE,
} = TEXTS

type ResetPasswordFormProps = {
  username: string
  token: string
  complete: (val: boolean) => void
}

const schema = yup
  .object({
    oldPassword: yup.string().default('').required(NO_OLD_PASSWORD_MESSAGE),
    newPassword: yup
      .string()
      .default('')
      .when('oldPassword', (oldPassword) => {
        if (oldPassword) {
          return yup
            .string()
            .required(NO_NEW_PASSWORD_MESSAGE)
            .test('validation', 'error', passwordValidation)
        }
      }),
    confirmPassword: confirmNewPasswordValidation,
  })
  .required()

const ResetPasswordForm = ({
  username,
  token,
  complete,
}: ResetPasswordFormProps) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const { handleSubmit, control } = useForm<PasswordsData>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
    mode: 'onChange',
  })
  const resetPassword = useResetPasswordByTokenMutation(token)
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const onSubmit = (data: PasswordsData) => {
    resetPassword.mutate(data, {
      onSuccess: () => {
        complete(true)
      },
    })
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const leftPart = (
    <>
      <Typography variant="h1" my={2}>
        Reset Your Password for Enhanced Security
      </Typography>
      <Typography className="formPageText">
        As part of our ongoing commitment to your security, we are requesting
        that you reset your account password. This action will help ensure the
        continued safety of your account and protect our valued customer&apos;s
        information.
      </Typography>
    </>
  )

  const rightPart = (
    <>
      <Typography variant="h3" mb={3}>
        Please take a moment to reset your password.
      </Typography>
      <Grid container alignItems="center" mb={2}>
        <Grid item xs={12} md={4} pb={isTablet ? 1 : 0} pr={isTablet ? 0 : 1}>
          {EMAIL}
        </Grid>
        <Grid item xs={12} md={8}>
          <BootstrapInput
            defaultValue={username}
            fullWidth={true}
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" mb={2}>
        <Grid item xs={12} md={4} pb={isTablet ? 1 : 0} pr={isTablet ? 0 : 1}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {CURRENT_PASSWORD}
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller
            name="oldPassword"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <BootstrapInput
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!error}
                  fullWidth={true}
                  placeholder={CURRENT_PASSWORD}
                  type={showCurrentPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle current password visibility"
                        onClick={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showCurrentPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!error && (
                  <FormHelperText error={!!error}>
                    {!!error ? error.message : ''}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end" my={1}>
          <Link component={RouterLink} to={`/forgot-password`}>
            {FORGOT_PASSWORD}
          </Link>
        </Grid>
      </Grid>
      <Grid container alignItems="center" mb={2}>
        <Grid item xs={12} md={4} pb={isTablet ? 1 : 0} pr={isTablet ? 0 : 1}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <span>{NEW_PASSWORD}</span>
            <CustomTooltip title={<PasswordTooltip />}>
              <HelpIcon sx={{ color: acrColorLime, fontSize: 20 }} />
            </CustomTooltip>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller
            name="newPassword"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <BootstrapInput
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!error}
                  fullWidth={true}
                  placeholder={NEW_PASSWORD}
                  type={showNewPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle new password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showNewPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!error && (
                  <FormHelperText error={!!error}>
                    {!!error ? error.message : ''}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" mb={2}>
        <Grid item xs={12} md={4} pb={isTablet ? 1 : 0} pr={isTablet ? 0 : 1}>
          {CONFIRM_NEW_PASSWORD}
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <BootstrapInput
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!error}
                  fullWidth={true}
                  placeholder={CONFIRM_NEW_PASSWORD}
                  type={showConfirmPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!error && (
                  <FormHelperText error={!!error}>
                    {!!error ? error.message : ''}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ textAlign: 'right', mt: 3 }}>
        <LoadingButton
          className="acr-button bg-teal "
          size="large"
          fullWidth={isTablet}
          onClick={handleSubmit(onSubmit)}
          loading={resetPassword.isLoading}
        >
          {RESET_PASSWORD}
        </LoadingButton>
      </Box>
    </>
  )

  return <PreLoginLayout leftChildren={leftPart} rightChildren={rightPart} />
}

export default ResetPasswordForm
