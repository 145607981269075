import { FC } from 'react'
import { Controller } from 'react-hook-form'

import {
  InputBase,
  InputBaseProps,
  styled,
  StyledComponentProps,
  TextFieldProps,
} from '@mui/material'

import { StyledFormFieldProps } from '~/types/StyledFormFieldProps.model'

import variables from '~/theme/scss/_variables.module.scss'

const {
  acrColorLime,
  acrColorWhite,
  acrColorRed,
  acrColorGrey,
  acrColorBlack,
} = variables

export const FilterStyledInput: FC<StyledComponentProps | InputBaseProps> =
  styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(4),
    },
    '.placeholder': {
      color: 'rgba(90, 90, 90, 0.6)',
    },
    '&.MuiInputBase-root': {
      borderRadius: 1,
      position: 'relative',
      backgroundColor: acrColorWhite,
      border: '1px solid',
      borderColor: acrColorGrey,
      fontSize: 16,
      padding: '6px 8px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&.Mui-focused': {
        borderColor: acrColorLime,
      },
      '&.Mui-error': {
        borderColor: acrColorRed,
      },
      '&::placeholder': {},
    },
    '& .MuiTypography-root': {
      color: acrColorBlack,
    },
  }))

export const FilterTextField = ({
  label,
  name,
  control,
  ...rest
}: StyledFormFieldProps & TextFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FilterStyledInput
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          error={!!error}
          fullWidth={true}
          placeholder={label}
          {...rest}
        />
      )}
    />
  )
}
