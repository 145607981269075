import ReactGA from 'react-ga4'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, FormHelperText, Typography, useMediaQuery } from '@mui/material'
import { UseQueryResult } from '@tanstack/react-query'

import PreLoginLayout from '~/components/common/preLoginLayout/PreLoginLayout'
import { useForgotPasswordMutation } from '~/services/auth'
import { theme } from '~/theme/AppTheme'
import { User } from '~/types/User.model'
import { TEXTS } from '~/utils/allConstants/Constants'

import { BootstrapInput } from '../Form/StyledFormControl'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue } = variables

const { EMAIL, EMAIL_FORMAT_MESSAGE, NO_EMAIL_MESSAGE } = TEXTS

const schema = yup
  .object({
    email: yup.string().email(EMAIL_FORMAT_MESSAGE).required(NO_EMAIL_MESSAGE),
  })
  .required()

const ForgotPasswordForm = ({
  complete,
  auth,
}: {
  complete: (val: boolean) => void
  auth: UseQueryResult<User | undefined>
}) => {
  const forgotPasswordMutation = useForgotPasswordMutation()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  })
  const onSubmit = (form: { email: string }) => {
    forgotPasswordMutation.mutate(form, {
      onSuccess: () => {
        ReactGA.event({
          category: 'User',
          action: 'Forgot Password Request',
        })

        complete(true)
      },
    })
  }

  const leftPart = (
    <>
      {auth.data ? (
        <>
          <Typography variant="h3" my={2}>
            Don&apos;t worry, we have your back on this.
            <br />
            We help people all the time.
          </Typography>
        </>
      ) : (
        <Typography variant="h1" my={2} textAlign="center">
          Forgot Password?
        </Typography>
      )}
    </>
  )

  const rightPart = (
    <>
      <Typography variant="h5" mb={2} sx={{ color: acrColorBlue }}>
        Please enter your registered email address to change your password.
      </Typography>
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <BootstrapInput
              name={field.name}
              placeholder={EMAIL}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              error={!!error}
              fullWidth={true}
            />
            {!!error && (
              <FormHelperText error={!!error}>
                {!!error ? error.message : ''}
              </FormHelperText>
            )}
          </>
        )}
      />
      <Box sx={{ textAlign: 'right', mt: 3 }}>
        <LoadingButton
          loading={forgotPasswordMutation.isLoading}
          className="acr-button bg-teal size-big"
          size="large"
          fullWidth={isTablet}
          onClick={handleSubmit(onSubmit)}
        >
          Send Reset Email
        </LoadingButton>
      </Box>
    </>
  )

  return <PreLoginLayout leftChildren={leftPart} rightChildren={rightPart} />
}

export default ForgotPasswordForm
