import { useContext } from 'react'
import ReactGA from 'react-ga4'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { PasswordsData } from '~/types/Auth.model'

import { NotificationContext } from '../components/Notification'
import { AllAccount, AllSubAccount } from '../types/Account.model'
import { APIErrorRes } from '../types/APIErrorRes.model'
import { UserFilter } from '../types/Filter.model'
import { PageParams } from '../types/PageParams.model'
import { SalesPersons, SalesPersonsItem } from '../types/SalesPersons.model'
import {
  Claims,
  Options,
  SearchParams,
  User,
  UserParams,
  UserSummary,
} from '../types/User.model'
import { castNullValues } from '../utils/helpers/helperFunctions'

import axiosInstance from './axiosInstance'
import { createQueryParams } from './filters-utils'

export enum Roles {
  ROLE_SUPER_ADMIN = 'Administrator',
  ROLE_CUSTOMER = 'Customer',
  ROLE_EMPLOYEE = 'ACR Corporate Employee',
  ROLE_BROKER = 'Broker',
  ROLE_NEW_USER = 'New User',
  ROLE_CARRIER = 'Carrier',
}

export enum RoleTypes {
  ADMIN = 'ROLE_SUPER_ADMIN',
  CUSTOMER = 'ROLE_CUSTOMER',
  EMPLOYEE = 'ROLE_EMPLOYEE',
  BROKER = 'ROLE_BROKER',
  NEW_USER = 'ROLE_NEW_USER',
  CARRIER = 'ROLE_CARRIER',
}

export enum UserTypes {
  USER_TYPE_CUSTOMER = 'Customer',
  USER_TYPE_BROKER = 'Broker',
  USER_TYPE_ACR_EMPLOYEE = 'ACR Employee',
}

export enum UserStatus {
  ACTIVE = 'Active',
  PENDING_APPROVAL = 'Pending Approval',
  DISABLED = 'Disabled',
  NOT_APPROVED = 'Not Approved',
  LOCKED = 'Locked',
}

export const fetchUsers = ({
  filters,
  fields,
  ...params
}: PageParams<UserFilter>) => {
  const qParams = createQueryParams(filters, fields)
  return axiosInstance
    .get<UserSummary>('/v1/admin/customers', {
      params: { ...params, ...qParams },
    })
    .then(({ data }) => data)
}

export const fetchUserById = (customerId: string) => {
  return axiosInstance
    .get<UserParams>(`/v1/admin/customers/${customerId}`)
    .then(({ data }) => castNullValues(data))
    .then((data) => {
      return {
        ...data,
        claims: data.userClaims.map(({ claim }: { claim: string }) => claim),
      }
    })
}

export const fetchAreaOfResponsibilities = () => {
  return axiosInstance
    .get<[string]>('/v1/responsibility-areas')
    .then(({ data }) => data)
}

export const fetchACREmployeeJobTitles = () => {
  return axiosInstance.get<[string]>('/v1/job-titles').then(({ data }) => data)
}

export const useAreaOfResponsibility = () => {
  return useQuery(['area-Of-Responsibility'], fetchAreaOfResponsibilities, {})
}

export const useChangeCurrentUser = (customerId: string) => {
  const queryClient = useQueryClient()
  const alert = useContext(NotificationContext)

  const mutation = useMutation(
    (data: PasswordsData) => {
      return axiosInstance.put<User>(`/v1/customers/${customerId}`, data)
    },
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(['useAuth'], data)
        alert.success('Password successfully updated')
      },
      onError: (e: APIErrorRes) => {
        alert.error(e.response.data.message || 'Profile was not completed')
      },
    }
  )

  return mutation
}

export const useChangeUser = (customerId: string) => {
  const queryClient = useQueryClient()
  const alert = useContext(NotificationContext)

  const mutation = useMutation(
    (data: UserParams) => {
      return axiosInstance.put<UserParams>(
        `/v1/admin/customers/${customerId}`,
        data
      )
    },
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(['users', customerId], data)
        queryClient.invalidateQueries(['users'])
        queryClient.invalidateQueries(['dashboard_users'])

        alert.success('User details successfully updated')
      },
      onError: (e: APIErrorRes) => {
        alert.error(e.response.data.message || 'User details was not completed')
      },
    }
  )

  return mutation
}

export const useSurveyComplete = (customerId: string) => {
  const alert = useContext(NotificationContext)
  const mutation = useMutation(
    () => {
      return axiosInstance.put<UserParams>(
        `/v1/customers/${customerId}/survey/taken`
      )
    },
    {
      onError: (e: APIErrorRes) => {
        alert.error(e.response.data.message || 'Survey was not completed')
      },
    }
  )

  return mutation
}

export const useChangePasswordByTokenMutation = (token: string) => {
  const alert = useContext(NotificationContext)
  const mutation = useMutation(
    (data: { password: string; confirm_password: string }) => {
      return axiosInstance.post<{ password: string }>(
        `/v1/auth/password?token=${token}`,
        data
      )
    },
    {
      onError: (e: APIErrorRes) => {
        if (
          e.response.data.message ===
          'Verification token is not valid or expired!'
        ) {
          alert.error(
            'Verification link expired. Return to login, click "Forgot Password" for a new link.'
          )
        }
      },
    }
  )

  return mutation
}

export const useResetPasswordByTokenMutation = (token: string) => {
  const alert = useContext(NotificationContext)
  const mutation = useMutation(
    (data: PasswordsData) => {
      return axiosInstance.post<PasswordsData>(
        `/v1/auth/resetPassword?token=${token}`,
        data
      )
    },
    {
      onError: (e: APIErrorRes) => {
        {
          alert.error(e.response.data.message)
        }
      },
    }
  )

  return mutation
}

export const useSetPasswordByTokenMutation = (token: string) => {
  const alert = useContext(NotificationContext)
  const mutation = useMutation(
    (data: { password: string }) => {
      return axiosInstance.post<{ password: string }>(
        `/v1/auth/setPassword?token=${token}`,
        data
      )
    },
    {
      onError: (e: APIErrorRes) => {
        {
          alert.error(e.response.data.message)
        }
      },
    }
  )

  return mutation
}

export const usePasswordResetPassword = () => {
  const alert = useContext(NotificationContext)

  return useMutation(
    (email) => {
      return axiosInstance.post<{ email: string }>(
        '/v1/admin/auth/password/reset',
        { email }
      )
    },
    {
      onSuccess: () => {
        alert.success('Password Reset Email Sent')

        ReactGA.event({
          category: 'User',
          action: 'Password Reset Request',
        })
      },
    }
  )
}

export const useResendConfirmationEmail = () => {
  const alert = useContext(NotificationContext)

  return useMutation(
    (email) => {
      return axiosInstance.post<{ email: string }>('/v1/auth/email/confirm', {
        email,
      })
    },
    {
      onSuccess: () => {
        alert.success('Resend Confirmation Email successfully')

        ReactGA.event({
          category: 'User',
          action: 'Resend Confirmation Email successfully',
        })
      },
    }
  )
}

export const useAllAccounts = (
  searchParams: SearchParams,
  options: Options
) => {
  return useQuery(
    ['admin/accounts', searchParams],
    () => {
      // @ts-ignore: Unmatched type error
      const params = new URLSearchParams({
        ...searchParams,
        page: searchParams.page.toString(),
        pageSize: searchParams.pageSize.toString(),
        type: 'ACCOUNT',
        assignedOnly: false,
      })
      return axiosInstance
        .get<AllAccount>('/v1/accounts?' + params)
        .then(({ data }) => data)
    },
    options
  )
}

export const useAllSubAccounts = (
  searchParams: SearchParams,
  options: Options
) => {
  return useQuery(
    ['admin/subaccounts', searchParams],
    () => {
      // @ts-ignore: Unmatched type error
      const params = new URLSearchParams({
        ...searchParams,
        page: searchParams.page.toString(),
        pageSize: searchParams.pageSize.toString(),
        type: 'SUBACCOUNT',
        assignedOnly: false,
      })
      return axiosInstance
        .get<AllSubAccount>('/v1/accounts?' + params)
        .then(({ data }) => data)
    },
    options
  )
}

export const fetchAllAccountsByUserId = (
  customer: UserParams,
  { fields: _, ...params }: PageParams<unknown>
) => {
  const qParams = {
    ...params,
    customerId: customer.id,
    assignedOnly: false,
    type: 'ACCOUNT',
  }
  return axiosInstance
    .get<AllAccount>(`/v1/accounts`, {
      params: qParams,
    })
    .then(({ data }) => data)
}

export const fetchAllSubAccountsByUserId = (
  customer: UserParams,
  { fields: _, ...params }: PageParams<unknown>
) => {
  const qParams = {
    ...params,
    customerId: customer.id,
    assignedOnly: false,
    type: 'SUBACCOUNT',
  }
  return axiosInstance
    .get<AllSubAccount>(`/v1/accounts`, {
      params: qParams,
    })
    .then(({ data }) => data)
}

export const useAttachAccountToUser = (
  customer: UserParams,
  setError: (val: string) => void
) => {
  const queryClient = useQueryClient()
  const alert = useContext(NotificationContext)
  const changeProfileMutation = useMutation(
    (accountId: string) => {
      return axiosInstance.post(`/v1/accounts/${accountId}/customers`, {
        email: customer.contactEmail,
      })
    },
    {
      onSuccess: () => {
        alert.success('Account successfully attach')

        queryClient.invalidateQueries([`manage_users_${customer.id}_accounts`])
        queryClient.invalidateQueries([
          `manage_users_${customer.id}_subaccounts`,
        ])
      },
      onError: (e: APIErrorRes) => {
        let { message } = e.response.data

        if (
          message.indexOf('Add user to group was not successful: 409') === 0
        ) {
          message =
            'Add user to group was not successful. This customer is already added to the account and cannot be duplicated.'
        }
        setError(message)
      },
    }
  )

  return changeProfileMutation
}

export const useDetachAccountFromUser = (customerId: string) => {
  const queryClient = useQueryClient()
  const alert = useContext(NotificationContext)
  const mutation = useMutation(
    (accountId: string) => {
      return axiosInstance.delete(
        `/v1/accounts/${accountId}/customers/${customerId}`
      )
    },
    {
      onSuccess: () => {
        alert.success('Account successfully detached')

        queryClient.invalidateQueries([`manage_users_${customerId}_accounts`])
        queryClient.invalidateQueries([
          `manage_users_${customerId}_subaccounts`,
        ])
      },
      onError: (e: APIErrorRes) => {
        alert.error(
          e.response.data.message || 'Detached account was not completed'
        )
      },
    }
  )

  return mutation
}

export const useAttachSubAccountToUser = (
  customer: UserParams,
  setError: (val: string) => void
) => {
  const queryClient = useQueryClient()
  const alert = useContext(NotificationContext)
  const changeProfileMutation = useMutation(
    (accountId: string) => {
      return axiosInstance.post(`/v1/accounts/${accountId}/customers`, {
        email: customer.contactEmail,
      })
    },
    {
      onSuccess: () => {
        alert.success('Sub-Account successfully attach')

        queryClient.invalidateQueries([
          `manage_users_${customer.id}_subaccounts`,
        ])
      },
      onError: (e: APIErrorRes) => {
        let { message } = e.response.data

        if (
          message.indexOf('Add user to group was not successful: 409') === 0
        ) {
          message =
            'Add user to subgroup was not successful. This customer is already added to the subaccount and cannot be duplicated.'
        }
        setError(message)
      },
    }
  )

  return changeProfileMutation
}

export const useDetachSubAccountFromUser = (customerId: string) => {
  const queryClient = useQueryClient()
  const alert = useContext(NotificationContext)
  const mutation = useMutation(
    (accountId: string) => {
      return axiosInstance.delete(
        `/v1/accounts/${accountId}/customers/${customerId}`
      )
    },
    {
      onSuccess: () => {
        alert.success('Sub-Account successfully detached')

        queryClient.invalidateQueries([
          `manage_users_${customerId}_subaccounts`,
        ])
      },
      onError: (e: APIErrorRes) => {
        alert.error(
          e.response.data.message || 'Detached sub-account was not completed'
        )
      },
    }
  )

  return mutation
}

export const useAllSalesmen = (
  searchParams: SearchParams,
  options: Options
) => {
  return useQuery(
    ['admin/salesmen', searchParams],
    () => {
      const params = new URLSearchParams({
        ...searchParams,
        page: searchParams.page.toString(),
        pageSize: searchParams.pageSize.toString(),
      })
      return axiosInstance
        .get<SalesPersons>('/v1/salespersons?' + params)
        .then(({ data }) => {
          return {
            ...data,
            items: data.items.map((salesmanItem) => ({
              ...salesmanItem,
              id: salesmanItem.number,
            })),
          }
        })
    },
    options
  )
}

export const fetchAllSalesmenByUserId = (
  user: UserParams,
  { fields: _, ...params }: PageParams<unknown>
) => {
  return axiosInstance
    .get<SalesPersons>(`/v1/admin/customers/${user.id}/salespersons`, {
      params,
    })
    .then(({ data }) => ({
      ...data,
      items: data.items.map((salesmanItem: SalesPersonsItem) => ({
        ...salesmanItem,
        id: salesmanItem.number,
      })),
    }))
}

export const useAttachSalesmanToUser = (
  customerId: string,
  setError: (val: string) => void
) => {
  const queryClient = useQueryClient()
  const alert = useContext(NotificationContext)
  const changeProfileMutation = useMutation(
    (salesmanId: string) => {
      return axiosInstance.post(
        `/v1/admin/customers/${customerId}/salespersons/${salesmanId}`
      )
    },
    {
      onSuccess: () => {
        alert.success('Salesman successfully attach')
        queryClient.invalidateQueries([
          `manage_users_${customerId}_mapped_salesmen`,
        ])
      },
      onError: (e: APIErrorRes) => {
        const { message } = e.response.data

        setError(message)
      },
    }
  )

  return changeProfileMutation
}

export const useDetachSalesmanFromUser = (customerId: string) => {
  const queryClient = useQueryClient()
  const alert = useContext(NotificationContext)
  const mutation = useMutation(
    (salesmanId: string) => {
      return axiosInstance.delete(
        `/v1/admin/customers/${customerId}/salespersons/${salesmanId}`
      )
    },
    {
      onSuccess: () => {
        alert.success('Salesman successfully detached')

        queryClient.invalidateQueries([
          `manage_users_${customerId}_mapped_salesmen`,
        ])
      },
      onError: (e: APIErrorRes) => {
        alert.error(
          e.response.data.message || 'Detached salesman was not completed'
        )
      },
    }
  )

  return mutation
}

export const fetchUserClaimsByRole = (role: string) => {
  return axiosInstance
    .get<Claims[]>(`/v1/claims`, { params: { role } })
    .then(({ data }) => {
      return data
    })
}

export const fetchUserCustomClaims = (role: string) => {
  return axiosInstance
    .get<Claims[]>(`/v1/claims/custom`, {
      params: {
        role,
      },
    })
    .then(({ data }) => {
      return data
    })
}
