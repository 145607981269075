import { Outlet } from 'react-router-dom'

import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'

import UserTab from '../components/Manage/Users/UserTab'

const UserProfile = () => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      {!isTablet && <UserTab />}

      <Grid container role="tabpanel" sx={{ overflow: 'hidden' }}>
        <Grid item xs={true} p={{ xs: 2, md: 3 }}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  )
}

export default UserProfile
