import { Typography } from '@mui/material'

import AssetSpecificInformation from '~/components/UI/DetailsPage/components/AssetSpecificInformation'
import { ReturnRequestApproval } from '~/types/Approval.model'

import { returnSpecificContent } from './returnData'

type Props = {
  pageHeading: string
  returnData: ReturnRequestApproval
}

const CreatedReturnInfo: React.FC<Props> = ({ pageHeading, returnData }) => {
  return (
    <>
      <Typography variant="h1" sx={{ marginBottom: 3 }}>
        {pageHeading}
      </Typography>
      <AssetSpecificInformation
        assetSpecificContent={returnSpecificContent}
        data={returnData}
      />
    </>
  )
}

export default CreatedReturnInfo
