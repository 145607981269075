import { FC, Fragment, ReactElement } from 'react'

import { Divider, Grid, SxProps, Typography } from '@mui/material'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue, acrColorTeal } = variables

type HeaderType = {
  label: string
  value: number | string
}
interface Props {
  heading: string
  headerPara?: string
  data?: HeaderType[]
  titleSx?: SxProps
}

const ModalHeader: FC<Props> = ({
  data,
  heading,
  headerPara,
  titleSx,
}): ReactElement => {
  return (
    <>
      <Grid container mt={1} mb={2}>
        <Grid item xs={12}>
          <Typography variant="h3" pr={2} sx={titleSx}>
            {heading}
          </Typography>
        </Grid>
        {headerPara && (
          <Grid item xs={12} mt={2}>
            <Typography color={acrColorTeal}>{headerPara}</Typography>
          </Grid>
        )}
        {data?.length > 0 && (
          <Grid item xs={12} mt={2}>
            <Grid container>
              {data.map(({ label, value }: HeaderType, index: number) => (
                <Fragment key={index}>
                  {!!value && (
                    <Grid item mr={5} sx={{ color: acrColorBlue }}>
                      <Typography>{label}</Typography>
                      <Typography fontWeight="bold">{value}</Typography>
                    </Grid>
                  )}
                </Fragment>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Divider />
    </>
  )
}

export default ModalHeader
