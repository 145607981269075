import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import HelpIcon from '@mui/icons-material/Help'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material'

import CustomTooltip from '~/components/common/customTooltip/CustomTooltip'
import PasswordTooltip from '~/components/common/passwordTooltip/PasswordTooltip'
import PreLoginLayout from '~/components/common/preLoginLayout/PreLoginLayout'
import { useChangePasswordByTokenMutation } from '~/services/users'
import { theme } from '~/theme/AppTheme'
import { TEXTS } from '~/utils/allConstants/Constants'
import {
  confirmPasswordValidation,
  passwordValidation,
} from '~/utils/helpers/helperFunctions'

import { BootstrapInput } from '../Form/StyledFormControl'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorLime } = variables
const {
  EMAIL,
  CONFIRM_NEW_PASSWORD,
  NEW_PASSWORD,
  UPDATE_PASSWORD,
  NO_PASSWORD_MESSAGE,
} = TEXTS

type ForgotPasswordConfirmFormProps = {
  email: string
  token: string
  complete: (val: boolean) => void
}

const schema = yup
  .object({
    password: yup
      .string()
      .default('')
      .required(NO_PASSWORD_MESSAGE)
      .test('validation', 'error', passwordValidation),
    confirm_password: confirmPasswordValidation,
  })
  .required()

const ForgotPasswordConfirmForm = ({
  email,
  token,
  complete,
}: ForgotPasswordConfirmFormProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const { handleSubmit, control } = useForm<{
    password: string
    confirm_password: string
  }>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
    mode: 'onChange',
  })
  const changePassword = useChangePasswordByTokenMutation(token)
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const onSubmit = (data: { password: string; confirm_password: string }) => {
    changePassword.mutate(data, {
      onSuccess: () => {
        complete(true)
      },
    })
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const leftPart = (
    <>
      <Typography variant="h1" my={2}>
        Forgot Password?
      </Typography>
      <Typography className="formPageText">
        This portion of our site is exclusively for our current customers and
        sales force.
        <br />
        <br />
        If you are a current customer or sales rep and need to set up an
        account, click&nbsp;
        <Link component={RouterLink} to="/register">
          here
        </Link>
        .
        <br />
        <br />
        If you are not a current customer and would like more information on how
        to buy our product, click&nbsp;
        <Link href="https://weareacr.com/contact" target="_blank">
          here
        </Link>
        .
        <br />
        <br />
        To review our Terms of Use policy, click&nbsp;
        <Link
          href="https://amercareroyal.com/pages/terms-and-conditions"
          target="_blank"
        >
          here
        </Link>
        .
      </Typography>
    </>
  )

  const rightPart = (
    <>
      <Typography variant="h3" mb={3}>
        Please enter new password below.
      </Typography>
      <Grid container alignItems="center" mb={2}>
        <Grid item xs={12} md={4} pb={isTablet ? 1 : 0} pr={isTablet ? 0 : 1}>
          {EMAIL}
        </Grid>
        <Grid item xs={12} md={8}>
          <BootstrapInput
            defaultValue={email}
            fullWidth={true}
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" mb={2}>
        <Grid item xs={12} md={4} pb={isTablet ? 1 : 0} pr={isTablet ? 0 : 1}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <span>{NEW_PASSWORD}</span>
            <CustomTooltip title={<PasswordTooltip />}>
              <HelpIcon sx={{ color: acrColorLime, fontSize: 20 }} />
            </CustomTooltip>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <BootstrapInput
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!error}
                  fullWidth={true}
                  placeholder={NEW_PASSWORD}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!error && (
                  <FormHelperText error={!!error}>
                    {!!error ? error.message : ''}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" mb={2}>
        <Grid item xs={12} md={4} pb={isTablet ? 1 : 0} pr={isTablet ? 0 : 1}>
          {CONFIRM_NEW_PASSWORD}
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller
            name="confirm_password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <BootstrapInput
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!error}
                  fullWidth={true}
                  placeholder={CONFIRM_NEW_PASSWORD}
                  type={showConfirmPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!error && (
                  <FormHelperText error={!!error}>
                    {!!error ? error.message : ''}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ textAlign: 'right', mt: 3 }}>
        <LoadingButton
          className="acr-button bg-teal "
          size="large"
          fullWidth={isTablet}
          onClick={handleSubmit(onSubmit)}
          loading={changePassword.isLoading}
        >
          {UPDATE_PASSWORD}
        </LoadingButton>
      </Box>
    </>
  )

  return <PreLoginLayout leftChildren={leftPart} rightChildren={rightPart} />
}

export default ForgotPasswordConfirmForm
