import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import ResetPasswordComplete from '~/components/ResetPassword/ResetPasswordComplete'
import ResetPasswordForm from '~/components/ResetPassword/ResetPasswordForm'

const ResetPassword = () => {
  const navigate = useNavigate()
  const [completeForm, setCompleteForm] = useState<boolean>(false)

  const [search] = useSearchParams()
  const searchAsObject = Object.fromEntries(new URLSearchParams(search))

  useEffect(() => {
    if (!searchAsObject.token) {
      navigate('/login')
    }
  }, [searchAsObject])

  if (completeForm) {
    return <ResetPasswordComplete />
  }

  return (
    <ResetPasswordForm
      username={searchAsObject.username}
      token={searchAsObject.token}
      complete={setCompleteForm}
    />
  )
}

export default ResetPassword
