import { FC } from 'react'
import { Controller } from 'react-hook-form'

import { FormControl, InputLabel, TextFieldProps } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { StyledFormFieldProps } from '~/types/StyledFormFieldProps.model'
import DatePickerOpenIcon from '~/UI/icons/CalendarIcon'

import { FilterStyledInput } from './FilterTextField'

export const FilterDatePicker: FC<StyledFormFieldProps> = ({
  name,
  label,
  control,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <FormControl variant="standard" fullWidth={true} margin="dense">
            <InputLabel
              shrink
              htmlFor={rest.id}
              error={!!error}
              sx={(theme) => ({
                fontSize: 16,
                transform: 'none',
                color: theme.palette.text.primary,
              })}
            >
              {label}
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="MM/dd/yyyy"
                value={field.value || null}
                onChange={field.onChange}
                components={{
                  OpenPickerIcon: DatePickerOpenIcon,
                }}
                renderInput={(params: TextFieldProps) => (
                  <FilterStyledInput
                    endAdornment={params.InputProps?.endAdornment}
                    {...params}
                    {...rest}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </>
      )}
    />
  )
}
