import { Typography } from '@mui/material'

import PreLoginLayout from '~/components/common/preLoginLayout/PreLoginLayout'

const AccountNotApproved = () => {
  const leftPart = (
    <Typography variant="h3">Account Not Yet Approved</Typography>
  )
  const rightPart = (
    <Typography variant="h5">
      We have successfully received your account registration, but have not yet
      had a chance to approve your account.
      <br />
      You will receive an email when your account has been approved. We
      appreciate your patience.
    </Typography>
  )
  return <PreLoginLayout leftChildren={leftPart} rightChildren={rightPart} />
}

export default AccountNotApproved
