import { Dispatch, SetStateAction, useState } from 'react'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { Link, Typography } from '@mui/material'

import PreLoginLayout from '~/components/common/preLoginLayout/PreLoginLayout'
import { useRegisterMutation } from '~/services/auth'
import { RegisterUserForm } from '~/types/UserForm.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import {
  companyNameValidation,
  companyPhoneValidation,
  confirmPasswordValidation,
  emailValidation,
  mobilePhoneRequiredValidation,
  passwordYupValidation,
} from '~/utils/helpers/helperFunctions'

import UserRegistrationForm from './components/UserRegistrationForm'

const { REGISTER, NEW_CARRIER_REGISTER } = TEXTS

const schema = yup
  .object({
    firstName: yup.string().default(''),
    lastName: yup.string().default(''),
    company: companyNameValidation,
    companyContactPhone: companyPhoneValidation,
    contactEmail: emailValidation,
    contactPhone: mobilePhoneRequiredValidation,
    password: passwordYupValidation,
    confirm_password: confirmPasswordValidation,
    responsibilityArea: yup.string().default(''),
    responsibilityAreaOther: yup.string().default(''),
    userType: yup.string().default(''),
    userTypeOther: yup.string().default(''),
  })
  .required()

const NewCarrierRegisterForm = ({
  complete,
}: {
  complete: Dispatch<SetStateAction<boolean>>
}) => {
  const registerMutation = useRegisterMutation()

  // FIXME: need to be improvement
  const [responseError, setResponseError] = useState('')

  const { handleSubmit, control, watch } = useForm<RegisterUserForm>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
    mode: 'onChange',
  })

  const onSubmit = (data: RegisterUserForm) => {
    setResponseError('')
    data.responsibilityArea = 'Carrier'
    data.userType = 'Vendor'
    data.contactEmail = data.contactEmail.trim()
    registerMutation.mutate(data, {
      onSuccess: () => {
        ReactGA.event({
          category: 'User',
          action: 'Registration Success',
        })

        complete(true)
      },
      onError: (error: any) => {
        // FIXME: need to be improvement
        let message = error.data.message
        if (error.data.status === 'CONFLICT') {
          message = (
            <>
              Email &#39;{data.contactEmail}&#39; is already in use. Please log
              in or request a&nbsp;
              <Link component={RouterLink} to="/forgot-password">
                password reset
              </Link>
              .
            </>
          )
        }

        setResponseError(message)
      },
    })
  }

  const leftPart = (
    <>
      <Typography variant="h1" my={2}>
        {NEW_CARRIER_REGISTER}
      </Typography>
      <Typography className="formPageText">
        The ACR portal is for existing ACR Customers or partners only. If you
        are an existing ACR partner, please complete this form to register for a
        user ID.
        <br />
        <br />
        Only existing partners will be approved for access.
      </Typography>
    </>
  )

  const rightPart = (
    <UserRegistrationForm
      control={control}
      watch={watch}
      registerMutation={registerMutation}
      responseError={responseError}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      submitButton={REGISTER}
      type="newCarrier"
    />
  )

  return <PreLoginLayout leftChildren={leftPart} rightChildren={rightPart} />
}

export default NewCarrierRegisterForm
