import { useQuery } from '@tanstack/react-query'

import { fetchClassNames, fetchDivisionNames } from '~/services/priceList'

type Props = {
  selectedSubAccountId: string
  currentTab: string
  selectedDivision: string
}

const useDivisionClassNames = ({
  selectedSubAccountId,
  currentTab,
  selectedDivision,
}: Props) => {
  const { data: divisionNames } = useQuery(
    ['division_names', currentTab],
    () => {
      return fetchDivisionNames({
        accountId: selectedSubAccountId,
        priceType: currentTab.toUpperCase(),
      })
    },
    {
      enabled: !!selectedSubAccountId && !!currentTab,
      placeholderData: [],
    }
  )

  const { data: classNames } = useQuery(
    ['class_names', currentTab, selectedDivision],
    () => {
      return fetchClassNames({
        accountId: selectedSubAccountId,
        priceType: currentTab.toUpperCase(),
        divisionName: selectedDivision,
      })
    },
    {
      enabled: !!selectedSubAccountId && !!currentTab,
      placeholderData: [],
    }
  )

  return { divisionNames, classNames }
}

export default useDivisionClassNames
