import { Link, Typography } from '@mui/material'

import PreLoginLayout from '~/components/common/preLoginLayout/PreLoginLayout'

const RegisterConfirmInvalid = () => {
  const leftPart = (
    <Typography variant="h3" my={2} textAlign="center">
      Alert!
    </Typography>
  )
  const rightPart = (
    <Typography variant="h5" py={3}>
      Confirmation link is invalid please create a&nbsp;
      <Link
        href="https://helpdesk.amercareroyal.com/a/tickets/new"
        target="_blank"
      >
        helpdesk ticket
      </Link>
    </Typography>
  )
  return <PreLoginLayout leftChildren={leftPart} rightChildren={rightPart} />
}

export default RegisterConfirmInvalid
