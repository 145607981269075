import { useContext, useState } from 'react'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material'

import PasswordTooltip from '~/components/common/passwordTooltip/PasswordTooltip'
import PreLoginLayout from '~/components/common/preLoginLayout/PreLoginLayout'
import { useSetPasswordByTokenMutation } from '~/services/users'
import { CreatePasswordForm } from '~/types/UserForm.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import {
  confirmPasswordValidation,
  passwordValidation,
} from '~/utils/helpers/helperFunctions'

import ControllerStyledFormControl from '../Form/StyledFormControl'
import { NotificationContext } from '../Notification'

const { CREATE_PASSWORD, NO_PASSWORD_MESSAGE, SOMETHING_WENT_WRONG } = TEXTS

const schema = yup
  .object({
    password: yup
      .string()
      .default('')
      .required(NO_PASSWORD_MESSAGE)
      .test('validation', 'error', passwordValidation),

    confirm_password: confirmPasswordValidation,
  })
  .required()

const CreatePassword = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const alert = useContext(NotificationContext)
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const searchAsObject = Object.fromEntries(new URLSearchParams(search))

  const setPasswordMutation = useSetPasswordByTokenMutation(
    searchAsObject.token
  )
  // FIXME: need to be improvement
  const { handleSubmit, control } = useForm<CreatePasswordForm>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
    mode: 'onChange',
  })

  const onSubmit = (data: any) => {
    setPasswordMutation.mutate(data, {
      onSuccess: () => {
        ReactGA.event({
          category: 'User',
          action: 'Registration Success',
        })
        navigate('/login')
      },
      onError: (error: any) => {
        alert.error(error.response.data.message || SOMETHING_WENT_WRONG)
      },
    })
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const leftPart = (
    <>
      <Typography variant="h3" my={2}>
        Thank you for confirming email, please create your password.
      </Typography>
      <Typography className="formPageText">
        Please create your password and finalize your registration. Once you
        have created your password, your account will be set to an ACR
        representative to complete the process.
      </Typography>
    </>
  )
  const rightPart = (
    <>
      <Typography variant="h3" mb={2}>
        Please note this is the password you will use to login to the 1ACR
        Portal.
      </Typography>
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12} md={6}>
          <ControllerStyledFormControl
            name="password"
            control={control}
            id="password-textield"
            label="Password"
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            tooltip={<PasswordTooltip />}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControllerStyledFormControl
            name="confirm_password"
            control={control}
            id="confirm-password-textield"
            label="Confirm Password"
            placeholder="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
      <Box sx={{ textAlign: 'right', mt: 3 }}>
        <LoadingButton
          className="acr-button bg-teal"
          fullWidth={true}
          size="large"
          onClick={handleSubmit(onSubmit)}
        >
          {CREATE_PASSWORD}
        </LoadingButton>
      </Box>
    </>
  )

  return <PreLoginLayout leftChildren={leftPart} rightChildren={rightPart} />
}

export default CreatePassword
