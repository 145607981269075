import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { BootstrapInput } from '~/components/Form/StyledFormControl'
import ControlledStyledTextField, {
  NoGridStyledTextField,
} from '~/components/Form/StyledTextField'
import {
  Roles,
  RoleTypes,
  useChangeUser,
  UserStatus,
  UserTypes,
} from '~/services/users'
import { theme } from '~/theme/AppTheme'
import { UserParams } from '~/types/User.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import {
  companyNameValidation,
  companyPhoneValidation,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  mobilePhoneValidation,
  responsibilityAreaValidation,
  textFieldValidation,
  userTypeValidation,
} from '~/utils/helpers/helperFunctions'

import UserClaims from './UserClaims'

const {
  SAVE,
  DISCARD,
  STATUS,
  FIRST_NAME,
  LAST_NAME,
  EMAIL_ADDRESS,
  MOBILE_NUMBER,
  COMPANY_NAME,
  COMPANY_PHONE_NUMBER,
  SECURITY_ROLE,
  COMMENTS,
  AREA_OF_RESPONSIBILITY,
  USER_TYPE,
} = TEXTS

const schema = yup
  .object({
    contactEmail: emailValidation,
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    contactPhone: mobilePhoneValidation,
    company: companyNameValidation,
    companyContactPhone: companyPhoneValidation,
    responsibilityArea: responsibilityAreaValidation,
    responsibilityAreaOther: textFieldValidation,
    userType: userTypeValidation,
    userTypeOther: textFieldValidation,
    securityRole: yup
      .mixed<Roles>()
      .default(Roles.ROLE_NEW_USER)
      .oneOf(Object.keys(Roles) as Array<Roles>)
      .required()
      .when('userStatus', (userStatus) => {
        if (userStatus === 'ACTIVE') {
          return yup
            .mixed<Roles | string>()
            .notOneOf(
              [RoleTypes.NEW_USER],
              'Role must be chosen before saving active user'
            )
            .required()
        }

        return yup
          .mixed<Roles>()
          .default(Roles.ROLE_NEW_USER)
          .oneOf(Object.keys(Roles) as Array<Roles>)
          .required()
      }),
    jobTitle: yup
      .string()
      .default('')
      .when('securityRole', (securityRole) => {
        if (securityRole === RoleTypes.EMPLOYEE) {
          return yup
            .mixed()
            .default('')
            .required('Corporate Role is a required field.')
        }
      }),
    userStatus: yup
      .mixed<UserStatus | string>()
      .default(UserStatus.PENDING_APPROVAL)
      .oneOf(Object.keys(UserStatus))
      .required(),
    userTypeComment: yup.string().default(''),
    grantedClaims: yup.array().of(yup.object()).default([]),
    claims: yup.array().of(yup.string()).default([]),
  })
  .required()

type Props = {
  user: UserParams
  areaOfResponsibilities: [string]
  acrJobTitles: [string]
}

const UserEditForm = ({
  user,
  areaOfResponsibilities,
  acrJobTitles,
}: Props) => {
  const changeUserMutation = useChangeUser(user.id)

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(user),
  })

  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const watchAreaOfResponsibility = watch('responsibilityArea')
  const watchUserType = watch('userType')
  const watchRole = watch('securityRole')
  const watchCorporateRole = watch('jobTitle')
  const watchUserStatus = watch('userStatus')

  useEffect(() => {
    reset(schema.cast(user))
  }, [user])

  const onSubmit = (data: UserParams) => {
    if (
      data.responsibilityArea === 'Other' &&
      data.responsibilityAreaOther !== ''
    ) {
      data.responsibilityArea = data.responsibilityAreaOther
    }
    if (data.userType === 'Other' && data.userTypeOther !== '') {
      data.userType = data.userTypeOther
    }
    if (data.securityRole !== RoleTypes.EMPLOYEE) {
      data.jobTitle = ''
    }

    if (data.jobTitle !== 'Sales Support') {
      data.userTypeComment = ''
    }

    changeUserMutation.mutate(data)
  }

  const onError = (errors: any, e: any) => console.log(errors, e)

  const onDiscard = () => {
    reset()
  }

  const showAreaOfResponsibilityOtherInpt =
    watchAreaOfResponsibility === 'Other'
  const showUserTypeInpt = watchUserType === 'Other'

  return (
    <>
      {user.userStatus === 'PENDING_APPROVAL' && (
        <Alert severity="warning" sx={{ mt: 3 }}>
          This user is pending approval. Note: Security Role must be chosen
          before account can become active.
        </Alert>
      )}

      {watchUserStatus === 'DISABLED' && (
        <Grid container mb={2} alignItems="center">
          <Alert severity="error" sx={{ width: '100%' }}>
            This user is disabled from using the ACR Customer Portal.
          </Alert>
        </Grid>
      )}

      <Grid container mt={3} pb={3} spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Typography variant="h4">User Account Information</Typography>
          <Typography>Update Account Information</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <ControlledStyledTextField
            name="firstName"
            control={control}
            label={FIRST_NAME}
          />
          <ControlledStyledTextField
            name="lastName"
            control={control}
            label={LAST_NAME}
          />
          <ControlledStyledTextField
            name="contactEmail"
            control={control}
            label={EMAIL_ADDRESS}
          />
          <ControlledStyledTextField
            name="contactPhone"
            control={control}
            label={MOBILE_NUMBER}
          />
          <ControlledStyledTextField
            name="company"
            control={control}
            label={COMPANY_NAME}
          />
          <ControlledStyledTextField
            name="companyContactPhone"
            control={control}
            label={COMPANY_PHONE_NUMBER}
          />
          <Grid container mb={2} alignItems="center">
            <Grid item xs={12} md={4} pb={1}>
              {AREA_OF_RESPONSIBILITY}
            </Grid>
            <Grid item xs={12} md={showAreaOfResponsibilityOtherInpt ? 4 : 8}>
              {areaOfResponsibilities && (
                <Controller
                  name="responsibilityArea"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Select
                      error={!!error}
                      input={<BootstrapInput />}
                      displayEmpty
                      fullWidth={true}
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    >
                      {areaOfResponsibilities.map(
                        (item: string, index: number) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        )
                      )}
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  )}
                />
              )}
            </Grid>
            {showAreaOfResponsibilityOtherInpt && (
              <Grid
                item
                xs={12}
                md={4}
                pl={{ md: 1 }}
                sx={{ mt: isTablet ? 1 : 0 }}
              >
                <NoGridStyledTextField
                  name="responsibilityAreaOther"
                  control={control}
                  id="responsibilityArea-textfield"
                  placeholder="Please specify"
                />
              </Grid>
            )}
          </Grid>
          <Grid container mb={2} alignItems="center">
            <Grid item xs={12} md={4} pb={1}>
              {USER_TYPE}
            </Grid>
            <Grid item xs={12} md={showUserTypeInpt ? 4 : 8}>
              <Controller
                name="userType"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    error={!!error}
                    input={<BootstrapInput />}
                    displayEmpty
                    fullWidth={true}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  >
                    {Object.values(UserTypes).map((key) => (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                )}
              />
            </Grid>
            {showUserTypeInpt && (
              <Grid
                item
                xs={12}
                md={4}
                pl={{ md: 1 }}
                sx={{ mt: isTablet ? 1 : 0 }}
              >
                <NoGridStyledTextField
                  name="userTypeOther"
                  control={control}
                  id="userType-textfield"
                  placeholder="Please specify"
                />
              </Grid>
            )}
          </Grid>
          <Grid container mb={2} alignItems="center">
            <Grid item xs={12} md={4} pb={1}>
              {SECURITY_ROLE}
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller
                name="securityRole"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Select
                      error={!!error}
                      input={<BootstrapInput />}
                      displayEmpty
                      fullWidth={true}
                      name={field.name}
                      value={field.value}
                      onChange={(value) => {
                        if (value.target.value != user.securityRole) {
                          setValue('claims', [])
                        }
                        return field.onChange(value)
                      }}
                      onBlur={field.onBlur}
                    >
                      {Object.keys(Roles).map((key) => (
                        <MenuItem key={key} value={key}>
                          {/* @ts-ignore: Unmatched type error */}
                          {Roles[key]}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!error && (
                      <FormHelperText error={!!error}>
                        {!!error ? error.message : ''}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
          {/* @ts-ignore: Unmatched type error */}
          {watchRole === RoleTypes.EMPLOYEE && (
            <Grid container mb={2} alignItems="center">
              <Grid item xs={12} md={4} pb={1}>
                ACR Corporate Employee Role
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  name="jobTitle"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Select
                        error={!!error}
                        input={<BootstrapInput />}
                        displayEmpty
                        fullWidth={true}
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        renderValue={(val) => {
                          if (val === '') {
                            return (
                              <span className="placeholder">Please Select</span>
                            )
                          }

                          return val
                        }}
                      >
                        {acrJobTitles.map((job: string) => (
                          <MenuItem key={job} value={job}>
                            {job}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!error && (
                        <FormHelperText error={!!error}>
                          {!!error ? error.message : ''}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>
            </Grid>
          )}
          {watchCorporateRole === 'Sales Support' && (
            <ControlledStyledTextField
              name="userTypeComment"
              control={control}
              label={COMMENTS}
            />
          )}
          <Grid container mb={2} alignItems="center">
            <Grid item xs={12} md={4} pb={1}>
              {STATUS}
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller
                name="userStatus"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    error={!!error}
                    input={<BootstrapInput />}
                    displayEmpty
                    fullWidth={true}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  >
                    {Object.keys(UserStatus).map((key) => (
                      <MenuItem key={key} value={key}>
                        {/*@ts-ignore: Unmatched type error*/}
                        {UserStatus[key]}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <UserClaims
          grantedClaimsName="grantedClaims"
          enabledClaimIdsName="claims"
          role={watchRole}
          control={control}
        />
      </Grid>

      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        sx={{ justifyContent: 'flex-end', gap: 2 }}
      >
        <Button
          className="acr-button"
          sx={{ width: !isTablet ? '200px' : '100%' }}
          onClick={onDiscard}
        >
          {DISCARD}
        </Button>

        <LoadingButton
          className="acr-button bg-teal"
          sx={{ width: !isTablet ? '200px' : '100%' }}
          // @ts-ignore: Unmatched type error
          onClick={handleSubmit(onSubmit, onError)}
          loading={changeUserMutation.isLoading}
        >
          {SAVE}
        </LoadingButton>
      </Stack>
    </>
  )
}

export default UserEditForm
