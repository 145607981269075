// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!./_variables.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"acrColorBlack": `#000`,
	"acrColorDarkGrey": `#2b2c2c`,
	"acrColorText": `#4d4d4f`,
	"acrColorGrey": `#87919a`,
	"acrColorGrey2": `#565759`,
	"acrColorMediumGrey": `#818385`,
	"acrColorLightGrey2": `#acaeb2`,
	"acrColorLightGrey": `#b3b5b6`,
	"acrColorRedishGrey": `#d9d9d9`,
	"acrColorGreenishWhite": `#e2f0d9`,
	"acrColorSkyWhite": `#f2f5f7`,
	"acrColorYellowishWhite": `#feefd8`,
	"acrColorBluishWhite": `#e7ebee`,
	"acrColorCyanWhite": `#f7f8f8`,
	"acrColorLight": `#f1f0f3`,
	"acrColorWhite": `#fff`,
	"acrColorTeal": `#007f63`,
	"acrColorTealLight": `rgba(0,127,99,.2)`,
	"acrColorMid": `#005c66`,
	"acrColorOrangishRed": `#d32f2f`,
	"acrColorRed": `#f52f2f`,
	"acrColorLightRed": `#e45656`,
	"acrColorYellow": `#fcc163`,
	"acrColorNavyBlue": `#252a82`,
	"acrColorBlue": `#003a5d`,
	"acrColorBlueLight": `rgba(0,58,93,.1019607843)`,
	"acrColorLime": `#53c072`,
	"acrColorBgLime": `#82b085`,
	"acrColorBorderLime": `#377a3b`,
	"acrColorBorderBlue": `rgba(28,64,86,.1882352941)`,
	"acrColorBorderGrey": `rgba(0,0,0,.1215686275)`,
	"acrColorInputBlue": `#164e63`
};
export default ___CSS_LOADER_EXPORT___;
