import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import App from './app/App'
import CreatePassword from './components/CreateUser/CreatePassword'
import {
  BannerNotificationEditForm,
  BannerNotificationForm,
  BannerNotificationGrid,
} from './components/Manage/BannerNotification/index'
import SiteSettings from './components/Manage/SiteSettings'
import SystemInformation from './components/Manage/SystemInformation'
import UserDetails from './components/Manage/UserDetails'
import UserEdit from './components/Manage/Users/form/UserEdit'
import Users from './components/Manage/Users/Users'
import CreateUserRoute from './routes/createUsers'
import {
  approvalRoutes,
  CheckRoutes,
  Dashboard,
  ForgotPassword,
  invoicesRoutes,
  loginRoutes,
  NotFound,
  ordersRoutes,
  priceListRoutes,
  RegisterConfirmRoute,
  RegisterRoute,
  reportsRoutes,
  ResetPassword,
  returnsRoutes,
  searchResultsRoutes,
  UserProfile,
} from './routes/index'
import NewCarrierRegisterRoute from './routes/new-carrier-register'
import RegisterConfirmInvalidRoute from './routes/register-confirm-invalid'
import { theme } from './theme/AppTheme'
import { RoutesCheck } from './utils/allConstants/Constants'

if (process.env.GA_TRACKING_ID) {
  ReactGA.initialize(process.env.GA_TRACKING_ID)
}

if (window.sessionStorage.getItem('impersonate_tokens') === null) {
  const localStorageTokenData = window.localStorage.getItem('tokens')
  if (localStorageTokenData) {
    window.sessionStorage.setItem('tokens', localStorageTokenData)
  }
}

const element = document.getElementById('root')
const root = ReactDOM.createRoot(element ?? null)

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Navigate to={'/dashboard'} replace />,
      },
      ...loginRoutes,
      {
        path: 'register',
        element: (
          <CheckRoutes type={[RoutesCheck.PUBLIC]}>
            <RegisterRoute />
          </CheckRoutes>
        ),
      },
      {
        path: 'new-carrier-register',
        element: (
          <CheckRoutes type={[RoutesCheck.PUBLIC]}>
            <NewCarrierRegisterRoute />
          </CheckRoutes>
        ),
      },
      {
        path: 'register-confirm',
        element: (
          <CheckRoutes type={[RoutesCheck.PUBLIC]}>
            <RegisterConfirmRoute />
          </CheckRoutes>
        ),
      },
      {
        path: 'set-password',
        element: (
          <CheckRoutes type={[RoutesCheck.PUBLIC]}>
            <CreatePassword />
          </CheckRoutes>
        ),
      },
      {
        path: 'register-confirm-invalid',
        element: (
          <CheckRoutes type={[RoutesCheck.PUBLIC]}>
            <RegisterConfirmInvalidRoute />
          </CheckRoutes>
        ),
      },
      {
        path: 'forgot-password',
        children: [
          {
            index: true,
            element: (
              <CheckRoutes type={[RoutesCheck.PUBLIC]}>
                <ForgotPassword />
              </CheckRoutes>
            ),
          },
          {
            path: ':email',
            element: (
              <CheckRoutes type={[RoutesCheck.PUBLIC]}>
                <ForgotPassword />
              </CheckRoutes>
            ),
          },
        ],
      },
      {
        path: 'reset-password',
        children: [
          {
            index: true,
            element: (
              <CheckRoutes type={[RoutesCheck.PUBLIC]}>
                <ResetPassword />
              </CheckRoutes>
            ),
          },
        ],
      },
      {
        path: 'dashboard',
        element: (
          <CheckRoutes type={[RoutesCheck.PRIVATE]}>
            <Dashboard />
          </CheckRoutes>
        ),
      },
      {
        path: 'manage',
        element: (
          <CheckRoutes type={[RoutesCheck.PRIVATE]}>
            <UserProfile />
          </CheckRoutes>
        ),
        children: [
          {
            index: true,
            element: <Navigate to={'/manage/profile'} />,
          },
          {
            path: 'profile',
            element: <UserDetails />,
          },
          {
            path: 'profile/forgot-password',
            element: <ForgotPassword />,
          },
          {
            path: 'users',
            element: (
              <CheckRoutes type={[RoutesCheck.SUPER_ADMIN]}>
                <Users />
              </CheckRoutes>
            ),
          },
          {
            path: 'users/:customerId',
            element: <UserEdit />,
          },
          {
            path: 'system',
            element: <SystemInformation />,
          },
          {
            path: 'site',
            element: <SiteSettings />,
          },
          {
            path: 'newuser',
            element: (
              <CheckRoutes type={[RoutesCheck.NEW_USER_CREATION]}>
                <CreateUserRoute />
              </CheckRoutes>
            ),
          },
          {
            path: 'banner-notification',
            children: [
              {
                index: true,
                element: <Navigate to={'/manage/banner-notification/active'} />,
              },
              {
                path: ':active_tab',
                element: (
                  <CheckRoutes type={[RoutesCheck.BANNER_NOTIFICATION_EDITOR]}>
                    <BannerNotificationGrid />
                  </CheckRoutes>
                ),
              },
              {
                path: 'create-banner',
                element: (
                  <CheckRoutes type={[RoutesCheck.BANNER_NOTIFICATION_EDITOR]}>
                    <BannerNotificationForm bannerData={undefined} />
                  </CheckRoutes>
                ),
              },
              {
                path: 'create-banner/:alertId',
                element: (
                  <CheckRoutes type={[RoutesCheck.BANNER_NOTIFICATION_EDITOR]}>
                    <BannerNotificationEditForm />
                  </CheckRoutes>
                ),
              },
            ],
          },
        ],
      },

      ...ordersRoutes,

      ...invoicesRoutes,

      ...priceListRoutes,

      ...searchResultsRoutes,

      ...returnsRoutes,

      ...approvalRoutes,

      ...reportsRoutes,
    ],
  },
])

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 1000,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => console.log(error),
  }),
})

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
)
