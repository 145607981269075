import { FC, ReactElement } from 'react'
import { Controller } from 'react-hook-form'

import HelpIcon from '@mui/icons-material/Help'
import {
  FormControl,
  FormHelperText,
  InputBase,
  InputBaseProps,
  InputLabel,
  TextFieldProps,
} from '@mui/material'
import { styled, StyledComponentProps } from '@mui/material/styles'

import { StyledFormFieldProps } from '~/types/StyledFormFieldProps.model'

import CustomTooltip from '../common/customTooltip/CustomTooltip'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorLime, acrColorWhite, acrColorRed, acrColorGrey } = variables

export const BootstrapInput: FC<StyledComponentProps | InputBaseProps> = styled(
  InputBase
)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(4),
  },
  '.placeholder': {
    color: 'rgba(90, 90, 90, 0.6)',
  },
  '&.MuiInputBase-root': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: acrColorWhite,
    border: '1px solid',
    borderColor: acrColorGrey,
    fontSize: 16,
    padding: '7px 9px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&.Mui-focused': {
      borderColor: acrColorLime,
    },
    '&.Mui-error': {
      borderColor: acrColorRed,
    },
    '&::placeholder': {},
  },
}))

export const StyledFormControl = ({
  label,
  error,
  helperText,
  tooltip = null,
  labelSx,
  ...rest
}: TextFieldProps & StyledFormFieldProps): ReactElement => {
  return (
    <FormControl variant="standard" fullWidth={true} margin="dense">
      {label && (
        <InputLabel
          shrink
          htmlFor={rest.id}
          error={!!error}
          sx={(theme) => ({
            fontSize: 16,
            transform: 'none',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            color: theme.palette.text.primary,
            ...labelSx,
          })}
        >
          {label}
          {tooltip !== null && (
            <CustomTooltip title={tooltip}>
              <HelpIcon sx={{ color: acrColorLime, fontSize: 20 }} />
            </CustomTooltip>
          )}
        </InputLabel>
      )}
      <BootstrapInput error={!!error} {...rest} autoComplete="off" />
      {!!error && <FormHelperText error={!!error}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

const ControllerStyledFormControl: FC<StyledFormFieldProps> = ({
  control,
  name,
  labelSx,
  ...rest
}): ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledFormControl
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          error={!!error}
          helperText={!!error ? error.message : ''}
          labelSx={labelSx}
          {...rest}
        />
      )}
    />
  )
}

export default ControllerStyledFormControl
