import { Chip } from '@mui/material'

import { InternalLink } from '~/components/UI/DataGrid/DataGrid'
import { Fields } from '~/types/Fields.model'
import { Order } from '~/types/Order.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import { ORDER_STATUSES_OPEN_ORDERS } from '~/utils/allConstants/HardcodedFilterDropdownValues'
import { formatCurrency, formatDate } from '~/utils/helpers/helperFunctions'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorYellowishWhite, acrColorYellow } = variables
const {
  ORDER_NUMBER,
  ORDER_TOTAL,
  PURCHASE_ORDER,
  ORDER_STATUS,
  REQ_DELIVERY_DATE,
  ORDER_DATE,
  WHO_PLACED_ORDER,
  CARRIER,
  TRACKING_NUMBER,
} = TEXTS

const fields: Fields[] = [
  {
    id: 'orderNumber',
    label: ORDER_NUMBER,
    minWidth: 110,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    format: (value: string) => (
      <InternalLink to={`/orders/${value}/details`}>{value}</InternalLink>
    ),
    sort: true,
  },
  {
    id: 'orderTotal',
    label: ORDER_TOTAL,
    minWidth: 100,
    filterFieldConfiguration: {
      type: 'range-freeText',
      prefix: '$',
    },
    format: (value: number) => formatCurrency(value),
    sort: true,
  },
  {
    id: 'purchaseOrder',
    label: PURCHASE_ORDER,
    minWidth: 120,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    sort: true,
  },
  {
    id: 'orderStatus',
    label: ORDER_STATUS,
    minWidth: 100,
    filterFieldConfiguration: {
      type: 'select',
      values: ORDER_STATUSES_OPEN_ORDERS,
    },
    format: (_, row: Order) =>
      row.orderStatus ? (
        <Chip
          label={
            row.orderStatus === 'Picking' && row.orderType === 'R'
              ? 'Awaiting Product Receipt'
              : row.orderStatus
          }
          sx={{
            backgroundColor: acrColorYellowishWhite,
            border: '1px solid',
            borderColor: acrColorYellow,
          }}
        />
      ) : null,
    sort: false,
  },
  {
    id: 'reqDeliveryDate',
    label: REQ_DELIVERY_DATE,
    minWidth: 140,
    filterFieldConfiguration: {
      type: 'range-date',
    },
    format: (value: string) => formatDate(value),
    sort: true,
  },
  {
    id: 'orderDate',
    label: ORDER_DATE,
    minWidth: 100,
    filterFieldConfiguration: {
      type: 'range-date',
    },
    format: (value: string) => formatDate(value),
    sort: true,
  },
  {
    id: 'orderPlacedBy',
    label: WHO_PLACED_ORDER,
    minWidth: 100,
    sort: true,
  },
  {
    id: 'shipper',
    label: CARRIER,
    minWidth: 100,
    sort: true,
  },
  {
    id: 'trackingNumber',
    label: TRACKING_NUMBER,
    minWidth: 100,
    format: (value: string, row: Order) =>
      row.trackingUrl ? (
        <InternalLink to={row.trackingUrl}>{value}</InternalLink>
      ) : (
        value
      ),
    sort: false,
  },
]

export { fields }
