import { createTheme } from '@mui/material'

import variables from './scss/_variables.module.scss'

const {
  acrColorWhite,
  acrColorBlack,
  acrColorGrey,
  acrColorLightGrey,
  acrColorRed,
  acrColorBlue,
  acrColorText,
} = variables

export const theme = createTheme({
  palette: {
    primary: {
      main: acrColorBlue,
    },
    secondary: {
      main: acrColorGrey,
    },
    text: {
      primary: acrColorBlack,
      disabled: acrColorLightGrey,
      secondary: acrColorGrey,
    },
    background: {
      default: acrColorWhite,
    },
  },
  status: {
    danger: acrColorRed,
  },
  typography: {
    fontFamily: ['Figtree', 'Inter', 'sans-serif'].join(','),
    h1: {
      fontSize: '42px',
      fontWeight: 500,
      lineHeight: '46px',
      color: acrColorBlue,
    },
    h3: {
      fontSize: '30px',
      lineHeight: '34px',
      fontWeight: 500,
      color: acrColorBlue,
    },
    h4: {
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '28px',
      color: acrColorBlue,
    },
    h5: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '27px',
      color: acrColorText,
    },
  },
})
