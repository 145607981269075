import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'

import { capitalize } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { useOrderPod } from '~/services/orderPod'
import {
  createOrderComment,
  fetchAllOrderComments,
  fetchOrderComments,
  fetchOrders,
} from '~/services/orders'
import { Account, SubAccount } from '~/types/Account.model'
import { Order, OrderState } from '~/types/Order.model'
import { TabsType } from '~/types/TabsType.model'
import CustomModal from '~/UI/modals/CustomModal'
import OrderInvoiceActionButton from '~/UI/modals/modalBodies/tableCommentModalBody/actionButton/OrderInvoiceActionButton'
import TableAttachFileModalBody from '~/UI/modals/modalBodies/tableCommentModalBody/TableAttachFileModalBody'
import TableCommentModalBody from '~/UI/modals/modalBodies/tableCommentModalBody/TableCommentModalBody'
import { TEXTS } from '~/utils/allConstants/Constants'
import { downloadFileRes, exportFile } from '~/utils/helpers/helperFunctions'

import { AccountsContext } from '../Accounts'
import useSynchAccountsNavigation from '../Accounts/hook/useSynchAccountsNavigation'
import ContentWrapper from '../UI/ContentWrapper/ContentWrapper'
import useDataGridStore, {
  DataGridProvider,
} from '../UI/DataGrid/DataGridContext'
import TabsTablePage from '../UI/TabsTablePage'

import { fields as openFields } from './components/OpenOrdersTabContent'
import { fields as historyFields } from './components/OrderHistoryTabContent'

const { OPEN_ORDERS, ORDERS_HISTORY } = TEXTS

const Orders = () => {
  const { active_tab } = useParams()
  const { selectedAccount, selectedSubAccount } =
    useContext(AccountsContext) ||
    ({} as {
      selectedAccount: Account
      selectedSubAccount: SubAccount
    })
  const [openCommentModal, setOpenCommentModal] = useState(false)
  const [commentModalData, setCommentModalData] = useState<Order>()
  const [openAttachFileModal, setOpenAttachFileModal] = useState(false)
  const [attachFileModalData, setAttachFileModalData] = useState<Order>()
  const [attachFileRow, setAttachFileRow] = useState<Order>()

  const useOrderPodMutation = useOrderPod()

  const currentTab = active_tab || 'open'

  const {
    setTableProps,
    tableProps,
    sort,
    filters: storeFilters,
    data,
  } = useDataGridStore()

  const { data: comments, isFetching } = useQuery(
    ['orders', 'comments', data?.items],
    async () => {
      const orderNumbers = data?.items.map((itm: any) => itm.orderNumber)
      return await fetchAllOrderComments(orderNumbers)
    },
    {
      enabled: data?.items.length > 0,
      placeholderData: [],
    }
  )

  const toggleCommentModal = useCallback((rowData: Order) => {
    setOpenCommentModal((prevState) => {
      if (!prevState) setCommentModalData(rowData)
      return !prevState
    })
  }, [])

  const toggleAttachFileModal = useCallback((rowData: Order) => {
    setAttachFileRow(rowData)
    useOrderPodMutation.mutate(rowData.orderNumber, {
      onSuccess: (response) => {
        const filename = `Order-POD-${rowData.orderNumber}.pdf`
        return downloadFileRes({ response, filename })
      },
      onError: () => {
        setOpenAttachFileModal((prevState) => {
          if (!prevState) {
            setAttachFileModalData(rowData)
          }
          return !prevState
        })
      },
    })
  }, [])

  useSynchAccountsNavigation(`orders/${currentTab}`)

  const filters = useMemo(() => {
    const f = {} as { customerNumber: string; shipToNumber: string }
    if (selectedAccount) {
      f.customerNumber = selectedAccount.customerNumber

      if (selectedSubAccount) {
        f.shipToNumber = selectedSubAccount.shipToNumber
      }
    }
    return f
  }, [selectedAccount, selectedSubAccount])

  const handleExcelExport = useCallback(() => {
    ReactGA.event({
      category: 'Orders',
      action: 'Export as Excel',
    })
    return exportFile({
      name: 'orders',
      exportfilename:
        currentTab == 'orders-history'
          ? 'Order' + capitalize(currentTab)
          : capitalize(currentTab) + 'Orders',
      extension: 'xlsx',
      fields: tableProps.fields,
      filters: {
        page: 1,
        pageSize: 1000,
        ...tableProps.additionalFilters,
        ...storeFilters,
        sort: sort !== '' ? sort : undefined,
      },
    })
  }, [tableProps, storeFilters, sort])

  const actions = [
    {
      render: (row: Order) => {
        return (
          <OrderInvoiceActionButton
            // @ts-ignore: Unmatched type error
            hasComments={comments?.[row.orderNumber]?.length}
            toggleAttachFileModal={toggleAttachFileModal.bind(this, row)}
            toggleCommentModal={toggleCommentModal.bind(this, row)}
            attachVisible={currentTab === 'orders-history'}
            isFetching={isFetching}
            isOrderPodLoading={useOrderPodMutation.isLoading}
            hasAttachClick={row === attachFileRow}
          />
        )
      },
    },
  ]

  useEffect(() => {
    setTableProps((prevState: TabsType) => {
      return {
        ...prevState,
        actions: actions,
        additionalFilters: {
          state: prevState?.additionalFilters.state,
          ...filters,
        },
      }
    })
  }, [filters, isFetching])

  const defaultProps = {
    source: fetchOrders,
    actions,
    additionalFilters: filters,
    notFoundMessage:
      'No Orders Available for your selection please make a new selection or contact customer support',
  }
  const params: TabsType[] = [
    {
      label: OPEN_ORDERS,
      name: 'open-orders',
      ...defaultProps,
      fields: openFields,
      additionalFilters: {
        ...defaultProps.additionalFilters,
        state: OrderState.OPEN,
      },
    },
    {
      label: ORDERS_HISTORY,
      name: 'orders-history',
      ...defaultProps,
      fields: historyFields,
      additionalFilters: {
        ...defaultProps.additionalFilters,
        state: `${OrderState.CLOSED},${OrderState.CANCELED}`,
      },
    },
  ]

  return (
    <ContentWrapper>
      <TabsTablePage
        key={currentTab}
        currentTab={currentTab}
        tabs={params}
        handleExcelExport={handleExcelExport}
      />
      <CustomModal open={openCommentModal} setOpen={setOpenCommentModal}>
        <TableCommentModalBody
          source={commentModalData}
          name="orders"
          dataActions={{
            fetchSource: fetchOrderComments,
            createSource: createOrderComment,
          }}
        />
      </CustomModal>
      <CustomModal open={openAttachFileModal} setOpen={setOpenAttachFileModal}>
        <TableAttachFileModalBody source={attachFileModalData} />
      </CustomModal>
    </ContentWrapper>
  )
}
// eslint-disable-next-line react/display-name
export default memo(() => (
  <DataGridProvider>
    <Orders />
  </DataGridProvider>
))
