import { useRouteError } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorRed } = variables
const NotFound = () => {
  const error: any = useRouteError()

  return (
    <Box
      id="error-page"
      className="flexVerticalCenter"
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        px: 2,
        py: 3,
        boxSizing: 'border-box',
      }}
    >
      <Typography variant="h3" mb={3}>
        Oops!
      </Typography>
      <Typography variant="h6" mb={3}>
        Sorry, an unexpected error has occurred.
      </Typography>
      <Typography variant="h6" color={acrColorRed}>
        {error.statusText || error.message}
      </Typography>
    </Box>
  )
}

export default NotFound
