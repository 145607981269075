import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Divider, Typography } from '@mui/material'

import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import TabsTablePage from '~/components/UI/TabsTablePage'
import {
  fetchCustomerAlerts,
  useDeleteCustomerAlertMutation,
} from '~/services/bannerNotification'
import { CustomerAlert } from '~/types/CustomerAlert.model'
import { TabsType } from '~/types/TabsType.model'
import PencilIcon from '~/UI/icons/PencilIcon'
import CustomModal from '~/UI/modals/CustomModal'
import BannerPreviewModalBody from '~/UI/modals/modalBodies/BannerPreviewModalBody'
import { TEXTS } from '~/utils/allConstants/Constants'

import { fields as bannerGridFields } from './components/BannerTabContent'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorMediumGrey } = variables
const { PREVIEW, EDIT, ACTIVE, PENDING, EXPIRED, BANNER_NOTIFICATION } = TEXTS

const BannerNotificationGrid = () => {
  const { active_tab } = useParams()
  const currentTab = active_tab || 'active'
  const navigate = useNavigate()
  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [previewModalData, setPreviewModalData] = useState<CustomerAlert>()
  const deleteCustomerAlert = useDeleteCustomerAlertMutation()

  const { setTableProps, refetch } = useDataGridStore()

  useEffect(() => {
    setTableProps((prevState: TabsType) => {
      return {
        ...prevState,
        additionalFilter: prevState?.additionalFilters?.status,
      }
    })
  }, [setTableProps])

  const closeModal = () => {
    setOpenPreviewModal((prev) => {
      return !prev
    })
  }

  const toggleBannerPreviewModal = useCallback((rowData: CustomerAlert) => {
    setOpenPreviewModal((prevState) => {
      if (!prevState) {
        setPreviewModalData(rowData)
      }
      return !prevState
    })
  }, [])

  const handleDeleteNotification = (alertId: string) => {
    deleteCustomerAlert.mutate(alertId, {
      onSuccess: () => {
        closeModal()
        refetch()
      },
    })
  }

  const previewAction = [
    {
      icon: <VisibilityOutlinedIcon sx={{ color: acrColorMediumGrey }} />,
      tooltip: PREVIEW,
      click: (row: CustomerAlert) => {
        toggleBannerPreviewModal(row)
      },
    },
  ]

  const editAction = [
    {
      icon: <VisibilityOutlinedIcon sx={{ color: acrColorMediumGrey }} />,
      tooltip: PREVIEW,
      click: (row: CustomerAlert) => {
        toggleBannerPreviewModal(row)
      },
    },
    {
      icon: <PencilIcon color={acrColorMediumGrey} />,
      tooltip: EDIT,
      click: (row: CustomerAlert) => {
        navigate(`/manage/banner-notification/create-banner/${row.id}`)
      },
    },
  ]

  const defaultProps = {
    source: fetchCustomerAlerts,
    fields: bannerGridFields,
    notFoundMessage: 'No Banner Notification available',
  }

  const params: TabsType[] = [
    {
      label: ACTIVE,
      name: 'active',
      ...defaultProps,
      actions: previewAction,
      additionalFilters: {
        status: 'ACTIVE',
      },
    },
    {
      label: PENDING,
      name: 'pending',
      ...defaultProps,
      actions: editAction,
      additionalFilters: {
        status: 'PENDING',
      },
    },
    {
      label: EXPIRED,
      name: 'expired',
      ...defaultProps,
      additionalFilters: {
        status: 'EXPIRED',
      },
    },
  ]

  return (
    <>
      <Typography variant="h3" pb={2}>
        {BANNER_NOTIFICATION}
      </Typography>

      <Divider sx={{ mb: 3 }} />
      <TabsTablePage
        currentTab={currentTab}
        tabs={params}
        navigateUrl={{ name: 'CREATE BANNER', link: `create-banner` }}
      />
      <CustomModal open={openPreviewModal} setOpen={setOpenPreviewModal}>
        <BannerPreviewModalBody
          bannerFormData={previewModalData}
          onDelete={handleDeleteNotification}
          closeModal={closeModal}
          isLoading={deleteCustomerAlert.isLoading}
        />
      </CustomModal>
    </>
  )
}

// eslint-disable-next-line react/display-name
export default () => (
  <DataGridProvider>
    <BannerNotificationGrid />
  </DataGridProvider>
)
