import { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'

import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Link, Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import BackButton from '~/components/common/backButton/BackButton'
import CreatedReturnInfo from '~/components/common/createdReturnInfo/CreatedReturnInfo'
import DataGrid from '~/components/UI/DataGrid/DataGrid'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import Loading from '~/components/UI/Loading'
import { useAuth } from '~/services/auth'
import { fetchReturnReasons, useIssueReopen } from '~/services/returns'
import { RoleTypes } from '~/services/users'
import { ReturnRequestApproval } from '~/types/Approval.model'
import { Fields } from '~/types/Fields.model'
import CustomModal from '~/UI/modals/CustomModal'
import ReturnDenialDispute from '~/UI/modals/modalBodies/returnModalBody/ReturnDenialDisputeModel'
import { APPROVAL_STATUSES, TEXTS } from '~/utils/allConstants/Constants'
import { downloadFile, exportFile } from '~/utils/helpers/helperFunctions'

import {
  approvalStatusFields,
  commonFields,
  creditFields,
} from './ReturnDetailInfoContent'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorCyanWhite, acrColorText } = variables

const {
  ATTACHMENTS,
  DOWNLOAD_ATTACHMENTS,
  EXPORT_TO_PDF,
  REOPEN,
  DISPUT_DENIALS,
  EXPORT_AS_ZIP,
  EXPORT_RA_AS_PDF,
  RETURN_REQUEST_REVIEWER,
} = TEXTS
const { APPROVED, REJECTED } = APPROVAL_STATUSES

type Options = {
  id: string
  name: string
  queryName: string
  tableProps: { fields: Fields[] }
}

type Props = {
  data: ReturnRequestApproval
  options: Options
}

const ReturnDetailsInfoPage: FC<Props> = ({ data, options }): ReactElement => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloadingRA, setIsDownloadingRA] = useState(false)
  const { data: user } = useAuth()
  const reopenMutation = useIssueReopen()

  const source = useCallback(() => {
    return {
      items: data.returnItemList.map((item: any) => {
        return {
          ...item,
          id: item.itemNo,
        }
      }),
    }
  }, [data])

  const { data: returnReasonCodes, isFetching: isReturnReasonCodesFetching } =
    useQuery(
      ['return-reason-code'],
      () => fetchReturnReasons(data.isCarrierReturn && RoleTypes.CARRIER),
      {
        keepPreviousData: true,
      }
    )

  const { setTableProps, tableProps } = useDataGridStore()
  const [openReturnConfirmModal, setOpenReturnConfirmModal] = useState(false)
  const approvedStatus = data.returnItemList.some((item: any) => {
    if (item.approved == false || item.approved == true) {
      return true
    }
  })

  useEffect(() => {
    let tableFields = [...commonFields]

    if (data.isCreditReturn) {
      tableFields = [...tableFields, ...creditFields]
    }

    if (approvedStatus) {
      tableFields = [...tableFields, ...approvalStatusFields]
    }

    options.tableProps.fields = tableFields

    setTableProps({
      name: `${options.queryName}-${options.id}`,
      source,
      ...options.tableProps,
    })
  }, [source, data, options, setTableProps])

  const closeModal = () => {
    setOpenReturnConfirmModal((prev) => {
      return !prev
    })
  }
  const handleDispute = () => {
    setOpenReturnConfirmModal((prev) => {
      return !prev
    })
  }

  const handleReopen = () => {
    reopenMutation.mutate(data.id, {
      onSuccess: () => {
        navigate('/returns/returns-history')
      },
    })
  }

  const handleAttachment = useCallback(() => {
    setIsLoading(true)
    ReactGA.event({
      category: RETURN_REQUEST_REVIEWER,
      action: EXPORT_AS_ZIP,
    })
    return exportFile({
      name: 'returns',
      id: data.id,
      extension: 'zip',
      exportfilename: ATTACHMENTS,
    }).then(() => setIsLoading(false))
  }, [data.id])

  const handleRaAttachment = useCallback(() => {
    setIsDownloadingRA(true)
    ReactGA.event({
      category: RETURN_REQUEST_REVIEWER,
      action: EXPORT_RA_AS_PDF,
    })

    const url = `/v1/returns/${data.id}/raAsset`

    return downloadFile(url, `RA-${data.raNumber}.pdf`).then(() =>
      setIsDownloadingRA(false)
    )
  }, [data.id])

  const denialBtnStatus =
    data.returnItemList.findIndex((obj: any) => {
      return obj.approved === false
    }) >= 0

  if (isReturnReasonCodesFetching) {
    return <Loading />
  }

  const returnReasonCodeFieldIndex = options.tableProps.fields.findIndex(
    (item) => item.id === 'reason'
  )

  options.tableProps.fields[returnReasonCodeFieldIndex].format = (
    _,
    row: string | any
  ) => {
    return returnReasonCodes[row.reason as keyof typeof returnReasonCodes]
  }

  return (
    <>
      <Box sx={{ px: { xs: 2, md: 3 } }}>
        <Box>
          <Grid container mt={3} sx={{ alignItems: 'center' }}>
            <Grid item xs={12} className="action-buttons-wrapper">
              <BackButton />
              {Object.keys(data.assets).length > 0 && (
                <LoadingButton
                  loading={isLoading}
                  className="action-button acr-button bg-teal"
                  onClick={handleAttachment}
                >
                  {DOWNLOAD_ATTACHMENTS}
                </LoadingButton>
              )}
              {data.raAttachmentAssetId && (
                <LoadingButton
                  loading={isDownloadingRA}
                  className="action-button acr-button bg-teal"
                  onClick={handleRaAttachment}
                >
                  {EXPORT_TO_PDF}
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <CreatedReturnInfo pageHeading={options.name} returnData={data} />
          <Box sx={{ marginBottom: '20px' }}>
            {Object.keys(tableProps).length > 0 && <DataGrid />}
          </Box>
          {data.isCreditReturn && (
            <Box bgcolor={acrColorCyanWhite} sx={{ p: 2, mb: 2 }}>
              <Box
                component="span"
                sx={{ fontWeight: 'bold', mr: 1, color: acrColorText }}
              >
                Link:
              </Box>
              <Link
                sx={{ wordWrap: 'break-word' }}
                href={data.url}
                target="_blank"
              >
                {data.url}
              </Link>
            </Box>
          )}
        </Box>
        <Stack direction="row" justifyContent="center" gap={2} mb={2}>
          {user.role != RoleTypes.CARRIER && (
            <Button
              className="acr-button bg-teal"
              onClick={handleDispute}
              disabled={data.dispute}
              sx={{
                m: 1,
                textTransform: 'initial',
                display:
                  denialBtnStatus &&
                  [APPROVED, REJECTED].includes(data.approvalStatus)
                    ? 'visible'
                    : 'none',
              }}
            >
              {DISPUT_DENIALS}
            </Button>
          )}
          {user.role === RoleTypes.ADMIN && (
            <LoadingButton
              className="acr-button bg-teal"
              onClick={handleReopen}
              sx={{
                m: 1,
                textTransform: 'initial',
                display:
                  data.dispute != null &&
                  [REJECTED].includes(data.approvalStatus) &&
                  user.role === RoleTypes.ADMIN
                    ? 'visible'
                    : 'none',
              }}
              loading={reopenMutation.isLoading}
            >
              {REOPEN}
            </LoadingButton>
          )}
        </Stack>
      </Box>
      <CustomModal
        open={openReturnConfirmModal}
        setOpen={setOpenReturnConfirmModal}
      >
        <ReturnDenialDispute
          closeModal={closeModal}
          id={data.id}
          // onClick={handleNavigateReturn}
        />
      </CustomModal>
    </>
  )
}
// eslint-disable-next-line react/display-name
export default (props: Props) => (
  <DataGridProvider>
    <ReturnDetailsInfoPage {...props} />
  </DataGridProvider>
)
