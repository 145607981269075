import { Controller } from 'react-hook-form'

import { FormControl, InputLabel } from '@mui/material'

import { StyledFormFieldProps } from '~/types/StyledFormFieldProps.model'

import { FilterStyledInput } from './FilterTextField'

const FilterFormControl = ({
  label,
  control,
  name,
  ...rest
}: StyledFormFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant="standard" fullWidth={true} margin="dense">
          <InputLabel
            shrink
            htmlFor={rest.id}
            error={!!error}
            sx={(theme) => ({
              fontSize: 16,
              transform: 'none',
              color: theme.palette.text.primary,
            })}
          >
            {label}
          </InputLabel>
          <FilterStyledInput
            error={!!error}
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            fullWidth={true}
            placeholder={label}
            {...rest}
          />
        </FormControl>
      )}
    />
  )
}

export default FilterFormControl
