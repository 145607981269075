import { Controller } from 'react-hook-form'

import { Select } from '@mui/material'

import { StyledFormFieldProps } from '~/types/StyledFormFieldProps.model'
import { isObject } from '~/utils/helpers/helperFunctions'

import { FilterStyledInput } from './FilterTextField'

const FilterSelectField = ({
  control,
  name,
  children,
  onCustomChange,
  onResetField,
  availableValues,
  ...rest
}: StyledFormFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        let fieldValue = ''
        if (!!field.value) {
          if (
            Array.isArray(availableValues) &&
            availableValues.includes(field.value)
          ) {
            fieldValue = field.value
          } else if (isObject(availableValues)) {
            fieldValue = availableValues[field.value]
          }
        }

        return (
          <Select
            error={!!error}
            name={field.name}
            value={fieldValue}
            onChange={(event) => {
              field.onChange(event)
              onCustomChange?.(event)
              if (field.name === 'divisionName') {
                onResetField?.('className')
              }
            }}
            onBlur={field.onBlur}
            labelId={rest.id + '-label'}
            input={<FilterStyledInput />}
            fullWidth={true}
            displayEmpty
            {...rest}
            renderValue={(val) => {
              if (val === '') {
                return <span className="placeholder">Please Select</span>
              }

              const itm = children.find(
                (itm: { props: { children: string; value: string } }) =>
                  itm.props.value === val
              )
              return itm ? itm.props.children : val
            }}
          >
            {children}
          </Select>
        )
      }}
    />
  )
}

export default FilterSelectField
