import * as React from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { fetchUnreadCustomerAlerts } from '~/services/bannerNotification'
import { fetchUsers, UserTypes } from '~/services/users'
import { theme } from '~/theme/AppTheme'
import { User } from '~/types/User.model'

import { DashboardBannerPreview } from '../Manage/BannerNotification'
import { GlobalLoading } from '../UI/Loading'

import {
  adminDashboardButtons,
  adminDashboardFields,
} from './AllDashboardsContent'
import DashboardCard from './DashboardCard'

import './Dashboard.scss'

const AdminDashboard = () => {
  const pageSize = 100
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const { data: userData } = useQuery(
    ['dashboard_users'],
    async () => {
      let result: User[] = []
      let users
      let page = 1
      do {
        users = await fetchUsers({
          page: page,
          pageSize: pageSize,
          filters: {
            userStatus: 'PENDING_APPROVAL',
          },
        })
        page++
        result = result.concat(users.items)
      } while (users.items.length === pageSize)
      return result
    },
    {
      select: (data) => {
        return {
          customer_cnt: data.filter(
            (itm) => itm.userType === UserTypes.USER_TYPE_CUSTOMER
          ).length,
          employee_cnt: data.filter(
            (itm) => itm.userType === UserTypes.USER_TYPE_ACR_EMPLOYEE
          ).length,
          broker_cnt: data.filter(
            (itm) => itm.userType === UserTypes.USER_TYPE_BROKER
          ).length,
          other_cnt: data.filter(
            (itm) =>
              ![
                UserTypes.USER_TYPE_CUSTOMER,
                UserTypes.USER_TYPE_ACR_EMPLOYEE,
                UserTypes.USER_TYPE_BROKER,
              ].includes(itm.userType)
          ).length,
        }
      },
    }
  )
  const goToLink = () => {
    navigate('/manage/users')
  }

  const additionalCardProps = {
    headerAction: goToLink,
  }

  const dataMapper: any = {
    userPending: userData,
  }
  const { data: customerAlertData, isFetching: isCustomerAlertFetching } =
    useQuery(
      [['unread-customer-alerts', {}]],
      () => {
        return fetchUnreadCustomerAlerts({})
      },
      {
        keepPreviousData: false,
      }
    )

  if (isCustomerAlertFetching) {
    return <GlobalLoading />
  }

  return (
    <>
      <Box sx={{ px: { xs: 2, sm: 2, md: 3 } }}>
        <DashboardBannerPreview customerAlertData={customerAlertData} />
      </Box>
      <Container
        className={cn(isTablet && 'dashboard-container')}
        sx={{ mt: 2, mb: 3 }}
      >
        <Stack direction={isMobile ? 'column' : 'row'} spacing={4}>
          {adminDashboardFields.map((fieldObject: any) => (
            <React.Fragment key={fieldObject.id}>
              <DashboardCard
                data={dataMapper[fieldObject.id]}
                cardProps={{ ...fieldObject, ...additionalCardProps }}
              />
            </React.Fragment>
          ))}
        </Stack>
        <Typography
          className="dashboardHeading"
          sx={{
            mt: 4,
          }}
          gutterBottom
        >
          Quick Links
        </Typography>
        <Stack
          direction={isTablet ? 'column' : 'row'}
          spacing={isTablet ? 2 : 3}
        >
          {adminDashboardButtons.map((button) => (
            <Button
              key={button.name}
              component={Link}
              href={button.href}
              target="_blank"
              className="acr-button bg-blue"
            >
              {button.name}
            </Button>
          ))}
        </Stack>
      </Container>
    </>
  )
}

export default AdminDashboard
