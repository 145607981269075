import { Box, Typography } from '@mui/material'

import KeyboardArrowRightIcon from '~/UI/icons/KeyboardArrowRightIcon'

import './PasswordTooltip.scss'
import variables from '~/theme/scss/_variables.module.scss'

const { acrColorWhite } = variables

const PasswordTooltip = () => {
  return (
    <div className="passwordTooltip">
      <Typography variant="h5" color={acrColorWhite} my={1}>
        Password Requirements:
      </Typography>
      <Box className="flexVerticalCenter requirementOptions">
        <KeyboardArrowRightIcon color={acrColorWhite} /> Min length 14
        characters
      </Box>
      <Box className="flexVerticalCenter requirementOptions">
        <KeyboardArrowRightIcon color={acrColorWhite} /> 1 special character
      </Box>
      <Box className="flexVerticalCenter requirementOptions">
        <KeyboardArrowRightIcon color={acrColorWhite} /> 1 numeric digit
      </Box>
      <Box className="flexVerticalCenter requirementOptions">
        <KeyboardArrowRightIcon color={acrColorWhite} /> 1 lowercase character
      </Box>
      <Box className="flexVerticalCenter requirementOptions">
        <KeyboardArrowRightIcon color={acrColorWhite} /> 1 uppercase character
      </Box>
    </div>
  )
}

export default PasswordTooltip
