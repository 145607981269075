import { FC, ReactElement } from 'react'

import { SvgIcon } from '@mui/material'

interface Props {
  color: string
}

const KeyboardArrowRightIcon: FC<Props> = ({ color }): ReactElement => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6z"
          fill={color}
        ></path>
      </svg>
    </SvgIcon>
  )
}

export default KeyboardArrowRightIcon
