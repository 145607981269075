import {
  FC,
  Fragment,
  MouseEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import cn from 'classnames'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import {
  AppBar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Link,
  ListItemButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import Logo from '~/images/acr-logo.png'
import { useAuth, useLogoutMutation } from '~/services/auth'
import { RoleTypes } from '~/services/users'
import { Account, SubAccount } from '~/types/Account.model'
import { CurrentUser } from '~/types/User.model'
import {
  GrantedClaims,
  HEADER_HEIGHT,
  JobTitles,
  MENUS,
  PAGE_TITLES,
  TEXTS,
} from '~/utils/allConstants/Constants'
import { convertPageToLink, hasChildren } from '~/utils/helpers/helperFunctions'

import { AccountsContext } from '../Accounts'

import DrawerComp from './Drawer/DrawerComp'
import { Item, Page, Pages } from './MenuData'
import SearchBox from './SearchBox'

import './Header.scss'
import variables from '~/theme/scss/_variables.module.scss'

const {
  acrColorGrey,
  acrColorRedishGrey,
  acrColorWhite,
  acrColorLime,
  acrColorTeal,
  acrColorBlue,
  acrColorBorderBlue,
} = variables

const { APPROVALS, INVOICES, ORDERS, PRICE_LIST, REPORTS, RETURNS } =
  PAGE_TITLES
const { PRICE_CHANGE_HISTORY } = MENUS
const { LOGIN, NEW_USER, CARRIER_REGISTRATION } = TEXTS

type ListItem = {
  item: Page
  icon: any
}

export interface IUserNameProps {
  user: CurrentUser
}

const UserName: FC<IUserNameProps> = ({ user }): ReactElement | any => {
  if (user.firstName === '' && user.lastName === '') {
    return user.contactEmail
  }
  if ([RoleTypes.CARRIER].includes(user.role)) {
    return `${user.firstName} ${user.lastName} - ${user.company}`
  }
  return `${user.firstName} ${user.lastName}`
}

export const HeaderFilter = ({
  page,
  user,
}: {
  page: Page
  user: CurrentUser
}) => {
  {
    switch (user.role) {
      case RoleTypes.ADMIN: {
        {
          if (page.name == REPORTS) {
            if (!user.claims.includes(GrantedClaims.REPORTS)) {
              return false
            }
          }
          return true
        }
      }

      case RoleTypes.CUSTOMER: {
        if (page.name === APPROVALS) {
          return false
        }
        if (page.name == REPORTS) {
          if (!user.claims.includes(GrantedClaims.REPORTS)) {
            return false
          }
          return true
        }
        return true
      }

      case RoleTypes.EMPLOYEE: {
        if (page.name == APPROVALS) {
          if (
            [
              JobTitles.CUSTOMER_SERVICE_REPRESENTATIVE,
              JobTitles.CUSTOMER_SERVICE_SUPERVISOR,
              JobTitles.CUSTOMER_SERVICE_DIRECTOR,
              JobTitles.VP_OF_NATIONAL_ACCOUNT_SALES,
              JobTitles.VP_OF_DISTRIBUTION_SALES,
            ].includes(user.jobTitle)
          ) {
            return true
          }
          return false
        }
        if (page.name == REPORTS) {
          if (!user.claims.includes(GrantedClaims.REPORTS)) {
            return false
          }
          return true
        }
        return true
      }

      case RoleTypes.BROKER: {
        if (page.name === APPROVALS) {
          return false
        }
        if (page.name == REPORTS) {
          if (!user.claims.includes(GrantedClaims.REPORTS)) {
            return false
          }
          return true
        }
        return true
      }

      case RoleTypes.CARRIER: {
        if (page.name == RETURNS) {
          return true
        }
        return false
      }
      default:
        return true
    }
  }
}

export const HeaderItem: FC<{
  page: Page
  toggleDrawer?: () => void
  icon?: ReactElement
}> = ({ page, toggleDrawer, icon }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const urlArray = pathname.slice(1).split('/')
  const isCurrentPageActive =
    (urlArray.includes(INVOICES.toLowerCase()) && page.name === INVOICES) ||
    (urlArray.includes(ORDERS.toLowerCase()) && page.name === ORDERS) ||
    (urlArray.includes(RETURNS.toLowerCase()) && page.name === RETURNS) ||
    (urlArray.includes(APPROVALS.toLowerCase()) && page.name === APPROVALS) ||
    (urlArray.includes(REPORTS.toLowerCase()) && page.name === REPORTS)
  const firstItemFromArrayURL = urlArray[0]
  const secondItemFromArrayURL = urlArray[1]
  const approvalUrl = `${firstItemFromArrayURL}/${secondItemFromArrayURL}`
  const [selectedUrl, setSelectedUrl] = useState(approvalUrl)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    setSelectedUrl(approvalUrl)
  }, [approvalUrl])

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const approvalNavigation = (path: string) => {
    navigate(path)
    setAnchorEl(null)
    setSelectedUrl(path)
    return true
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isDesktop = useMediaQuery(theme.breakpoints.down('lg'))
  const accounts =
    useContext(AccountsContext) ||
    ({} as {
      selectedAccount: Account
      selectedSubAccount: SubAccount
    })

  const link: string[] = []

  const CheckAccountLink = (
    pageTitle: string[],
    pageName: string,
    link: string[]
  ) => {
    if (!pageTitle.includes(pageName)) {
      return
    }
    if ([null, undefined].includes(accounts.selectedAccount)) {
      return
    }
    link.push(accounts.selectedAccount.id)
    if ([null, undefined].includes(accounts.selectedSubAccount)) {
      return
    }
    link.push(accounts.selectedSubAccount.id)
  }

  CheckAccountLink([INVOICES, ORDERS, PRICE_LIST, RETURNS], page.name, link)

  link.push(convertPageToLink(page.name))

  const MenuItemList = ({ item, icon }: ListItem) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel
    return <Component item={item} icon={icon} />
  }

  const linkBtn = {
    color: acrColorBlue,
    textTransform: 'capitalize',
  }

  const activeLinkBtn = {
    fontWeight: 600,
    textDecoration: 'underline',
    textTransform: 'capitalize',
    textUnderlineOffset: '4px',
    textDecorationColor: acrColorLime,
    textDecorationThickness: '2px',
  }

  const SingleLevel = ({ item, icon }: ListItem) => {
    return (
      <ListItemButton
        divider
        to={link.join('/')}
        onClick={toggleDrawer}
        component={NavLink}
        sx={{
          ...linkBtn,
          bgcolor: acrColorWhite,
          '&.active': { ...activeLinkBtn, bgcolor: acrColorRedishGrey },
        }}
      >
        <Box className="svgWrapper">{icon}</Box>
        <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>
          {item.name}
        </Typography>
      </ListItemButton>
    )
  }

  const MultiLevel = ({ item, icon }: ListItem) => {
    const { menuItem: children } = item
    const [open, setOpen] = useState(false)

    const handleClick = () => {
      setOpen((prev) => !prev)
    }

    return (
      <Fragment>
        <ListItemButton
          divider
          onClick={handleClick}
          sx={[
            isCurrentPageActive
              ? { ...activeLinkBtn, bgcolor: acrColorRedishGrey }
              : { ...linkBtn, bgcolor: acrColorWhite },
          ]}
        >
          <Box className="svgWrapper">{icon}</Box>
          <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>
            {item.name}
          </Typography>
          {open ? (
            <ExpandLessIcon sx={{ marginLeft: 'auto' }} />
          ) : (
            <ExpandMoreIcon sx={{ marginLeft: 'auto' }} />
          )}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children.map((child: Item, key: number) => {
            const childLink: string[] = []
            CheckAccountLink([PRICE_CHANGE_HISTORY], child.name, childLink)

            childLink.push(convertPageToLink(page.name))
            childLink.push(convertPageToLink(child.name))
            return (
              <ListItemButton
                key={key}
                divider
                to={childLink.join('/')}
                onClick={toggleDrawer}
                component={NavLink}
                sx={{
                  '&.active': {
                    color: acrColorBlue,
                    fontWeight: '700',
                    bgcolor: 'lightgrey',
                  },
                  color: acrColorGrey,
                  fontWeight: '500',
                  textTransform: 'capitalize',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                    lineHeight: 'normal',
                    fontWeight: '500',
                    padding: '5px 0px 0px 34px',
                  }}
                >
                  {child.name}
                </Typography>
              </ListItemButton>
            )
          })}
        </Collapse>
      </Fragment>
    )
  }

  if (isTablet) {
    return <MenuItemList item={page} icon={icon} />
  } else {
    return (
      <>
        {!page.menuItem && (
          <Button
            component={NavLink}
            to={link.join('/')}
            className={cn('top-simple-item', isDesktop && 'margin-left-none')}
            sx={{
              '&.active': activeLinkBtn,
              ...linkBtn,
            }}
          >
            {page.name}
          </Button>
        )}

        {page.menuItem && (
          <>
            <Button
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              className={cn(
                'top-dropdown-item',
                isDesktop && 'margin-left-none'
              )}
              sx={[isCurrentPageActive ? activeLinkBtn : linkBtn]}
            >
              {page.name}
              {anchorEl ? (
                <ExpandLessIcon
                  sx={[
                    { mr: -1 },
                    isCurrentPageActive ? activeLinkBtn : linkBtn,
                  ]}
                />
              ) : (
                <ExpandMoreIcon
                  sx={[
                    { mr: -1 },
                    isCurrentPageActive ? activeLinkBtn : linkBtn,
                  ]}
                />
              )}
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {page.menuItem.map((child: Item, index: number) => {
                const childLink: string[] = []
                CheckAccountLink([PRICE_CHANGE_HISTORY], child.name, childLink)

                childLink.push(convertPageToLink(page.name))
                childLink.push(convertPageToLink(child.name))

                const isSelectedActive = () => {
                  if (
                    accounts.selectedAccount?.id === firstItemFromArrayURL &&
                    accounts.selectedSubAccount?.id === secondItemFromArrayURL
                  ) {
                    return (
                      JSON.stringify(urlArray) === JSON.stringify(childLink)
                    )
                  } else if (
                    accounts.selectedAccount?.id === firstItemFromArrayURL
                  ) {
                    return (
                      JSON.stringify(urlArray) === JSON.stringify(childLink)
                    )
                  } else {
                    return selectedUrl === childLink.join('/')
                  }
                }
                return (
                  <MenuItem
                    key={index + child.name}
                    onClick={() => approvalNavigation(childLink.join('/'))}
                    selected={isSelectedActive()}
                    sx={{
                      '&.Mui-selected': {
                        color: acrColorBlue,
                        fontWeight: 'bold',
                      },
                    }}
                  >
                    {child.name}
                  </MenuItem>
                )
              })}
            </Menu>
          </>
        )}
      </>
    )
  }
}

const Header: FC = (): ReactElement => {
  const navigate = useNavigate()
  const logoutMutation = useLogoutMutation()
  const { data: user } = useAuth()
  const { pathname } = useLocation()
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const isNotLoginPage = pathname !== '/login'
  const isNotRegisterPage = pathname !== '/register'
  const isNotProductionEnvironment = process.env.APP_ENV != 'prod'
  const isNotCarrierRegisterPage = pathname !== '/new-carrier-register'

  const handleMenu = () => {
    navigate('/manage/profile')
  }

  const handleLogout = () => {
    logoutMutation.mutate(null, {
      onSuccess: () => {
        window.location.href = '/login'
      },
    })
  }

  const displayDesktop = () => {
    return (
      <>
        {user && !isTablet && (
          <Box className="logoutBar">
            <Stack
              spacing={2}
              direction="row"
              sx={[
                {
                  marginLeft: 'auto',
                },
                user.impersonate && {
                  background: acrColorTeal,
                  paddingRight: '5px',
                },
              ]}
            >
              <IconButton
                aria-label="logout"
                size="small"
                onClick={handleLogout}
                sx={{
                  color: acrColorWhite,
                  width: '37px',
                  height: '37px',
                }}
              >
                <LogoutIcon
                  sx={{
                    transform: 'scaleX(-1)',
                  }}
                  fontSize="small"
                />
              </IconButton>
              <Button
                variant="text"
                onClick={handleMenu}
                endIcon={
                  user.impersonate ? <SupervisorAccountIcon /> : <PersonIcon />
                }
                className="handle-margin"
                sx={{
                  color: acrColorWhite,
                  fontWeight: '600',
                  textTransform: 'Capitalize',
                }}
              >
                <Box sx={{ lineHeight: 'normal' }}>
                  <UserName user={user} />
                </Box>
              </Button>
            </Stack>
          </Box>
        )}
        {!user && (
          <Box className="logoutBar">
            <Stack
              spacing={1}
              direction="row"
              sx={{
                marginLeft: 'auto',
                textAlign: 'center',
              }}
            >
              {isNotRegisterPage && (
                <Button
                  component={NavLink}
                  to="/register"
                  className="topLink"
                  variant="text"
                >
                  {NEW_USER}
                </Button>
              )}

              {isNotCarrierRegisterPage &&
                isNotRegisterPage &&
                isNotProductionEnvironment && (
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    className="hrLine"
                  />
                )}

              {isNotCarrierRegisterPage && isNotProductionEnvironment && (
                <Button
                  component={NavLink}
                  to="/new-carrier-register"
                  className="topLink"
                  variant="text"
                >
                  {CARRIER_REGISTRATION}
                </Button>
              )}

              {(!isNotRegisterPage ||
                (isNotLoginPage && isNotRegisterPage)) && (
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  className="hrLine"
                />
              )}

              {isNotLoginPage && (
                <>
                  <Button
                    component={NavLink}
                    to="/login"
                    className="topLink"
                    variant="text"
                  >
                    {LOGIN}
                  </Button>
                </>
              )}
            </Stack>
          </Box>
        )}
        <Toolbar
          sx={{
            [theme.breakpoints.down('md')]: {
              minHeight: '50px',
              px: '16px',
            },
          }}
        >
          <Link href="https://weareacr.com" target="_blank">
            <img
              src={Logo}
              alt={Logo}
              style={{
                height: `${HEADER_HEIGHT}px`,
                marginRight: '10px',
                verticalAlign: 'top',
              }}
            />
          </Link>
          {user && (
            <>
              {isTablet ? (
                <>
                  <Box sx={{ flex: 1, paddingLeft: 2 }}>
                    <SearchBox />
                  </Box>
                  <DrawerComp
                    UserName={UserName}
                    handleMenu={handleMenu}
                    handleLogout={handleLogout}
                    title={Logo}
                  />
                </>
              ) : (
                <>
                  <Stack spacing={3} direction="row">
                    {Pages.filter((page) => HeaderFilter({ page, user })).map(
                      (page, index) => {
                        return <HeaderItem key={index} page={page} />
                      }
                    )}
                  </Stack>
                  <Box sx={{ flex: 1, paddingLeft: 2 }}>
                    <SearchBox />
                  </Box>
                </>
              )}
            </>
          )}
        </Toolbar>
      </>
    )
  }

  return (
    <AppBar
      sx={{
        background: acrColorWhite,
        boxShadow: 'none',
        boxSizing: 'border-box',
        borderBottom: '1px solid',
        borderBottomColor: acrColorBorderBlue,
      }}
    >
      {displayDesktop()}
    </AppBar>
  )
}

export default Header
