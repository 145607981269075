import { FC, ReactElement, useState } from 'react'
import { NavLink } from 'react-router-dom'

import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
} from '@mui/material'

import UserTab from '~/components/Manage/Users/UserTab'
import { useAuth } from '~/services/auth'
import { TEXTS } from '~/utils/allConstants/Constants'

import { HeaderFilter, HeaderItem, IUserNameProps } from '../Header'
import { MobileMenus } from '../MenuData'

import './DrawerComp.scss'
import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue } = variables
const { LOGOUT } = TEXTS

interface Props {
  title: string
  handleLogout: () => void
  handleMenu: () => void
  UserName: FC<IUserNameProps>
}

const DrawerComp: FC<Props> = ({
  UserName,
  handleMenu,
  handleLogout,
  title,
}): ReactElement => {
  const { data: user } = useAuth()
  const [openDrawer, setOpenDrawer] = useState(false)
  const toggleDrawer = () => setOpenDrawer((prevState) => !prevState)

  return (
    <>
      <Drawer open={openDrawer} onClose={toggleDrawer} className="sidePanel">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Link
            to="/"
            component={NavLink}
            sx={{ width: '210px', textAlign: 'center' }}
          >
            <img
              src={title}
              alt={title}
              style={{
                maxWidth: '80%',
                margin: '20px auto 0px auto',
              }}
            />
          </Link>
        </Box>
        <Button
          variant="text"
          color="secondary"
          onClick={() => {
            handleMenu()
            toggleDrawer()
          }}
          startIcon={<PersonIcon />}
          sx={{
            fontWeight: 'bold',
            textTransform: 'none',
            fontSize: 20,
            padding: '20px 0px 20px 0px',
            justifyContent: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: 250,
          }}
          size="large"
        >
          <UserName user={user} />
        </Button>
        <Divider />
        <Box p={1}>
          <UserTab toggleDrawer={toggleDrawer} />
        </Box>
        <Divider />
        <List
          sx={{
            width: '100%',
            padding: 0,
          }}
          component="nav"
          aria-label="mailbox folders"
        >
          {MobileMenus.filter((page) => HeaderFilter({ page, user })).map(
            (list, index) => (
              <HeaderItem
                key={index}
                page={list}
                toggleDrawer={toggleDrawer}
                icon={<list.icon sx={{ m: '5px' }} />}
              />
            )
          )}
        </List>
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <Button
            className="acr-button bg-lime"
            onClick={handleLogout}
            startIcon={
              <LogoutIcon sx={{ transform: 'scaleX(-1)' }} fontSize="small" />
            }
            sx={{
              position: 'relative',
              bottom: '0',
            }}
          >
            {LOGOUT}
          </Button>
        </Box>
      </Drawer>
      <IconButton onClick={toggleDrawer}>
        <MenuIcon sx={{ color: acrColorBlue }} />
      </IconButton>
    </>
  )
}

export default DrawerComp
