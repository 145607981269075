import { FC, ReactElement } from 'react'
import cn from 'classnames'

import { Box } from '@mui/system'

import './FooterCard.scss'

interface Props {
  heading: string
  children: ReactElement
  isSmall?: boolean
}
const FooterCard: FC<Props> = ({
  heading,
  children,
  isSmall,
}): ReactElement => {
  return (
    <Box component="div">
      <p
        className={cn(
          'footerSectionHeading',
          isSmall ? 'footerSectionSubHeading' : ''
        )}
      >
        {heading}
      </p>
      {children}
    </Box>
  )
}

export default FooterCard
