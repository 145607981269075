// APP TEXT
export const APP_TITLE = 'Amer Care Royal'
export const REASONS_KEY = 'selectedReturnReasons'
export const LINK_PATTERN =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
export const VALID_LINK_ALERT = 'Please provide a valid link'

export const PAGE_TITLES = {
  DASHBOARD: 'Dashboard',
  INVOICES: 'Invoices',
  ORDERS: 'Orders',
  PRICE_LIST: 'Price List',
  RETURNS: 'Returns',
  APPROVALS: 'Approvals',
  REPORTS: 'Reports',
}

export const MENUS = {
  ALL_APPROVALS: 'All Approvals',
  ALL_RETURNS: 'All Returns',
  MY_APPROVALS: 'My Approvals',
  PRICE_CHANGE_HISTORY: 'Price Change History',
}

export const TEXTS = {
  YES: 'Yes',
  NO: 'No',
  BACK: 'Back',
  SAVE: 'Save',
  LINK: 'Link',
  DISCARD: 'Discard',
  CANCEL: 'Cancel',
  CANCELED: 'Canceled',
  CLOSE: 'Close',
  AMOUNT: 'Amount',
  SHIPPED: 'Shipped',
  ORDERED: 'Ordered',
  USERNAME: 'Username',
  ONE_TIME_CODE: 'One Time Code',
  APPLY_FILTERS: 'Apply Filters',
  REOPEN: 'Reopen',
  CONTINUE: 'Continue',
  LOGIN: 'Log In',
  LOGOUT: 'Logout',
  PRICE: 'Price',
  ADD_FILTER: 'Add Filter',
  ASSIGN_TO_ME: 'Assign To Me',
  DISPUT_DENIALS: 'Dispute Denial(s)',
  NEW_USER: 'New User?',
  CARRIER_REGISTRATION: 'Carrier Registration',
  RETURN: 'Return',
  REVIEW: 'Review',
  ATTACHMENTS: 'Attachments',
  DOWNLOAD_ATTACHMENTS: 'Download Attachments',
  EXPORT_TO_PDF: 'EXPORT TO PDF',
  EXPORT_RA_TO_PDF: 'Export RA to PDF',
  EXPORT_TO_EXCEL: 'EXPORT TO EXCEL',
  WAREHOUSE_MISPICK: 'Warehouse Mispick',
  NO_DATA_MESSAGE: (dataName: string) =>
    `No ${dataName} available for your selection, please make a new selection or contact customer support`,
  REQUIRED_FIELD_MESSAGE: (fieldName: string) =>
    `${fieldName} is a required field`,
  INVALID_FIELD_MESSAGE: (fieldName: string) => `${fieldName} is not valid`,
  ACCOUNT_SELECT_MESSAGE:
    'Please Select Account and Sub-Account before proceeding',
  NO_USER_MESSAGE:
    'We are unable to locate the username entered in our database. Please reenter your username to try again.',
  ACCESS_DENIED_MESSAGE: 'Access denied this account is not approved.',
  ACCOUNT_LOCKED_MESSAGE:
    'Your account has been temporarily locked due to multiple unsuccessful login attempts. Please check your registered email to reset your password.',
  CONFIRM_EMAIL_MESSAGE: 'Please confirm your email before logging in.',
  MANY_ATTEMPTS_MESSAGE:
    'Too many requests. Please wait a few seconds and try again.',
  NOT_AUTHORIZED: 'Not Authorized',
  APPROVAL_STATUS: 'Approval Status',
  WAREHOUSE_LOCATIONS: 'Warehouse Locations',
  INTERNAL_REASON: 'Internal Reason',
  WRONG_ITEM: 'Wrong Item',
  PICKUP_INFO: 'Pickup Info',
  COMMENTS: 'Comments',
  ALL_OPEN: 'All open',
  ALL_CLOSED: 'All closed',
  N_A: 'N/A',
  LINE: 'Line',
  ITEM: 'Item',
  ITEM_NO: 'Item No.',
  QTY: 'Qty',
  PO: 'PO',
  ORDER: 'Order',
  STATUS: 'Status',
  BALANCE: 'Balance',
  CARRIER: 'Carrier',
  DESCRIPTION: 'Description',
  APPLIED: 'Applied',
  TRACKING_NUMBER: 'Tracking Number',
  REASON_CODE: 'Reason Code',
  RETURN_REASON_CODE: 'Return Reason Code',
  DENIAL_REASON_CODE: 'Denial Reason Code',
  INTERNAL_REASON_CODE: 'Internal Reason Code',
  REVIEWER_COMMENT: 'Reviewer Comment',
  APPROVER_COMMENT: 'Approver Comment',
  CUSTOMER_NAME: 'Customer Name',
  CUSTOMER_NUMBER: 'Customer Number',
  SHIP_TO_NUMBER: 'Ship To Number',
  CITY: 'City',
  STATE: 'State',
  SALESMAN_NAME: 'Salesmen Name',
  SALESMAN_NUMBER: 'Salesmen Number',
  RETURN_STATUS: 'Return Status',
  PURCHASE_ORDER: 'Purchase Order',
  ORDER_DATE: 'Order Date',
  ORDER_NUMBER: 'Order Number',
  ORDER_TOTAL: 'Order Total',
  ORDER_STATUS: 'Order Status',
  REQ_DELIVERY_DATE: 'Req Delivery Date',
  ACTUAL_SHIPMENT_DATE: 'Actual Shipment Date',
  WHO_PLACED_ORDER: 'Who Placed Order',
  CUSTOMER_PO: 'Customer Po',
  CLOSED: 'Closed',
  INVOICED: 'Invoiced',
  INVOICE_DATE: 'Invoice Date',
  INVOICE_DUE_DATE: 'Invoice Due Date',
  INVOICE_NUMBER: 'Invoice Number',
  INVOICE_AMOUNT: 'Invoice Amount',
  RETURN_CREATION_DATE: 'Return Creation Date',
  ASSIGNED_TO: 'Assigned To',
  MY_ASSIGNED_ITEMS: 'My Assigned Items',
  COMPLETE_APPROVAL: 'Complete Approval',
  COMPLETE_RESEARCH: 'Complete Research',
  NO_DATA_FOUND: 'No data found',
  RETURN_QTY: 'Return Qty',
  SHIPPED_QTY: 'Shipped Qty',
  AVAILABLE_QTY: 'Available Qty',
  AVAILABLE_RETURN_QTY: 'Available Return Qty',
  PASSWORD: 'Password',
  CREATE_PASSWORD: 'Create Password',
  UPDATE_PASSWORD: 'Update Password',
  RESET_PASSWORD: 'Reset Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  VIEW_MORE: 'View More',
  VIEW_LESS: 'View Less',
  MARK_AS_READ: 'Mark as read',
  UPDATE: 'Update',
  SUBMIT: 'Submit',
  START_DATE: 'Start Date',
  EXPIRATION: 'Expiration',
  BANNER_NOTIFICATION: 'Banner Notification',
  SECURITY_ROLE: 'Security Role',
  CONTAINS_FILE: 'Contains File',
  PREVIEW: 'Preview',
  EDIT: 'Edit',
  ACTIVE: 'Active',
  PENDING: 'Pending',
  EXPIRED: 'Expired',
  CLAIMS: 'Claims',
  GRANTED_CLAIMS: 'Granted Claims',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  EMAIL_CONFIRMED: 'Email Confirmed',
  EMAIL_ADDRESS: 'Email Address',
  MOBILE_NUMBER: 'Mobile Number',
  PHONE_NUMBER: 'Phone Number',
  COMPANY: 'Company',
  COMPANY_NAME: 'Company Name',
  COMPANY_PHONE_NUMBER: 'Company Phone Number',
  AREA_OF_RESPONSIBILITY: 'Area of Responsibility',
  USER_TYPE: 'User Type',
  MY_ACCOUNT: 'My Account',
  MANAGE_USERS: 'Manage Users',
  SITE_SETTINGS: 'Site Settings',
  SYSTEM_INFORMATION: 'System Information',
  CREATE_NEW_USER: 'Create New User',
  REGISTER: 'Register',
  REGISTER_NEW_USER: 'Register a New User',
  NEW_CARRIER_REGISTER: 'New Carrier Register',
  YOUR_CURRENT_PASSWORD: 'Your Current Password',
  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_NEW_PASSWORD: 'Confirm New Password',
  FORGOT_PASSWORD: 'Forgot Password',
  ITEM_NUMBER: 'Item Number',
  ITEM_DESCRIPTION: 'Item Description',
  DIVISION_NAME: 'Division Name',
  CLASS_NAME: 'Class Name',
  EFFECTIVE_FROM: 'Effective From',
  CURRENT_PRICE: 'Current Price',
  INCORRECT_PRICE: 'Incorrect Price',
  INVOICED_PRICE: 'Invoiced Price',
  QUOTED_PRICE: 'Quoted Price',
  EXTENDED_AMOUNT: 'Ext. Amount',
  CURRENT_PRICE_LIST: 'Current Price List',
  FUTURE_PRICE_LIST: 'Future Price List',
  ORIGINAL: 'Original',
  APPROVED: 'Approved',
  EFFECTIVE_START: 'Effective Start',
  EFFECTIVE_END: 'Effective End',
  CHANGE: 'Change',
  EXPORT_AS_PDF: 'Export as Pdf',
  EXPORT_AS_EXCEL: 'Export as Excel',
  EXPORT_AS_ZIP: 'Export as Zip',
  EXPORT_RA_AS_PDF: 'Export RA as PDF',
  RETURN_HASH: 'Return#',
  ORDER_HASH: 'Order#',
  CUSTOMER_HASH: 'Customer#',
  SHIP_TO_HASH: 'Ship To#',
  RETURN_VALUE_DOLLAR: 'Return Value $',
  ALL_INVOICES: 'All Invoices',
  OPEN_INVOICES: 'Open Invoices',
  CLOSED_INVOICES: 'Closed Invoices',
  OPEN_ORDERS: 'Open Orders',
  ORDERS_HISTORY: 'Orders History',
  OPEN_RETURNS: 'Open Returns',
  RETURNS_HISTORY: 'Returns History',
  SOMETHING_WENT_WRONG: 'Something Went Wrong!',
  SUBMIT_RETURN_REQUEST: 'Submit Return Request',
  CREDIT_REQUEST_REVIEW: 'Credit Request Review',
  RETURN_REQUEST_REVIEW: 'Return Request Review',
  RETURN_REQUEST_REVIEWER: 'Return Request Reviewer',
  SEND_COMMENT: 'Send Comment',
  SEND_REQUEST: 'Send Request',
  EMAIL_FORMAT_MESSAGE: 'Provided email has incorrect format',
  NO_EMAIL_MESSAGE: 'No email provided',
  NO_PASSWORD_MESSAGE: 'No password provided',
  NO_OLD_PASSWORD_MESSAGE: 'No old password provided',
  NO_NEW_PASSWORD_MESSAGE: 'No new password provided',
  PASSWORDS_MATCH_MESSAGE: 'Passwords must match',
}

export const APPROVAL_LEVELS = {
  CSR_WORK_QUEUE: 'CSR Work Queue',
  CSS_WORK_QUEUE: 'CSS Work Queue',
  DIRECTOR_WORK_QUEUE: 'Director Work Queue',
  VP_WORK_QUEUE: 'VP Work Queue',
  LOGISTICS_WORK_QUEUE: 'Logistics Work Queue',
  AR_WORK_QUEUE: 'AR Work Queue',
  AR_WORK_MANAGER_QUEUE: 'AR Manager Work Queue',
}

export enum ApprovalStatuses {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CLOSED = 'CLOSED',
  PENDING = 'PENDING',
  REVIEWED = 'REVIEWED',
}

export const APPROVAL_STATUSES = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING',
  REVIEWED: 'REVIEWED',
}

export const HEADER_HEIGHT = 40

export const DATE_STRING_OPTIONS: Intl.DateTimeFormatOptions = {
  hour12: false,
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}

export const RETURN_STATUSES = [
  'Pending Approval ',
  'Denied ',
  'Approved - Pending Scheduling ',
  'Approved - Pickup Scheduled',
  'Received (Closed)',
]

export enum ApprovalStatusItems {
  APPROVED = 'Approved',
  DENIED = 'Denied',
}

export enum ApprovalStatusItemsCarrier {
  APPROVED_RA = 'Approved RA',
  APPROVED_NO_RA = 'Approved No RA',
  DENIED = 'Denied',
}

export enum GrantedClaims {
  PRICE_LIST_VIEWER = 'Price List Viewer',
  ADMINISTER_USERS = 'Administer Users',
  ADMIN_DASHBOARD_VIEW = 'Admin Dashboard View',
  CUSTOMER_RA_REQUEST = 'Customer RA Request',
  NEW_USER_CREATION = 'New User Creation',
  COMMON_ENTRY = 'Comment Entry',
  REPORTS = 'Report Price Change History',
  CARRIER_RETURNS = 'Carrier Returns',
  BANNER_NOTIFICATION_EDITOR = 'Banner Notification Editor',
}

export const enum Method {
  GET = 'GET',
  POST = 'POST',
}

export const enum JobTitles {
  CCO = 'CCO',
  VP_OF_DISTRIBUTION_SALES = 'VP of Distribution sales',
  DIRECT_OF_SALES = 'Director of Sales',
  REGIONAL_SALES_MANAGER = 'Regional Sales Manager',
  DISTRICT_SALES_MANAGER = 'District Sales Manager',
  SALES_COORDINATOR_MANAGER = 'Sales Coordinator Manager',
  SALES_COORDINATOR = 'Sales Coordinator',
  DIRECTOR_OF_CORPORATE_ACCOUNTS = 'Director of Corporate Accounts',
  CORPORATE_ACCOUNTS_MANAGER = 'Corporate Accounts Manager',
  INSIDE_SALES_MANAGER = 'Inside Sales Manager',
  INSIDE_SALES = 'Inside Sales',
  VP_OF_NATIONAL_ACCOUNT_SALES = 'VP of National Account Sales',
  DIRECTOR_OF_NATIONAL_ACCOUNTS = 'Director of National Accounts',
  NATIONAL_ACCOUNT_MANAGER = 'National Account Manager',
  NATIONAL_ACCOUNT_MANAGER_SPECIFIC = 'National Account Manager Specific',
  CUSTOMER_SERVICE_DIRECTOR = 'Customer Service Director',
  SALES_SUPPORT = 'Sales Support',
  CUSTOMER_SERVICE_REPRESENTATIVE = 'Customer Service Representative',
  CUSTOMER_SERVICE_SUPERVISOR = 'Customer Service Supervisor',
}

export enum RoutesCheck {
  PRIVATE = 'private',
  PUBLIC = 'public',
  SUPER_ADMIN = 'super_admin',
  NOT_CUSTOMER = 'not_customer',
  NOT_BROKER = 'not_broker',
  NOT_CARRIER = 'not_carrier',
  CUSTOMER_RA_REQUEST = 'customer_ra_request',
  CARRIER_RETURNS = 'carrier_returns',
  NOT_SERVICE_EMPLOYEE = 'not_service_employee',
  NEW_USER_CREATION = 'new_user_creation',
  REPORT = 'report',
  BANNER_NOTIFICATION_EDITOR = 'banner_notification_editor',
}

export enum ReturnItemPickupOptions {
  DO_NOT_PICK = 'Do not pick up, customer is keeping',
  SCHEDULE_PICK = 'Schedule Pick up',
}

export const VALIDATION_MESSAGES = {
  WAREHOUSE_LOCATION_REQUIRED: 'Warehouse Location is required.',
  APPROVAL_STATUS_REQUIRED: 'Approval Status is required.',
  INTERNAL_REASON_REQUIRED: 'Internal Reason is required.',
  DENIAL_REASON_REQUIRED: 'Denial Reason is required.',
}

export const RECORD_TYPE = {
  BILL_TO: 'BT',
}

export const RETURN_TYPE = {
  CSS: {
    type: 'CSS',
    msg: 'Return Approval research completed successfully.',
  },
  CARRIER: {
    type: 'carrierReturn',
    msg: 'Carrier Return research completed successfully.',
  },
  CREDIT: {
    type: 'creditReturn',
    msg: 'Credit Return research completed successfully.',
  },
}
