import { LoadingButton } from '@mui/lab'

import { useUnassign } from '~/services/approval'
import { ReturnRequestApproval } from '~/types/Approval.model'
import { APPROVAL_STATUSES } from '~/utils/allConstants/Constants'

const { APPROVED, REJECTED, CLOSED } = APPROVAL_STATUSES

export const UnAssignButton = ({
  row,
  setUnassignSuccess,
}: {
  row: ReturnRequestApproval
  setUnassignSuccess: any
}) => {
  const { mutate, isLoading } = useUnassign()

  const handleUnassign = () => {
    mutate(row.id, {
      onSuccess: () => setUnassignSuccess((prevVal: any) => !prevVal),
    })
  }

  return (
    <LoadingButton
      loading={isLoading}
      onClick={handleUnassign}
      className="action-button acr-button bg-teal"
      disabled={
        [APPROVED, CLOSED, REJECTED].includes(row.approvalStatus) ||
        !row.assignmentDetails.unassignableByCurrentUser
      }
    >
      Unassign
    </LoadingButton>
  )
}
