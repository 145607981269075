import axios from 'axios'

import { TEXTS } from '~/utils/allConstants/Constants'

import { doRefreshToken, getAccessToken, getRefreshToken } from './auth'

const instance = axios.create({
  baseURL: process.env.API_URL,
})

const { MANY_ATTEMPTS_MESSAGE } = TEXTS

instance.interceptors.request.use((config) => {
  const accessToken = getAccessToken()
  if (!!accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`
  }

  return config
})

let ttl = 0
instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response) {
      const responseStatus = error.response.status
      if (responseStatus === 401) {
        const refreshToken = getRefreshToken()
        if (!!refreshToken && ttl === 0) {
          ttl++
          return doRefreshToken()
            .then(() => {
              ttl = 0
              delete error.config.headers
              return instance(error.config)
            })
            .catch(() => {
              let tokens = 'tokens'
              if (
                window.sessionStorage.getItem('impersonate_tokens') !== null
              ) {
                tokens = 'impersonate_tokens'
              }
              window.localStorage.removeItem(tokens)
              window.sessionStorage.removeItem(tokens)

              window.location.href = '/login'
            })
        }
      } else if (responseStatus === 429) {
        return Promise.reject(MANY_ATTEMPTS_MESSAGE)
      }
    }

    return Promise.reject(error)
  }
)
export default instance
