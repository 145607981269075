import { Fields } from '~/types/Fields.model'
import { DivisionClassNamesApiData } from '~/types/PriceLists.model'
import GroupIcon from '~/UI/icons/GroupIcon'
import { TEXTS } from '~/utils/allConstants/Constants'
import { formatCurrency } from '~/utils/helpers/helperFunctions'

const { ITEM_NUMBER, ITEM_DESCRIPTION, DIVISION_NAME, CLASS_NAME, PRICE } =
  TEXTS

const getCurrentFields = ({
  divisionNames,
  classNames,
  handleDivisionChange,
}: DivisionClassNamesApiData) =>
  [
    {
      id: 'code',
      label: ITEM_NUMBER,
      minWidth: 100,
      filterFieldConfiguration: {
        type: 'freeText',
      },
      sort: true,
    },
    {
      id: 'name',
      label: ITEM_DESCRIPTION,
      minWidth: 100,
      sort: true,
    },
    {
      id: 'divisionName',
      label: DIVISION_NAME,
      minWidth: 100,
      filterFieldConfiguration: {
        type: 'select',
        values: divisionNames,
        onChange: handleDivisionChange,
      },
      sort: false,
      icon: (iconColor: string) => <GroupIcon color={iconColor} />,
      format: (_, row) => {
        return row.divisionName
      },
      groupType: 'outer',
    },
    {
      id: 'className',
      label: CLASS_NAME,
      minWidth: 100,
      filterFieldConfiguration: {
        type: 'select',
        values: classNames,
      },
      sort: false,
      icon: (iconColor: string) => <GroupIcon color={iconColor} />,
      format: (_, row) => {
        return row.className
      },
      groupType: 'inner',
    },
    {
      id: 'price',
      label: PRICE,
      minWidth: 100,
      format: (val: { currency: string; value: number }) =>
        formatCurrency(val?.value),
      filterFieldConfiguration: {
        type: 'range-freeText',
        prefix: '$',
      },
      sort: true,
    },
  ] as Fields[]

export { getCurrentFields }
