import { Box, FormControl, Stack } from '@mui/material'

import { theme } from '~/theme/AppTheme'

import AccountSelector from './selectors/AccountSelector'
import SubAccountSelector from './selectors/SubAccountSelector'

import './SubHeader.scss'
import variables from '~/theme/scss/_variables.module.scss'

const { acrColorWhite, acrColorBorderBlue } = variables

const SubHeader = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '5px 24px',
          boxSizing: 'border-box',
          borderBottom: '1px solid',
          borderBottomColor: acrColorBorderBlue,
          boxShadow: `0px 1px 4px 0px rgba(0, 0, 0, 0.1)`,
          position: 'fixed',
          top: '112px',
          left: '0',
          background: acrColorWhite,
          zIndex: 10,
          [theme.breakpoints.down('md')]: {
            top: '51px',
            padding: '5px 16px',
          },
          [theme.breakpoints.down('sm')]: {
            display: 'block',
          },
        }}
      >
        <Stack
          spacing={2}
          direction="row"
          sx={{
            width: '600px',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
            },
          }}
        >
          <FormControl
            fullWidth
            variant="standard"
            className="account-form-control"
          >
            <AccountSelector />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard"
            className="sub-account-form-control"
          >
            <SubAccountSelector />
          </FormControl>
        </Stack>
      </Box>
    </>
  )
}

export default SubHeader
