import { FC, ReactElement } from 'react'

import { Badge, CircularProgress, IconButton } from '@mui/material'

import CustomTooltip from '~/components/common/customTooltip/CustomTooltip'
import CommentIcon from '~/UI/icons/CommentIcon'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorMediumGrey } = variables

type Props = {
  isFetching: boolean
  hasComments: boolean
  onClick: () => void
}

const CommentsAction: FC<Props> = ({
  isFetching,
  hasComments,
  onClick,
}): ReactElement => {
  return (
    <CustomTooltip title="Comment">
      <IconButton onClick={onClick}>
        <Badge
          color={isFetching ? 'default' : 'warning'}
          variant={isFetching ? 'standard' : 'dot'}
          invisible={!isFetching && !hasComments}
          badgeContent={isFetching && <CircularProgress size="10px" />}
        >
          <CommentIcon color={acrColorMediumGrey} />
        </Badge>
      </IconButton>
    </CustomTooltip>
  )
}

export default CommentsAction
