import { FC, ReactNode } from 'react'

import { Box } from '@mui/material'

import './PreLoginLayout.scss'

type Props = {
  leftChildren: ReactNode
  rightChildren: ReactNode
}

const PreLoginLayout: FC<Props> = ({ leftChildren, rightChildren }) => {
  return (
    <Box className="mainSection">
      <Box className="leftSection">
        <Box sx={{ width: '100%', maxWidth: 500 }}>{leftChildren}</Box>
      </Box>
      <Box className="rightSection">
        <Box sx={{ width: '100%', maxWidth: { xs: 500, sm: 500, md: 700 } }}>
          {rightChildren}
        </Box>
      </Box>
    </Box>
  )
}

export default PreLoginLayout
