import { FC } from 'react'

import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material'

import { theme } from '~/theme/AppTheme'

interface Props {
  message: string
  closeModal?: () => void
}
const TableNoDataFoundModalBody: FC<Props> = ({ message, closeModal }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      sx={{
        position: 'relative',
        top: '1%',
        bgcolor: 'background.paper',
        textAlign: 'left',
        width: '100%',
      }}
    >
      <Grid
        container
        display="flex"
        justifyContent="center"
        mt={isMobile ? '35%' : '0%'}
      >
        <Grid item xs={12} my={12} mx={6} textAlign="center">
          <Typography variant="h5" sx={{ marginBottom: '50px' }}>
            {message}
          </Typography>
          <Typography my={2}>
            <Button
              className="acr-button bg-teal"
              sx={{
                width: 190,
              }}
              onClick={closeModal}
              fullWidth={isMobile}
            >
              Continue
            </Button>
          </Typography>
          <Typography my={1.5}>
            <Button
              className="acr-button"
              sx={{
                width: 190,
              }}
              fullWidth={isMobile}
              onClick={closeModal}
            >
              Cancel
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TableNoDataFoundModalBody
