import { FC, ReactElement } from 'react'
import { Controller } from 'react-hook-form'

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'

import { StyledFormFieldProps } from '~/types/StyledFormFieldProps.model'

import variables from '~/theme/scss/_variables.module.scss'

const {
  acrColorBlue,
  acrColorMediumGrey,
  acrColorLightGrey2,
  acrColorRedishGrey,
} = variables

export const StyledTextField = ({
  error = false,
  helperText = '',
  ...rest
}: StyledFormFieldProps & TextFieldProps) => {
  return (
    <>
      <TextField
        fullWidth
        multiline
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiInputBase-root': {
            '& ::placeholder': {
              color: acrColorMediumGrey,
              opacity: 1,
            },
          },
        }}
        {...rest}
        // variant="filled"
      />
      {!!error && (
        <FormHelperText variant="filled" error={!!error}>
          {!!error ? helperText : ''}
        </FormHelperText>
      )}
    </>
  )
}

export const ControllerStyledTextField = ({
  name,
  control,
  ...rest
}: StyledFormFieldProps & TextFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledTextField
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          InputProps={{
            rows: 5,
          }}
          error={!!error}
          placeholder="Your Comments..."
          helperText={error?.message}
          {...rest}
        />
      )}
    />
  )
}

const MenuProps = {
  sx: {
    '& .MuiPopover-paper': {
      boxShadow: 'none',
      borderColor: acrColorBlue,
      borderStyle: 'none solid solid solid',
      borderWidth: '1px',
      borderRadius: 0,
      borderTop: '1px solid',
      borderTopColor: acrColorRedishGrey,
    },
  },
}

interface SelectProps {
  field: any
  children: ReactElement
}

export const StyledSelect: FC<SelectProps> = ({
  field,
  children,
  ...rest
}): ReactElement => {
  return (
    <Select
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      displayEmpty
      MenuProps={MenuProps}
      sx={{
        border: '1px solid',
        borderColor: acrColorBlue,
        borderRadius: '0px',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&:hover': {
          borderColor: 'black',
        },
      }}
      renderValue={(value) => {
        if (value === '') {
          return (
            <Typography sx={{ color: acrColorLightGrey2 }}>
              Please Select
            </Typography>
          )
        } else return value
      }}
      IconComponent={(props) => (
        <ExpandMoreIcon {...props} style={{ color: acrColorLightGrey2 }} />
      )}
      {...rest}
    >
      {children}
    </Select>
  )
}

type IssueItem = {
  label: string
  value: string
}

export const ControllerStyledSelect = ({
  name,
  control,
  items,
  ...rest
}: StyledFormFieldProps & any) => {
  return (
    <FormControl sx={{ minWidth: 220 }}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <StyledSelect field={field} {...rest}>
              {items.map((item: IssueItem, index: number) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </StyledSelect>
            {!!error && (
              <FormHelperText error={!!error}>
                {!!error ? error.message : ''}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  )
}
