import { Link, Typography } from '@mui/material'

import PreLoginLayout from '~/components/common/preLoginLayout/PreLoginLayout'

const RegisterComplete = () => {
  const leftPart = (
    <Typography variant="h3" my={2}>
      Thank you for registering for the ACR Customer Portal!
    </Typography>
  )
  const rightPart = (
    <>
      <Typography variant="h3" mb={2}>
        You will soon receive an email asking you to confirm your email address.
        Once confirmed an ACR representative will review.
      </Typography>

      <Typography variant="h5" py={3}>
        If your active customer status is confirmed, you will receive an
        approval notification email. Non-ACR existing customers, please
        click&nbsp;
        <Link href="https://weareacr.com/contact" target="_blank">
          here
        </Link>
        &nbsp;for more information on how to open a customer account.
      </Typography>
    </>
  )
  return <PreLoginLayout leftChildren={leftPart} rightChildren={rightPart} />
}

export default RegisterComplete
