import { Typography } from '@mui/material'

import systemInstructions from '~/PDFs/WIN.Customer.Communication.Instructions.pdf'

type Account = {
  name: string
  shipToNumber: string | number
  state: string
  city: string
}

export const dashboardFields = [
  {
    id: 'invoices',
    title: 'Open Invoices',
    cardSubTitle: (currentAccount: Account, currentSubAccount: Account) => (
      <>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          {currentAccount?.name}
        </Typography>
        {currentSubAccount && (
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {`${currentSubAccount.shipToNumber} - ${currentSubAccount.city} - ${currentSubAccount.state}`}
          </Typography>
        )}
      </>
    ),
    cardContent: [
      { label: 'Open', id: 'open_cnt' },
      { label: 'Past Due', id: 'past_due_cnt' },
    ],
  },
  {
    id: 'orders',
    title: 'Open Orders',
    cardSubTitle: (currentAccount: Account, currentSubAccount: Account) => (
      <>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          {currentAccount?.name}
        </Typography>
        {currentSubAccount && (
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {`${currentSubAccount.shipToNumber} - ${currentSubAccount.city} - ${currentSubAccount.state}`}
          </Typography>
        )}
      </>
    ),
    cardContent: [
      { label: 'Open', id: 'open_cnt' },
      { label: 'Picking', id: 'picking_cnt' },
      { label: 'Shipped', id: 'shipped_cnt' },
    ],
  },
]

export const dashboardButtons = [
  { name: 'System Instructions', href: systemInstructions },
  {
    name: 'Quarterly Market Report',
    href: 'https://weareacr.com/resources/insights',
  },
  {
    name: 'Product Catalog',
    href: 'https://weareacr.com/catalogs/acr-product-catalog',
  },
]

export const adminDashboardFields = [
  {
    id: 'userPending',
    title: 'Users Pending Approval',
    cardContent: [
      { label: 'Customer', id: 'customer_cnt' },
      { label: 'ACR Employee', id: 'employee_cnt' },
      { label: 'Broker', id: 'broker_cnt' },
      { label: 'Others', id: 'other_cnt' },
    ],
  },
]

export const adminDashboardButtons = [
  { name: 'System Instructions', href: systemInstructions },
]
