import { FC, ReactElement, useEffect } from 'react'

import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, Typography } from '@mui/material'

import ModalHeader from '../UI/ModalHeader'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorTeal, acrColorBlue } = variables

type Props = {
  closeModal?: () => void
  startSurvey: () => void
  closeSurvey: () => void
  showSurveyForm: boolean
}

const CustomerSurveyModalBody: FC<Props> = ({
  closeModal,
  startSurvey,
  closeSurvey,
  showSurveyForm,
}): ReactElement => {
  const surveyURL = process.env.SURVEY_URL
  useEffect(() => {
    return () => {
      closeSurvey()
    }
  }, [])

  return (
    <>
      <ModalHeader
        heading="Share Your Feedback - Take Our Short Survey!"
        titleSx={{ color: acrColorTeal }}
      />
      <Box sx={{ flex: 1 }}>
        {showSurveyForm ? (
          <iframe
            title="Customer Survey Form"
            width="100%"
            height="100%"
            src={surveyURL}
            style={{
              border: 'none',
              maxWidth: '100%',
              maxHeight: '100vh',
              minHeight: 500,
            }}
            allowFullScreen
          ></iframe>
        ) : (
          <>
            <Typography variant="h5" color={acrColorBlue} mt={3}>
              Your feedback is important to us! We&apos;re always looking for
              ways to improve, and we&apos;d love to hear your thoughts. Please
              click on&nbsp;
              <strong>continue</strong> to complete our short survey and let us
              know how we&apos;re doing.
            </Typography>
            <Typography color={acrColorBlue} my={3}>
              If you want to complete our survey at a later time, you can access
              the survey using the <strong>Take a Quick Survey</strong> button
              located on your dashboard.
            </Typography>
          </>
        )}
      </Box>
      <Box sx={{ marginTop: 'auto' }}>
        <Divider />
        <Box pt={3} pb={2} gap={2} display="flex" justifyContent="center">
          {!showSurveyForm && (
            <LoadingButton className="acr-button bg-teal" onClick={startSurvey}>
              Continue to Survey
            </LoadingButton>
          )}
          <Button className="acr-button" onClick={closeModal}>
            Share my Feedback Later
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default CustomerSurveyModalBody
