import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import { fetchOrderDetailsData } from '~/services/orders'

import { GlobalLoading } from '../UI/Loading'

import { fields } from './components/ReturnDetailContent'
import ReturnDetailsPage from './components/ReturnDetailsPage'

const options = {
  name: 'Return',
  queryName: 'return-details',
  tableProps: {
    fields,
  },
}
const ReturnDetail = () => {
  const { id } = useParams<{ id?: string }>()

  const { isLoading, data } = useQuery(
    [`return-details`, id],
    () => {
      return fetchOrderDetailsData(id)
    },
    { keepPreviousData: true }
  )

  if (isLoading) {
    return <GlobalLoading />
  }

  return <ReturnDetailsPage data={data} options={{ ...options, id }} />
}

export default ReturnDetail
