import { memo, useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { Button } from '@mui/material'

import ContentWrapper from '~/components/UI/ContentWrapper/ContentWrapper'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import TabsTablePage from '~/components/UI/TabsTablePage'
import { useAssignToMeMutation } from '~/services/approval'
import { fetchReturns } from '~/services/returns'
import { ReturnRequestApproval } from '~/types/Approval.model'
import { TabsType } from '~/types/TabsType.model'
import { APPROVAL_LEVELS, TEXTS } from '~/utils/allConstants/Constants'

import { fields } from '../components/ApprovalTabContent'
import { UnAssignButton } from '../components/UnAssignButton'

const { NO_DATA_MESSAGE, ASSIGN_TO_ME, REVIEW } = TEXTS
const {
  CSR_WORK_QUEUE,
  CSS_WORK_QUEUE,
  DIRECTOR_WORK_QUEUE,
  VP_WORK_QUEUE,
  LOGISTICS_WORK_QUEUE,
  // Note: user story 3834 - hiding in prod
  // AR_WORK_QUEUE,
  // AR_WORK_MANAGER_QUEUE,
} = APPROVAL_LEVELS

const Returns = () => {
  const { active_tab } = useParams()
  const assignToMeMutation = useAssignToMeMutation()
  const currentTab = active_tab || 'csr'
  const { setTableProps, refetch } = useDataGridStore()
  const [unassignSuccess, setUnassignSuccess] = useState(null)
  useEffect(() => {
    refetch()
    setTableProps((prevState: TabsType) => {
      return {
        ...prevState,
        additionalFilter: prevState?.additionalFilters?.approverGroup,
      }
    })
  }, [unassignSuccess])

  const handleAssignToMe = (row: ReturnRequestApproval) => {
    assignToMeMutation.mutate(row.id)
  }

  const AssignToMeButton = ({
    row,
    isReview,
  }: {
    row: ReturnRequestApproval
    isReview?: boolean
  }) => (
    <Button
      component={NavLink}
      className="action-button acr-button bg-teal"
      to={`${row.id}/details`}
      disabled={row.assignmentDetails.assignableToCurrentUser === false}
      onClick={() => handleAssignToMe(row)}
    >
      {isReview ? REVIEW : ASSIGN_TO_ME}
    </Button>
  )

  const actions = [
    {
      render: (row: ReturnRequestApproval) => <AssignToMeButton row={row} />,
    },
  ]

  // Note: user story 3834 - hiding in prod
  // const actionsForCreditReturn = [
  //   {
  //     render: (row: ReturnRequestApproval) => (
  //       <AssignToMeButton row={row} isReview />
  //     ),
  //   },
  // ]

  const unAssignActions = [
    {
      render: (row: ReturnRequestApproval) => {
        return (
          <>
            <AssignToMeButton row={row} />
            <UnAssignButton row={row} setUnassignSuccess={setUnassignSuccess} />
          </>
        )
      },
    },
  ]

  const defaultProps = {
    source: fetchReturns,
    fields,
    notFoundMessage: NO_DATA_MESSAGE('Returns'),
  }

  const params: TabsType[] = [
    {
      label: CSR_WORK_QUEUE,
      name: 'csr',
      ...defaultProps,
      actions,
      additionalFilters: {
        approverGroup: 'CSR',
      },
    },
    {
      label: CSS_WORK_QUEUE,
      name: 'css',
      ...defaultProps,
      actions,
      additionalFilters: {
        approverGroup: 'CSS',
      },
    },
    {
      label: DIRECTOR_WORK_QUEUE,
      name: 'director',
      ...defaultProps,
      actions: unAssignActions,
      additionalFilters: {
        approverGroup: 'DCS',
      },
    },
    {
      label: VP_WORK_QUEUE,
      name: 'vp',
      ...defaultProps,
      actions,
      additionalFilters: {
        approverGroup: 'VP',
      },
    },
    {
      label: LOGISTICS_WORK_QUEUE,
      name: 'lc',
      ...defaultProps,
      actions,
      additionalFilters: {
        approverGroup: 'LC',
      },
    },
    // Note: user story 3834 - hiding in prod
    // {
    //   label: AR_WORK_QUEUE,
    //   name: 'ar',
    //   ...defaultProps,
    //   actions: actionsForCreditReturn,
    //   additionalFilters: {
    //     approverGroup: 'AR',
    //   },
    // },
    // {
    //   label: AR_WORK_MANAGER_QUEUE,
    //   name: 'arm',
    //   ...defaultProps,
    //   actions: actionsForCreditReturn,
    //   additionalFilters: {
    //     approverGroup: 'ARM',
    //   },
    // },
  ]

  return (
    <ContentWrapper>
      <TabsTablePage currentTab={currentTab} tabs={params} />
    </ContentWrapper>
  )
}
// eslint-disable-next-line react/display-name
export default memo(() => (
  <DataGridProvider>
    <Returns />
  </DataGridProvider>
))
