import { Link as RouterLink } from 'react-router-dom'

import { Link, Typography } from '@mui/material'

import PreLoginLayout from '~/components/common/preLoginLayout/PreLoginLayout'

const ForgotPasswordComplete = () => {
  const leftPart = (
    <Typography variant="h1" my={2} textAlign="center">
      Success!
    </Typography>
  )
  const rightPart = (
    <>
      <Typography variant="h3" mb={2}>
        An email to reset your password has been sent.
      </Typography>

      <Typography variant="h5" py={3}>
        Please click&nbsp;
        <Link component={RouterLink} to="/login">
          here
        </Link>
        &nbsp; to go back to sign in.
      </Typography>
    </>
  )
  return <PreLoginLayout leftChildren={leftPart} rightChildren={rightPart} />
}

export default ForgotPasswordComplete
