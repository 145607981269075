import { Fields } from '../types/Fields.model'
import { PriceFilter } from '../types/Filter.model'
import { PageParams } from '../types/PageParams.model'
import {
  DivisionClassNamesParams,
  PriceListsSummary,
} from '../types/PriceLists.model'
import { Method } from '../utils/allConstants/Constants'
import { downloadFile } from '../utils/helpers/helperFunctions'

import axiosInstance from './axiosInstance'
import { createQueryParams, getPreparedFilters } from './filters-utils'

export const fetchDivisionNames = ({
  accountId,
  priceType,
}: DivisionClassNamesParams) => {
  return axiosInstance
    .get<string[]>('/v1/acr/products/division-names', {
      params: {
        accountId,
        priceType,
      },
    })
    .then(({ data }) => data)
}

export const fetchClassNames = ({
  accountId,
  priceType,
  divisionName,
}: DivisionClassNamesParams) => {
  let url = '/v1/acr/products/class-names'
  if (divisionName) {
    url = `${url}?divisionName=${divisionName}`
  }
  return axiosInstance
    .get<string[]>(url, {
      params: {
        accountId,
        priceType,
      },
    })
    .then(({ data }) => data)
}

export const fetchProducts = ({
  filters = {},
  fields,
  ...params
}: PageParams<PriceFilter>) => {
  let url = '/v1/acr/products'
  if (params?.groupBy) {
    url = `${url}/grouped`
  }

  const preparedFilters = getPreparedFilters(filters, fields)

  const restparams = Object.keys(params)
    .filter((key) => key !== 'additionalFilters')
    .reduce((obj, key) => {
      // @ts-ignore: Unmatched type error
      obj[key] = params[key]
      return obj
    }, {})

  return axiosInstance
    .get<PriceListsSummary>(url, {
      params: {
        ...restparams,
        ...preparedFilters,
      },
    })
    .then(({ data }) => data)
}

export const fetchFutureProducts = ({
  filters = {},
  fields,
  ...params
}: PageParams<PriceFilter>) => {
  let url = '/v1/acr/products/futurePrices'
  if (params?.groupBy) {
    url = `${url}/grouped`
  }

  const preparedFilters = getPreparedFilters(filters, fields)

  const restparams = Object.keys(params)
    .filter((key) => key !== 'additionalFilters')
    .reduce((obj, key) => {
      // @ts-ignore: Unmatched type error
      obj[key] = params[key]
      return obj
    }, {})

  return axiosInstance
    .get<PriceListsSummary>(url, {
      params: {
        ...restparams,
        ...preparedFilters,
      },
    })
    .then(({ data }) => data)
}

export const exportProductFile = ({
  extension,
  filters,
  fields,
  tab,
  ...params
}: {
  extension: string
  filters: PageParams<PriceFilter>
  fields: Fields[]
  tab: string
}) => {
  const url =
    tab === 'current'
      ? '/v1/acr/products/export'
      : '/v1/acr/products/futurePrices/export'
  const fileName =
    tab === 'current'
      ? `PriceList.${extension}`
      : `FuturePriceList.${extension}`
  const qParams = createQueryParams(filters, fields)
  const combinedParams: any = {
    ...params,
    ...qParams,
    type: extension,
  }
  return downloadFile(url, fileName, combinedParams, Method.GET)
}
