import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Button,
  CardActions,
  Container,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { fetchAccounts } from '~/services/accounts'
import { useAuth } from '~/services/auth'
import { fetchUnreadCustomerAlerts } from '~/services/bannerNotification'
import { fetchInvoices } from '~/services/invoices'
import { fetchOrders } from '~/services/orders'
import { RoleTypes, useSurveyComplete } from '~/services/users'
import { theme } from '~/theme/AppTheme'
import { Account } from '~/types/Account.model'
import { Invoice } from '~/types/Invoice.model'
import { Order, OrderStatus } from '~/types/Order.model'
import CustomModal from '~/UI/modals/CustomModal'
import CustomerSurveyModalBody from '~/UI/modals/modalBodies/customerSurveyModalBody/CustomerSurveyModalBody'

import { AccountsContext } from '../Accounts'
import { DashboardBannerPreview } from '../Manage/BannerNotification'
import { GlobalLoading } from '../UI/Loading'

import { dashboardButtons, dashboardFields } from './AllDashboardsContent'
import DashboardCard from './DashboardCard'

import './Dashboard.scss'
import variables from '~/theme/scss/_variables.module.scss'

const { acrColorTeal } = variables

const Dashboard = () => {
  const navigate = useNavigate()
  const { data: user } = useAuth()
  const completeSurveyMutation = useSurveyComplete(user.id)
  const [openSurveyModal, setOpenSurveyModal] = useState(false)
  const [openSurveyForm, setOpenSurveyForm] = useState(false)
  const [currentAccount, setAccount] = useState<Account | null>(null)
  const {
    selectedAccount: globalSelectedAccount,
    selectedSubAccount: globalSelectedSubAccount,
  } = useContext(AccountsContext)

  const showSurveyLink = user.showSurveyLink
  const isRoleCustomerOrBroker = [
    RoleTypes.CUSTOMER,
    RoleTypes.BROKER,
  ].includes(user.role)

  const searchParams = {
    page: 1,
    pageSize: 100,
    type: 'ACCOUNT',
    status: 'ACTIVE',
  }
  const { data, isFetching } = useQuery(
    ['accounts', searchParams],
    fetchAccounts,
    {
      placeholderData: {
        items: [],
      },
    }
  )
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const pageSize = 100
  const { data: invoiceData } = useQuery(
    [
      'dashboard_invoices',
      currentAccount?.customerNumber,
      globalSelectedSubAccount?.shipToNumber,
    ],
    async () => {
      let result: any[] = []
      let invoices
      let page = 1
      do {
        invoices = await fetchInvoices({
          page: page,
          pageSize: pageSize,
          fields: [],
          filters: {
            customerNumber: currentAccount?.customerNumber,
            shipToNumber: globalSelectedSubAccount?.shipToNumber,
            state: 'Open',
          },
        })
        page++
        result = result.concat(invoices.items)
      } while (invoices.items.length === pageSize)
      return result
    },
    {
      select: (data: Invoice[]) => {
        return {
          open_cnt: data.length,
          past_due_cnt: data.filter(
            (itm) => itm.dueDate !== null && +new Date(itm.dueDate) < Date.now()
          ).length,
          account_balance: Number(
            data.reduce((acc, itm) => acc + itm.balance, 0)
          ).toFixed(2),
        }
      },
      enabled: !!currentAccount?.customerNumber,
    }
  )

  const { data: orderData } = useQuery(
    [
      'orders',
      currentAccount?.customerNumber,
      globalSelectedSubAccount?.shipToNumber,
    ],
    async () => {
      let result: any[] = []
      let orders
      let page = 1

      do {
        orders = await fetchOrders({
          page: page,
          pageSize: pageSize,
          fields: [],
          filters: {
            customerNumber: currentAccount?.customerNumber,
            shipToNumber: globalSelectedSubAccount?.shipToNumber,
            state: 'Open',
          },
        })
        page++
        result = result.concat(orders.items)
      } while (orders.items.length === pageSize)

      return result
    },
    {
      select: (data: Order[]) => {
        return {
          open_cnt: data.filter((itm) => itm.orderStatus === OrderStatus.Open)
            .length,
          picking_cnt: data.filter(
            (itm) => itm.orderStatus === OrderStatus.Picking
          ).length,
          shipped_cnt: data.filter(
            (itm) => itm.orderStatus === OrderStatus.Shipped
          ).length,
        }
      },
      enabled: !!currentAccount?.customerNumber,
    }
  )

  const { data: customerAlertData, isFetching: isCustomerAlertFetching } =
    useQuery(
      [['unread-customer-alerts', { page: 1, pageSize: 10 }]],
      () => {
        return fetchUnreadCustomerAlerts({ page: 1, pageSize: 10 })
      },
      {
        keepPreviousData: false,
      }
    )

  useEffect(() => {
    if (showSurveyLink && isRoleCustomerOrBroker) {
      setOpenSurveyModal(true)
    }
  }, [showSurveyLink, isRoleCustomerOrBroker])

  useEffect(() => {
    if (globalSelectedAccount) {
      setAccount(globalSelectedAccount)
    }
  }, [globalSelectedAccount])

  useEffect(() => {
    if (data.items.length > 0 && !globalSelectedAccount) {
      const [account]: any = data.items
      setAccount(account)
    }
  }, [data])

  if (isFetching || isCustomerAlertFetching) {
    return <GlobalLoading />
  }

  const prevAccount = () => {
    const values = data.items
    const index = values.findIndex((itm: any) => itm.id === currentAccount.id)

    const realIndex = index === 0 ? values.length - 1 : index - 1
    setAccount(values[realIndex])
  }

  const nextAccount = () => {
    const values = data.items
    const index = values.findIndex((itm: any) => itm.id === currentAccount.id)

    const realIndex = index + 1 >= values.length ? 0 : index + 1
    setAccount(values[realIndex])
  }

  const goToLink = (pageName: string) => {
    navigate(`/${currentAccount.id}/${pageName}/open`)
  }

  const additionalCardProps = {
    headerAction: goToLink,
    cardActions: () => (
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Button size="small" onClick={prevAccount} sx={{ color: acrColorTeal }}>
          {'<'}
        </Button>
        <Button size="small" onClick={nextAccount} sx={{ color: acrColorTeal }}>
          {'>'}
        </Button>
      </CardActions>
    ),
    currentAccount,
    currentSubAccount: globalSelectedSubAccount,
  }

  const dataMapper: any = {
    invoices: invoiceData,
    orders: orderData,
  }

  return (
    <>
      <Box sx={{ px: { xs: 2, sm: 2, md: 3 } }}>
        <DashboardBannerPreview customerAlertData={customerAlertData} />
      </Box>
      <Container
        className={cn(isTablet && 'dashboard-container')}
        sx={{ mt: 2, mb: 3 }}
      >
        <Stack direction={isMobile ? 'column' : 'row'} spacing={4}>
          {dashboardFields.map((fieldObject: any) => (
            <React.Fragment key={fieldObject.id}>
              <DashboardCard
                data={dataMapper[fieldObject.id]}
                cardProps={{ ...fieldObject, ...additionalCardProps }}
              />
            </React.Fragment>
          ))}
        </Stack>
        <Typography
          className="dashboardHeading"
          sx={{
            mt: 4,
          }}
          gutterBottom
        >
          Quick Links
        </Typography>
        <Stack
          direction={isTablet ? 'column' : 'row'}
          spacing={isTablet ? 2 : 3}
          gap={1}
        >
          {dashboardButtons.map((button) => (
            <Button
              key={button.name}
              component={Link}
              href={button.href}
              target="_blank"
              className="acr-button bg-blue"
            >
              {button.name}
            </Button>
          ))}
          {isRoleCustomerOrBroker && (
            <Button
              className="acr-button bg-blue"
              onClick={() => setOpenSurveyModal(true)}
            >
              Take a Quick Survey
            </Button>
          )}
        </Stack>
      </Container>
      <CustomModal
        open={openSurveyModal}
        setOpen={setOpenSurveyModal}
        className={openSurveyForm ? 'surveyFormModal' : ''}
      >
        <CustomerSurveyModalBody
          startSurvey={() => {
            setOpenSurveyForm(true)
            completeSurveyMutation.mutate()
          }}
          closeSurvey={() => setOpenSurveyForm(false)}
          showSurveyForm={openSurveyForm}
        />
      </CustomModal>
    </>
  )
}

export default Dashboard
