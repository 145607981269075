import { FC, ReactElement, useCallback, useEffect, useState } from 'react'

import { Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { fields as orderFields } from '~/components/Orders/components/OpenOrdersTabContent'
import DataGrid from '~/components/UI/DataGrid/DataGrid'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import { useOrderPod } from '~/services/orderPod'
import {
  createOrderComment,
  fetchAllOrderComments,
  fetchOrderComments,
  fetchOrders,
} from '~/services/orders'
import { Order } from '~/types/Order.model'
import CustomModal from '~/UI/modals/CustomModal'
import OrderInvoiceActionButton from '~/UI/modals/modalBodies/tableCommentModalBody/actionButton/OrderInvoiceActionButton'
import TableAttachFileModalBody from '~/UI/modals/modalBodies/tableCommentModalBody/TableAttachFileModalBody'
import TableCommentModalBody from '~/UI/modals/modalBodies/tableCommentModalBody/TableCommentModalBody'
import { PAGE_TITLES, TEXTS } from '~/utils/allConstants/Constants'
import { downloadFileRes } from '~/utils/helpers/helperFunctions'

const { NO_DATA_MESSAGE } = TEXTS
const { ORDERS } = PAGE_TITLES

type Props = {
  filters: any
}

const SearchResultsOrders: FC<Props> = ({ filters }): ReactElement => {
  const { setTableProps, tableProps, data } = useDataGridStore()

  const [openCommentModal, setOpenCommentModal] = useState(false)
  const [commentModalData, setCommentModalData] = useState<Order>()
  const [openAttachFileModal, setOpenAttachFileModal] = useState(false)
  const [attachFileModalData, setAttachFileModalData] = useState<Order>()
  const [attachFileRow, setAttachFileRow] = useState<Order>()

  const useOrderPodMutation = useOrderPod()

  const { data: comments, isFetching } = useQuery(
    ['orders', 'comments', data?.items],
    async () => {
      const orderNumbers = data?.items.map((itm: Order) => itm.orderNumber)
      return await fetchAllOrderComments(orderNumbers)
    },
    {
      enabled: data?.items.length > 0,
      placeholderData: [],
    }
  )

  const toggleCommentModal = useCallback((rowData: Order) => {
    setOpenCommentModal((prevState) => {
      if (!prevState) {
        setCommentModalData(rowData)
      }
      return !prevState
    })
  }, [])

  const toggleAttachFileModal = useCallback((rowData: Order) => {
    setAttachFileRow(rowData)
    useOrderPodMutation.mutate(rowData.orderNumber, {
      onSuccess: (response) => {
        const filename = `Order-POD-${rowData.orderNumber}.pdf`
        return downloadFileRes({ response, filename })
      },
      onError: () => {
        setOpenAttachFileModal((prevState) => {
          if (!prevState) {
            setAttachFileModalData(rowData)
          }
          return !prevState
        })
      },
    })
  }, [])

  const table = {
    name: 'search_orders',
    additionalFilters: filters,
    source: fetchOrders,
    fields: orderFields,
    actions: [
      {
        render: (row: Order) => {
          return (
            <OrderInvoiceActionButton
              // @ts-ignore: Unmatched type error
              hasComments={comments?.[row.orderNumber]?.length}
              toggleAttachFileModal={toggleAttachFileModal.bind(this, row)}
              toggleCommentModal={toggleCommentModal.bind(this, row)}
              attachVisible={row.state === 'Closed'}
              isFetching={isFetching}
              isOrderPodLoading={useOrderPodMutation.isLoading}
              hasAttachClick={row === attachFileRow}
            />
          )
        },
      },
    ],
    notFoundMessage: NO_DATA_MESSAGE('Orders'),
  }

  useEffect(() => {
    setTableProps({
      ...table,
    })
  }, [isFetching, filters, useOrderPodMutation.isLoading])

  if (Object.keys(tableProps).length === 0) {
    return null
  }

  return (
    <>
      <Typography variant="h5" mt={3}>
        {ORDERS}
      </Typography>
      <DataGrid />
      <CustomModal open={openCommentModal} setOpen={setOpenCommentModal}>
        <TableCommentModalBody
          source={commentModalData}
          name="orders"
          dataActions={{
            fetchSource: fetchOrderComments,
            createSource: createOrderComment,
          }}
        />
      </CustomModal>
      <CustomModal open={openAttachFileModal} setOpen={setOpenAttachFileModal}>
        <TableAttachFileModalBody source={attachFileModalData} />
      </CustomModal>
    </>
  )
}

// eslint-disable-next-line react/display-name
export default (props: Props) => {
  return (
    <DataGridProvider>
      <SearchResultsOrders {...props} />
    </DataGridProvider>
  )
}
