import { ChangeEvent, FC, ReactElement, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { theme } from '~/theme/AppTheme'
import { Fields } from '~/types/Fields.model'
import {
  InvoiceFilter,
  OrderFilter,
  PriceFilter,
  UserFilter,
} from '~/types/Filter.model'
import { PageParams } from '~/types/PageParams.model'
import FilterIcon from '~/UI/icons/FilterIcon'
import { TEXTS } from '~/utils/allConstants/Constants'
import { emptyDeep, trimStringValues } from '~/utils/helpers/helperFunctions'

import useDataGridStore from '../DataGridContext'

import { FilterDatePicker } from './components/FilterDatePicker'
import FilterFormControl from './components/FilterFormControl'
import FilterSelectField from './components/FilterSelectField'
import { FilterTextField } from './components/FilterTextField'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorTeal, acrColorBlue } = variables
const { ADD_FILTER, APPLY_FILTERS, CANCEL } = TEXTS

interface Props {
  closeModal?: () => void
}

const createDefaultValues = (fields: Fields[], values: any) => {
  const defaultValues: any = {}

  fields.forEach((filter: Fields) => {
    const filterType = filter.filterFieldConfiguration.type
    const isRange = filterType.indexOf('range-') !== -1
    const filterKeys = []

    if (isRange) {
      filterKeys.push(`${filter.id}.0`, `${filter.id}.1`)
    } else {
      filterKeys.push(filter.id)
    }

    filterKeys.forEach((key, index) => {
      const filterKey = isRange ? filter.id : key
      let val = filterKey in values ? values[filterKey] : ''
      if (isRange && Array.isArray(val)) {
        val = val[index]
      }
      defaultValues[key] = val
    })
  })

  return defaultValues
}

const filterFieldMapper = (
  filter: Fields,
  control: any,
  resetFieldByName: (fieldName: string) => void
) => {
  switch (filter.filterFieldConfiguration.type) {
    case 'freeText':
      return (
        <Grid item xs={12} sm={6}>
          <FilterTextField
            name={filter.id}
            control={control}
            placeholder={filter.label}
          />
        </Grid>
      )
      break
    case 'range-freeText':
      return (
        <>
          <Grid item xs={6} sx={{ height: 88 }}>
            <FilterFormControl
              name={`${filter.id}.0`}
              control={control}
              placeholder={filter.label}
              label="From"
              startAdornment={
                <InputAdornment position="start">
                  {filter.filterFieldConfiguration.prefix}
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={6} sx={{ height: 88 }}>
            <FilterFormControl
              name={`${filter.id}.1`}
              control={control}
              placeholder={filter.label}
              label="To"
              startAdornment={
                <InputAdornment position="start">
                  {filter.filterFieldConfiguration.prefix}
                </InputAdornment>
              }
            />
          </Grid>
        </>
      )
      break
    case 'select':
    case 'boolean-select':
      return (
        <Grid item xs={12} sm={6}>
          <FilterSelectField
            name={filter.id}
            control={control}
            onCustomChange={filter.filterFieldConfiguration.onChange}
            onResetField={resetFieldByName}
            availableValues={filter.filterFieldConfiguration.values}
          >
            {Object.keys(filter.filterFieldConfiguration.values).map(
              (key, index) => {
                const value = Array.isArray(
                  filter.filterFieldConfiguration.values
                )
                  ? filter.filterFieldConfiguration.values[key as any]
                  : key

                return (
                  <MenuItem key={index} value={value}>
                    {filter.filterFieldConfiguration.values[key]}
                  </MenuItem>
                )
              }
            )}
          </FilterSelectField>
        </Grid>
      )
    case 'range-date':
      return (
        <>
          <Grid item xs={6} sx={{ height: 88 }}>
            <FilterDatePicker
              name={`${filter.id}.0`}
              label="From"
              control={control}
            />
          </Grid>
          <Grid item xs={6} sx={{ height: 88 }}>
            <FilterDatePicker
              name={`${filter.id}.1`}
              label="To"
              control={control}
            />
          </Grid>
        </>
      )
  }
}

const toDotList = (obj: any) => {
  const walk = (into: any, obj: any, prefix: any = []) => {
    Object.entries(obj).forEach(([key, val]) => {
      if (typeof val === 'object' && !Array.isArray(val))
        walk(into, val, [...prefix, key])
      else into[[...prefix, key].join('.')] = val
    })
  }
  const out = {}
  walk(out, obj)
  return out
}

const DataGridFilters: FC<Props> = ({ closeModal }): ReactElement => {
  const { tableProps, changeFilters, filters } = useDataGridStore()
  const isDesktop = useMediaQuery(theme.breakpoints.down('lg'))
  const fieldsWithFilters = tableProps.fields.filter(
    (
      filter: PageParams<InvoiceFilter | OrderFilter | UserFilter | PriceFilter>
    ) => filter.hasOwnProperty('filterFieldConfiguration')
  )

  const { handleSubmit, control, watch, resetField, setValue } = useForm({
    defaultValues: createDefaultValues(fieldsWithFilters, filters),
  })

  const watchFilters = watch()

  const [enabledFilters, setEnabledFilters] = useState<any>(
    new Set<string>(Object.keys(filters))
  )

  const resetFieldByName = (fieldName: string) => {
    resetField(fieldName)
    setValue(fieldName, '')
  }

  const enableFilter = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      resetField(event.target.name)
      setEnabledFilters(
        (prev: any) => new Set([...prev].filter((x) => x !== event.target.name))
      )
    } else {
      setEnabledFilters((prev: any) => new Set(prev.add(event.target.name)))
    }
  }

  const onSubmit = (data: any) => {
    const trimmedData = trimStringValues(data)
    data = toDotList(trimmedData)
    const currentFilters: any = {}
    for (const filterName of enabledFilters) {
      if (data[filterName] !== '') {
        currentFilters[filterName] = data[filterName]
      }
    }
    changeFilters(currentFilters)
    closeModal()
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <FilterIcon color={acrColorBlue} />
        <Typography variant="h3" ml={2}>
          {ADD_FILTER}
        </Typography>
      </Grid>
      <Box
        sx={{
          maxHeight: isDesktop ? '100%' : 400,
          overflowY: 'auto',
          width: '100%',
          mt: 2,
        }}
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={(e) => e.preventDefault()}
      >
        {fieldsWithFilters.map((filter: Fields) => (
          <Grid container key={filter.id} className="filter-grid">
            <Grid item xs={1} className="grid-item">
              <Checkbox
                className="grid-item-content"
                sx={{
                  m: 0,
                  p: 0,
                  '& .MuiSvgIcon-root': {
                    color: acrColorTeal,
                  },
                }}
                checked={enabledFilters.has(filter.id)}
                onChange={enableFilter}
                name={filter.id}
              />
            </Grid>
            <Grid item xs={5} lg={3} className="grid-item">
              <Typography
                className="grid-item-content"
                sx={{
                  pl: 1,
                }}
              >
                {filter.label}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={8} className="grid-item">
              {enabledFilters.has(filter.id) && (
                <Grid
                  container
                  columnSpacing={2}
                  className="grid-item-content having-form-control"
                  sx={{
                    pl: 2,
                  }}
                >
                  {filterFieldMapper(filter, control, resetFieldByName)}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ color: '#D2D3DA' }} />
            </Grid>
          </Grid>
        ))}
      </Box>
      <Box sx={{ m: '30px auto 20px auto' }}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button
            className="acr-button bg-teal"
            sx={{
              width: '200px',
            }}
            disabled={emptyDeep(watchFilters)}
            onClick={handleSubmit(onSubmit)}
          >
            {APPLY_FILTERS}
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', py: 3 }}>
          <Button
            className="acr-button"
            sx={{
              width: '200px',
            }}
            onClick={closeModal}
          >
            {CANCEL}
          </Button>
        </Grid>
      </Box>
    </Grid>
  )
}

export default DataGridFilters
