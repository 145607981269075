import AccountLoader from '../components/Accounts/AccountLoader'
import PriceChangeHistory from '../components/Reports/PriceChangeHistory'
import { RoutesCheck } from '../utils/allConstants/Constants'
import { RedirectWithAccountParams } from '../utils/routes-util'

import CheckRoutes from './CheckRoutes'

export default [
  {
    path: 'reports',
    element: <RedirectWithAccountParams to="/reports/price-change-history" />,
  },
  {
    path: ':accountId/reports',
    element: (
      <RedirectWithAccountParams to="/:accountId/reports/price-change-history" />
    ),
  },
  {
    path: ':accountId/:subAccountId/reports',
    element: (
      <RedirectWithAccountParams to="/:accountId/:subAccountId/reports/price-change-history" />
    ),
  },
  {
    path: 'reports/price-change-history',
    element: (
      <CheckRoutes type={[RoutesCheck.REPORT, RoutesCheck.NOT_CARRIER]}>
        <PriceChangeHistory />
      </CheckRoutes>
    ),
  },
  {
    path: ':accountId/reports/price-change-history',
    element: (
      <CheckRoutes type={[RoutesCheck.REPORT, RoutesCheck.NOT_CARRIER]}>
        <AccountLoader>
          <PriceChangeHistory />
        </AccountLoader>
      </CheckRoutes>
    ),
  },
  {
    path: ':accountId/:subAccountId/reports/price-change-history',
    element: (
      <CheckRoutes type={[RoutesCheck.REPORT, RoutesCheck.NOT_CARRIER]}>
        <AccountLoader>
          <PriceChangeHistory />
        </AccountLoader>
      </CheckRoutes>
    ),
  },
]
