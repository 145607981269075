import { useQuery } from '@tanstack/react-query'

import { fetchFutureProducts, fetchProducts } from '~/services/priceList'
import { TabsType } from '~/types/TabsType.model'
import { TEXTS } from '~/utils/allConstants/Constants'

const { CURRENT_PRICE_LIST, FUTURE_PRICE_LIST, NO_DATA_MESSAGE } = TEXTS

const usePriceListTabs = ({
  active_tab,
  accounts,
  currentFields,
  futureFields,
  selectedAccount,
  selectedSubAccount,
}: any) => {
  const isCustomerNumberMatched =
    selectedSubAccount?.customerNumber === selectedAccount?.customerNumber

  const defaultProps = {
    additionalFilters: accounts,
    initialPageSize: 100,
    pageSizeOptions: [
      { value: 100, label: '100' },
      { value: 500, label: '500' },
      { value: 'all', label: '1000+' }, // custom `all` pageSize value, supported by BE for Product API
    ],
    notFoundMessage: NO_DATA_MESSAGE('Products'),
  }

  const { data: futurePriceListData, isLoading: isLoadingFuturePriceList } =
    useQuery(
      [
        'future_price',
        {
          filters: accounts,
          page: 1,
          pageSize: 100,
        },
      ],
      () => {
        return (
          selectedAccount &&
          fetchFutureProducts({
            ...accounts,
            fields: futureFields,
            page: 1,
            pageSize: 100,
          })
        )
      },
      {
        keepPreviousData: isCustomerNumberMatched,
        enabled: !!selectedSubAccount,
        placeholderData: {
          items: [],
          totalCount: 0,
        },
      }
    )

  const currentTab: string =
    futurePriceListData?.totalCount > 0 ? active_tab || 'current' : 'current'

  const tabsData: TabsType[] = [
    {
      label: CURRENT_PRICE_LIST,
      name: 'current_price',
      source: fetchProducts,
      ...defaultProps,
      fields: currentFields,
      additionalFilters: {
        ...defaultProps.additionalFilters,
      },
    },
    {
      label: FUTURE_PRICE_LIST,
      name: 'future_price',
      source: fetchFutureProducts,
      ...defaultProps,
      fields: futureFields,
      additionalFilters: {
        ...defaultProps.additionalFilters,
      },
    },
  ]

  const priceListTabs =
    futurePriceListData?.totalCount > 0 ? tabsData : tabsData.slice(0, -1)

  return {
    currentTab,
    priceListTabs,
    isLoadingFuturePriceList,
  }
}

export default usePriceListTabs
