import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'

import PasswordTooltip from '~/components/common/passwordTooltip/PasswordTooltip'
import { useAuth, useLogoutMutation } from '~/services/auth'
import { useChangeCurrentUser } from '~/services/users'
import { theme } from '~/theme/AppTheme'
import { PasswordsData } from '~/types/Auth.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import {
  confirmNewPasswordValidation,
  passwordValidation,
} from '~/utils/helpers/helperFunctions'

import ControlledStyledTextField, {
  StyledTextField,
} from '../Form/StyledTextField'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue } = variables
const {
  FIRST_NAME,
  LAST_NAME,
  EMAIL_ADDRESS,
  PHONE_NUMBER,
  COMPANY_NAME,
  COMPANY_PHONE_NUMBER,
  AREA_OF_RESPONSIBILITY,
  USER_TYPE,
  DISCARD,
  SAVE,
  YOUR_CURRENT_PASSWORD,
  NEW_PASSWORD,
  CONFIRM_NEW_PASSWORD,
  FORGOT_PASSWORD,
  MY_ACCOUNT,
  NO_OLD_PASSWORD_MESSAGE,
  NO_NEW_PASSWORD_MESSAGE,
} = TEXTS

const schema = yup
  .object({
    oldPassword: yup.string().default('').required(NO_OLD_PASSWORD_MESSAGE),
    newPassword: yup
      .string()
      .default('')
      .when('oldPassword', (oldPassword) => {
        if (oldPassword) {
          return yup
            .string()
            .required(NO_NEW_PASSWORD_MESSAGE)
            .test('validation', 'error', passwordValidation)
        }
      }),
    confirmPassword: confirmNewPasswordValidation,
  })
  .required()

const UserDetails = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const { data: user } = useAuth()
  const logoutMutation = useLogoutMutation()
  const changeProfileMutation = useChangeCurrentUser(user.id)
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const { control, handleSubmit, reset } = useForm<PasswordsData>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
    mode: 'onChange',
  })

  const onSubmit = (data: PasswordsData) => {
    changeProfileMutation.mutate(data, {
      onSuccess: () => {
        reset({ oldPassword: '', newPassword: '', confirmPassword: '' })
      },
    })
  }

  const onError = (errors: any, e: any) => console.log(errors, e)

  const onDiscard = () => {
    reset({ oldPassword: '', newPassword: '', confirmPassword: '' })
  }

  const handleLogOut = () => {
    logoutMutation.mutate(null, {
      onSuccess: () => {
        window.location.href = '/forgot-password'
      },
    })
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <>
      <Typography variant="h3" pb={2}>
        {MY_ACCOUNT}
      </Typography>
      <Divider />
      <Grid container mt={3} pb={3} spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Typography variant="h4">User Account Information</Typography>
          <Typography>Update your Account Information</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <StyledTextField
            name="firstName"
            value={user.firstName}
            label={FIRST_NAME}
            disabled={true}
          />
          <StyledTextField
            name="lastName"
            value={user.lastName}
            label={LAST_NAME}
            disabled={true}
          />
          <StyledTextField
            name="contactEmail"
            value={user.contactEmail}
            label={EMAIL_ADDRESS}
            disabled={true}
          />
          <StyledTextField
            name="contactPhone"
            value={user.contactPhone}
            label={PHONE_NUMBER}
            disabled={true}
          />
          <StyledTextField
            name="company"
            value={user.company}
            label={COMPANY_NAME}
            disabled={true}
          />
          <StyledTextField
            name="companyContactPhone"
            value={user.companyContactPhone}
            label={COMPANY_PHONE_NUMBER}
            disabled={true}
          />
          <StyledTextField
            name="responsibilityArea"
            value={user.responsibilityArea ?? ''}
            label={AREA_OF_RESPONSIBILITY}
            disabled={true}
          />
          <StyledTextField
            name="userType"
            value={user.userType ?? ''}
            label={USER_TYPE}
            disabled={true}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container mt={3} pb={3} spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Typography variant="h4">Password Change</Typography>
          <Typography>Provide your Personal Information</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <ControlledStyledTextField
            name="oldPassword"
            control={control}
            label={YOUR_CURRENT_PASSWORD}
            type={showCurrentPassword ? 'text' : 'password'}
            autoComplete="new-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle current password visibility"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showCurrentPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <ControlledStyledTextField
            name="newPassword"
            control={control}
            label={NEW_PASSWORD}
            type={showNewPassword ? 'text' : 'password'}
            tooltip={<PasswordTooltip />}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle new password visibility"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
          <ControlledStyledTextField
            name="confirmPassword"
            control={control}
            label={CONFIRM_NEW_PASSWORD}
            type={showConfirmPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>

      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        sx={{ justifyContent: 'flex-end', gap: 2 }}
      >
        <Button
          className="acr-button"
          sx={{ width: !isTablet ? '200px' : '100%' }}
          onClick={onDiscard}
        >
          {DISCARD}
        </Button>
        <LoadingButton
          className="acr-button bg-teal"
          sx={{ width: !isTablet ? '200px' : '100%' }}
          onClick={handleSubmit(onSubmit, onError)}
          loading={changeProfileMutation.isLoading}
        >
          {SAVE}
        </LoadingButton>
      </Stack>

      <Grid container sx={{ mt: 2, textAlign: 'right' }}>
        <Grid item xs={true}>
          <Link
            onClick={handleLogOut}
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: acrColorBlue,
            }}
          >
            {FORGOT_PASSWORD}
          </Link>
        </Grid>
      </Grid>
    </>
  )
}

export default UserDetails
