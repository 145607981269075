import { Controller } from 'react-hook-form'

import HelpIcon from '@mui/icons-material/Help'
import {
  Box,
  FormHelperText,
  Grid,
  TextFieldProps,
  useMediaQuery,
} from '@mui/material'

import { theme } from '~/theme/AppTheme'
import { StyledFormFieldProps } from '~/types/StyledFormFieldProps.model'

import CustomTooltip from '../common/customTooltip/CustomTooltip'

import { BootstrapInput } from './StyledFormControl'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorLime } = variables

const ControlledStyledTextField = ({
  label,
  name,
  className,
  control,
  tooltip = null,
  ...rest
}: StyledFormFieldProps & TextFieldProps) => {
  {
    tooltip
  }
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Grid
      container
      mb={2}
      className={className}
      alignItems={{ md: 'center' }}
      direction={isTablet ? 'column' : 'row'}
    >
      {label && (
        <Grid item xs={12} md={4} pb={{ xs: 1, md: 0 }} pr={{ xs: 0, md: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <span>{label}</span>
            {tooltip !== null && (
              <CustomTooltip title={tooltip}>
                <HelpIcon sx={{ color: acrColorLime, fontSize: 20 }} />
              </CustomTooltip>
            )}
          </Box>
        </Grid>
      )}

      <Grid item xs={12} md={label ? 8 : 12}>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <BootstrapInput
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!error}
                fullWidth={true}
                placeholder={label}
                autoComplete="off"
                {...rest}
              />
              {!!error && (
                <FormHelperText error={!!error}>
                  {!!error ? error.message : ''}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>
    </Grid>
  )
}

export const StyledTextField = ({
  label,
  ...rest
}: StyledFormFieldProps & TextFieldProps) => {
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Grid
      container
      mb={2}
      alignItems={{ md: 'center' }}
      direction={isTablet ? 'column' : 'row'}
    >
      {label && (
        <Grid item xs={12} md={4} pb={{ xs: 1, md: 0 }} pr={{ xs: 0, md: 1 }}>
          {label}
        </Grid>
      )}
      <Grid item xs={12} md={label ? 8 : 12}>
        <BootstrapInput fullWidth={true} placeholder={label} {...rest} />
        {!!rest.error && (
          <FormHelperText error={!!rest.error}>
            {!!rest.error ? rest.error.message : ''}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  )
}

export const NoGridStyledTextField = ({
  placeholder,
  label,
  name,
  control,
  ...rest
}: StyledFormFieldProps & TextFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          {label && <Box sx={{ mb: 1 }}>{label}</Box>}
          <BootstrapInput
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={!!error}
            fullWidth={true}
            placeholder={placeholder || label}
            {...rest}
          />
          {!!error && (
            <FormHelperText error={!!error}>
              {!!error ? error.message : ''}
            </FormHelperText>
          )}
        </>
      )}
    />
  )
}

export default ControlledStyledTextField
