import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { UseQueryResult } from '@tanstack/react-query'

import ForgotPasswordComplete from '../components/ForgotPassword/ForgotPasswordComplete'
import ForgotPasswordConfirmComplete from '../components/ForgotPassword/ForgotPasswordConfirmComplete'
import ForgotPasswordConfirmForm from '../components/ForgotPassword/ForgotPasswordConfirmForm'
import ForgotPasswordForm from '../components/ForgotPassword/ForgotPasswordForm'
import { User } from '../types/User.model'

const ForgotPassword = ({
  auth,
}: {
  auth?: UseQueryResult<User | undefined>
}) => {
  const [completeForm, setCompleteForm] = useState<boolean>(false)

  const [search] = useSearchParams()
  const searchAsObject = Object.fromEntries(new URLSearchParams(search))
  if (searchAsObject.email) {
    if (completeForm) {
      return <ForgotPasswordConfirmComplete />
    }

    return (
      <ForgotPasswordConfirmForm
        email={searchAsObject.email}
        token={searchAsObject.token}
        complete={setCompleteForm}
      />
    )
  } else {
    if (completeForm) {
      return <ForgotPasswordComplete />
    }

    return <ForgotPasswordForm complete={setCompleteForm} auth={auth} />
  }
}

export default ForgotPassword
