import { FC, ReactElement } from 'react'

import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { theme } from '~/theme/AppTheme'

import './Dashboard.scss'
import variables from '~/theme/scss/_variables.module.scss'

const { acrColorTeal, acrColorBlue, acrColorSkyWhite } = variables
interface Props {
  data: any
  cardProps: any
}

const DashboardCard: FC<Props> = ({ data, cardProps }): ReactElement => {
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Card
      sx={{
        backgroundColor: acrColorSkyWhite,
        borderRadius: '.5rem',
        boxShadow: 'none',
        minWidth: 250,
      }}
    >
      <CardHeader
        disableTypography={true}
        action={
          cardProps?.headerAction && (
            <IconButton
              color="primary"
              onClick={() => cardProps.headerAction(cardProps.id)}
            >
              <OpenInNewIcon sx={{ color: acrColorBlue }} />
            </IconButton>
          )
        }
        title={
          <Typography
            className="dashboardHeading"
            sx={{
              lineHeight: '1.5rem',
            }}
            gutterBottom
          >
            {cardProps.title}
          </Typography>
        }
        subheader={
          <Box className="subTitle">
            {cardProps.cardSubTitle &&
              cardProps.cardSubTitle(
                cardProps.currentAccount,
                cardProps.currentSubAccount
              )}
          </Box>
        }
      />
      <CardContent>
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', gap: 2, flexWrap: 'wrap' }}
        >
          {cardProps.cardContent.map((cardContentItem: any) => (
            <Box
              key={cardContentItem.label}
              sx={{
                fontSize: '1rem',
                fontWeight: 500,
                color: acrColorBlue,
              }}
            >
              <Box sx={{ textAlign: 'center', fontSize: isTablet ? 16 : 18 }}>
                {cardContentItem.label}
              </Box>
              {!!data && (
                <Box
                  sx={{
                    textAlign: 'center',
                    fontWeight: 600,
                    fontSize: isTablet ? 22 : 24,
                    color: acrColorTeal,
                  }}
                >
                  {cardContentItem.hasOwnProperty('format')
                    ? cardContentItem.format(data[cardContentItem.id])
                    : data[cardContentItem.id]}
                </Box>
              )}
            </Box>
          ))}
        </Stack>
      </CardContent>
      {cardProps?.cardActions && cardProps.cardActions()}
    </Card>
  )
}

export default DashboardCard
