import { Navigate } from 'react-router-dom'

import { AdminDashboard } from '../components/Dashboard'
import Dashboard from '../components/Dashboard/Dashboard'
import Loading from '../components/UI/Loading'
import { useAuth } from '../services/auth'
import { RoleTypes } from '../services/users'

const DashboardRoute = () => {
  const { data, isLoading } = useAuth()

  if (isLoading) {
    return <Loading />
  }

  if (data.role === RoleTypes.ADMIN) {
    return <AdminDashboard />
  }

  if (data.role === RoleTypes.CARRIER) {
    return <Navigate to="/returns" replace />
  }

  return <Dashboard />
}

export default DashboardRoute
