export const ORDER_STATUSES_OPEN_ORDERS = [
  'Open',
  'Picking',
  'Awaiting Product Receipt',
  'Shipped',
]

export const ORDER_STATUSES_CLOSED_ORDERS = ['Invoiced', 'Canceled']

export const RETURN_STATUSES_HISTORY = [
  'Closed',
  'Rejected',
  'Approved RA-Expired',
]
export const RETURN_STATUSES_OPEN = [
  'Pending',
  'Approved',
  'Reviewed',
  'Awaiting Product Receipt',
  'Reopened',
  'Approved-O',
]
