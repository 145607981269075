import { Fields } from '../types/Fields.model'
import { removeNullUndefined } from '../utils/helpers/helperFunctions'

const prepareFilterValue = (val: any, field: Fields) => {
  if (field?.filterFieldConfiguration?.type === 'range-date' && val !== '') {
    const date = new Date(val)
    const year = date.getFullYear()
    const month =
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    return `${year}-${month}-${day}`
  }

  return val
}

export const getPreparedFilters = (filters: any, fields: Fields[]) => {
  const filterParams: any = {}
  Object.keys(filters).forEach((filterKey) => {
    const field = fields.find((itm) => itm.id === filterKey)

    if (typeof filters[filterKey] === 'object') {
      filterParams[`${filterKey}Ge`] = prepareFilterValue(
        filters[filterKey][0],
        field
      )
      filterParams[`${filterKey}Le`] = prepareFilterValue(
        filters[filterKey][1],
        field
      )
    } else {
      filterParams[filterKey] = prepareFilterValue(filters[filterKey], field)
    }
  })

  return filterParams
}

export const createQParams = (filters: any, fields: Fields[] = []) => {
  if (Object.keys(filters).length) {
    const filtersArray: string[] = []
    Object.keys(filters).forEach((filterKey) => {
      const field = fields.find((itm) => itm.id === filterKey)
      let val = filters[filterKey]
      if (
        ['freeText', 'text', 'select', 'range-freeText', 'range-date'].includes(
          field?.filterFieldConfiguration.type
        )
      ) {
        if (
          field.filterFieldConfiguration.type === 'freeText' &&
          field.id != 'id'
        ) {
          if (
            Object.keys(filters).includes('contactEmail') &&
            val.includes('+')
          ) {
            val = `~${val.trim().substring(0, val.trim().indexOf('+'))}`
          } else if (Object.keys(filters).includes('compoundShipToNumber')) {
            val = `~-${val}`
          } else {
            val = `~${val}`
          }
        }
        if (field.filterFieldConfiguration.type === 'select') {
          val = `~${val.toUpperCase()}`
        }
        if (field.filterFieldConfiguration.type === 'range-date') {
          val = `>="${val[0]}" AND <="${val[1]}"`
        }
        if (field.filterFieldConfiguration.type === 'range-freeText') {
          val = `>=${val[0]} AND <=${val[1]}`
        }
        val = `(${val})`
      }
      filtersArray.push(`${filterKey}:${val}`)
    })
    return filtersArray.join(' ')
  } else {
    return undefined
  }
}

export const createQueryParams = (filters: any, fields: Fields[] = []) => {
  const filtersObject: {
    [key: string]: string
  } = {}
  if (Object.keys(filters).length) {
    Object.keys(filters).forEach((filterKey) => {
      const field = fields.find((itm) => itm.id === filterKey)
      const val = filters[filterKey]

      if (
        ['freeText', 'text', 'select', 'range-freeText', 'range-date'].includes(
          field?.filterFieldConfiguration.type
        )
      ) {
        // TODO: transform fitler value
      }

      filtersObject[filterKey] = val
    })
  }

  return filtersObject
}

export const createFilterParams = (filters: any, fields: Fields[] = []) => {
  const filter: any = []
  filters = removeNullUndefined(filters)
  if (Object.keys(filters).length) {
    Object.keys(filters).forEach((filterKey) => {
      const field = fields.find((itm) => itm.id === filterKey)
      const val = filters[filterKey]

      switch (field?.filterFieldConfiguration.type) {
        case 'range-freeText':
          {
            const from = prepareFilterValue(val[0], field)
            const to = prepareFilterValue(val[1], field)
            filter.push(`${filterKey}:bw:(${from};${to})`)
          }
          break

        case 'range-date':
          {
            const from = prepareFilterValue(val[0], field)
            const to = prepareFilterValue(val[1], field)
            filter.push(`${filterKey}:>=:${from}`)
            filter.push(`${filterKey}:<=:${to}`)
          }
          break

        default: {
          filter.push(`${filterKey}:=:${val}`)
        }
      }
    })
  }

  return filter
}
