import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material'

import PreLoginLayout from '~/components/common/preLoginLayout/PreLoginLayout'
import ControllerStyledFormControl from '~/components/Form/StyledFormControl'
import AccountNotApproved from '~/components/Login/AccountNotApproved'
import { useLogingMutation } from '~/services/auth'
import { theme } from '~/theme/AppTheme'
import { LoginUserForm } from '~/types/UserForm.model'
import { RoutesCheck, TEXTS } from '~/utils/allConstants/Constants'

import { CheckRoutes } from './index'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue, acrColorTeal } = variables
const {
  FORGOT_PASSWORD,
  LOGIN,
  USERNAME,
  PASSWORD,
  NO_USER_MESSAGE,
  EMAIL_FORMAT_MESSAGE,
  NO_EMAIL_MESSAGE,
  NO_PASSWORD_MESSAGE,
  ACCESS_DENIED_MESSAGE,
  CONFIRM_EMAIL_MESSAGE,
  ACCOUNT_LOCKED_MESSAGE,
} = TEXTS

const schema = yup
  .object({
    email: yup.string().email(EMAIL_FORMAT_MESSAGE).required(NO_EMAIL_MESSAGE),
    password: yup.string().required(NO_PASSWORD_MESSAGE),
  })
  .required()

const Login = () => {
  const [showPassword, setShowPassword] = useState(false)
  const loginMutation = useLogingMutation()
  const navigate = useNavigate()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  // FIXME:
  const [responseError, setResponseError] = useState('')

  const { handleSubmit, control } = useForm<LoginUserForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      remember_me: true,
    },
  })

  const onSubmit = (data: LoginUserForm) => {
    setResponseError('')
    loginMutation.mutate(data, {
      onError: ({ data }: any) => {
        if (data.code === 403) {
          if (data.details && data.details.reason) {
            if (
              ['USER_PENDING_APPROVAL', 'CUSTOMER_PENDING_APPROVAL'].includes(
                data.details.reason
              )
            ) {
              navigate('/account-not-approved')
              return true
            }

            const errorMessages: any = {
              USER_NOT_FOUND: NO_USER_MESSAGE,
              EMAIL_NOT_VERIFIED: CONFIRM_EMAIL_MESSAGE,
              USER_NOT_APPROVED: ACCESS_DENIED_MESSAGE,
              USER_DISABLED: ACCESS_DENIED_MESSAGE,
              CUSTOMER_NOT_FOUND: NO_USER_MESSAGE,
              CUSTOMER_NOT_APPROVED: ACCESS_DENIED_MESSAGE,
              CUSTOMER_DISABLED: ACCESS_DENIED_MESSAGE,
              CUSTOMER_LOCKED: ACCOUNT_LOCKED_MESSAGE,
            }

            const errorMessage =
              errorMessages[data.details.reason] ??
              `${data.message} (${data.details.reason})`
            setResponseError(errorMessage)

            return true
          }
        }
        setResponseError(data.message)
      },
    })
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const leftPart = (
    <>
      <Typography variant="h1" my={2}>
        Log in to Your Account
      </Typography>
      <Typography className="formPageText">
        Welcome to ACR&apos;s 1ACR Portal also known as the web portal for
        customer purchase order and sales information. You must be an existing
        ACR customer to access the portal.
        <br />
        <br />
        If you are an existing customer and need to register for a user ID,
        please click on the New User button in the upper right-hand corner.
        <br /> <br /> Non-ACR existing customers, please click&nbsp;
        <Link href="https://weareacr.com/contact" target="_blank">
          here
        </Link>
        &nbsp;for more information on how to open a customer account.
      </Typography>
    </>
  )
  const rightPart = (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <ControllerStyledFormControl
        name="email"
        control={control}
        label={USERNAME}
        placeholder={USERNAME}
        labelSx={{ color: acrColorBlue }}
      />
      <ControllerStyledFormControl
        name="password"
        control={control}
        label={PASSWORD}
        placeholder={PASSWORD}
        type={showPassword ? 'text' : 'password'}
        labelSx={{ color: acrColorBlue }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        }
      />
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Controller
            name="remember_me"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                control={
                  <Checkbox
                    defaultChecked
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: acrColorTeal,
                      },
                    }}
                  />
                }
                label="Stay signed in"
              />
            )}
          />
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <Link component={RouterLink} to={`/forgot-password`}>
            {FORGOT_PASSWORD}
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={3}>
        {responseError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {responseError}
          </Alert>
        )}
        <Box sx={{ textAlign: 'right' }}>
          <LoadingButton
            type="submit"
            className="acr-button bg-teal size-big"
            fullWidth={isTablet}
            loading={loginMutation.isLoading}
          >
            {LOGIN}
          </LoadingButton>
        </Box>
      </Grid>
    </Box>
  )

  return <PreLoginLayout leftChildren={leftPart} rightChildren={rightPart} />
}

export default Login

export const routes = [
  {
    path: 'login',
    element: (
      <CheckRoutes type={[RoutesCheck.PUBLIC]}>
        <Login />
      </CheckRoutes>
    ),
  },
  {
    path: 'account-not-approved',
    element: (
      <CheckRoutes type={[RoutesCheck.PUBLIC]}>
        <AccountNotApproved />
      </CheckRoutes>
    ),
  },
]
