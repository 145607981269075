import { useNavigate } from 'react-router-dom'

import { ArrowBack } from '@mui/icons-material'
import { Box, Button } from '@mui/material'

import { TEXTS } from '~/utils/allConstants/Constants'

const { BACK } = TEXTS

const BackButton = () => {
  const navigate = useNavigate()
  return (
    <Button
      startIcon={<ArrowBack />}
      className="acr-button mrAuto"
      onClick={() => navigate(-1)}
    >
      <Box sx={{ lineHeight: 'normal', fontSize: '14px' }}>{BACK}</Box>
    </Button>
  )
}

export default BackButton
